import {memo} from "react";

const DeleteBtn = ({detail, remove, isDisabled = false}) => {
        return (
            <button
                className="btn"
                onClick={() => remove(detail)}
								tabIndex={-1}
                disabled={isDisabled}
            >
                <i className="btn__icon-delete"></i>
            </button>
        )
    }
;

export default memo(DeleteBtn);