import { createAction } from "@reduxjs/toolkit";
import actionType from "./actionType";


const addCDetail = createAction(actionType.ADD_DETAIL);
const addSelectedCDetail = createAction(actionType.ADD_SELECTED_DETAIL);
const removeSelectedCDetail = createAction(actionType.REMOVE_SELECTED_DETAIL);
const removeCDetail = createAction(actionType.REMOVE_DETAIL)
const setScene = createAction(actionType.SET_SCENE)
const setSelectedDetails = createAction(actionType.SET_SELECTED_DETAILS)
const setEditedObject = createAction(actionType.SET_EDITED_OBJECT)
const addProduct = createAction(actionType.ADD_PRODUCT)
const removeProduct = createAction(actionType.REMOVE_PRODUCT)
const addSelectedObject = createAction(actionType.ADD_SELECTED_OBJECT)
const removeSelectedObject = createAction(actionType.REMOVE_SELECTED_OBJECT)
const addHistory = createAction(actionType.ADD_HISTORY_STATE);
const setHistory = createAction(actionType.SET_HISTORY_STATE)
const historySetCurrent = createAction(actionType.HISTORY_SET_CURRENT)
const getShowedProcessing = createAction(actionType.GET_SHOWED_PROCESSING)
const setShowedProcessing = createAction(actionType.SET_SHOWED_PROCESSING)
const addConnection = createAction(actionType.ADD_CONNECTION)
const removeConnection = createAction(actionType.REMOVE_CONNECTION)
const setConnections = createAction(actionType.SET_CONNECTIONS)
const showConnectOptions = createAction(actionType.SHOW_CONNECT_OPTIONS)
const setConnectValues = createAction(actionType.CONNECT_VALUES)
const dropConnectValues = createAction(actionType.DROP_CONNECT_VALUES)
const addGroup = createAction(actionType.ADD_GROUP);
const removeGroup = createAction(actionType.REMOVE_GROUP);
const addDetailGroup = createAction(actionType.ADD_DETAIL_GROUP);
const removeDetailGroup = createAction(actionType.REMOVE_DETAIL_GROUP)
const leftPanelOpened = createAction(actionType.LEFT_PANEL_OPENED)
const rightPanelOpened = createAction(actionType.RIGHT_PANEL_OPENED)
const setCurrentCamera = createAction(actionType.SET_CURRENT_CAMERA)
const addTopPanelItem = createAction(actionType.ADD_TOP_PANEL_ITEM)
const removeTopPanelItem = createAction(actionType.REMOVE_TOP_PANEL_ITEM)
const toggleConnectModal = createAction(actionType.TOGGLE_CONNECT_MODAL)
const getRenderType = createAction(actionType.GET_RENDER_TYPE)
const setRenderType = createAction(actionType.SET_RENDER_TYPE)

export default {
  addCDetail,
  addSelectedCDetail,
  removeSelectedCDetail,
  setSelectedDetails,
  removeCDetail,
  setScene,
  setEditedObject,
  addProduct,
  removeProduct,
  addSelectedObject,
  removeSelectedObject,
  addHistory,
  setHistory,
  historySetCurrent,
  getShowedProcessing,
  setShowedProcessing,
  addConnection,
  removeConnection,
  setConnections,
  showConnectOptions,
  setConnectValues,
  dropConnectValues,
  addGroup,
  removeGroup,
  addDetailGroup,
  removeDetailGroup,
  leftPanelOpened,
  rightPanelOpened,
  setCurrentCamera,
  addTopPanelItem,
  removeTopPanelItem,
  toggleConnectModal,
  getRenderType,
  setRenderType
  // setCSceneModel
};
