import API from "config/api";

export default class Orders {
	constructor(axios, token, headers) {
		this.axios = axios;
		this.token = token;
		this.headers = headers;
	}
	
	getSSActualOrders() {
		const ssParams = {
			isFinalStatus: false,
			countMonth: 1
		};
		
		return this.axios.get(API.ROUTE.get_orders(ssParams), this.headers).then(response => {
			if (response && response.data) {
				return Promise.resolve(response.data)
			} else {
				return Promise.reject()
			}
		})
	}
	
	getSSFinalOrders() {
		const ssParams = {
			isFinalStatus: true,
			countMonth: 1
		};
		
		return this.axios.get(API.ROUTE.get_orders(ssParams), this.headers).then(response => {
			if (response && response.data) {
				return Promise.resolve(response.data)
			} else {
				return Promise.reject()
			}
		})
	}
}
