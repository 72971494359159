import React from "react";
import "./style.scss";
import loader from '../../../media/spinner.svg'

const Loader2 = () => {

    return <div className='materialResidues__loader2'>
            <img src={loader} alt="loader" className='loader2 '/>
    </div>
}

export default Loader2;
