import { ToastContainer } from "react-toastify";
import "./style.scss";

const Toast = () => {
  return (
    <>
      <ToastContainer
        closeOnClick={true}
        pauseOnHover={true}
        autoClose={2500}
        hideProgressBar={false}
        position="top-right"
        draggable={true}
        theme="colored"
        limit={window.location.pathname === '/processes'? 5 : 2}
      />
    </>
  );
};

export default Toast;
