import React, { useState } from 'react';
import './style.scss'

const Input = props => {
	const {value, type='text', onChange, onBlur, name='input', title, error=null} = props;
	const [val, setVal] = useState(value);

	const handleChange = e => {
		setVal(e.target.value);
		if(onChange && typeof onChange === 'function') {
			onChange({name, value: e.target.value});
		}
	}

	const handleBlur = (e) => {
		if(onBlur && typeof onBlur === 'function') {
			onBlur({name, value: val});
		}
	}

	return (
		<label className={'d-flex input flex-wrap'}>
			<span className={'input__label'}>{title}</span>
			<input type={type} value={val} name={name} onBlur={handleBlur} onChange={handleChange}/>
			{error ? <span className={'input__error'}>{error}</span> : null }
		</label>

	);
}

export default Input;