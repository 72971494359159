import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {projectOperations, projectSelectors} from "redux/project";
import {modalsOperations} from "redux/modals";
import {empty} from "../../../helpers/helper";
import Languages from "../../../translation/Languages";
import {panelOperations, panelSelectors} from "redux/panel";
import {Tooltip} from "react-tooltip";
import {userSelectors} from "redux/user";
import Contour from "../SvgSketch";

const ParamItem = ({param, idx, uId, selectAll,isAllSelected, select, updateParams,detail, createComment, paramMain, createProc, clone, setSelectedItems}) => {
	const [headerValues, setHeaderValues] = useState([])
	const [bodyValues, setBodyValues] = useState([])
	const dispatch = useDispatch()
	const isEditPanel = useSelector(panelSelectors.getPanelIsEditPanel)
	const disabledEditForPartial = param.type === 'partial'
	const permissions = useSelector(userSelectors.permissions);
	const [runContour, setRunContour] = useState(false);
	const construction = useSelector(projectSelectors.getConstruction);

	useEffect(() => {
		if (param) {
			const data = createParamsHandler(param);
			setHeaderValues([...Object.keys(data)])
			setBodyValues([...Object.values(data)])
		}
	}, [param, paramMain, param.isErrorText, param.isSelected])
	const edgeIcons = (paramType, item) => {
		if (item === "right_top") {
			return 'chosProc-icon chosProc-icon__angle-right-top'
		}
		if (item === "right_bottom") {
			return 'chosProc-icon chosProc-icon__angle-right-bottom'
		}
		if (item === "left_top") {
			return 'chosProc-icon chosProc-icon__angle-left-top'
		}
		if (item === "left_bottom") {
			return 'chosProc-icon chosProc-icon__angle-left-bottom'
		}
		if (item === "ver") {
			return 'chosProc-icon chosProc-icon__vert-proc'
		}
		if (item === "hor") {
			return 'chosProc-icon chosProc-icon__horiz-proc'
		}
		if (item === 'front') {
			return 'chosProc-icon chosProc-icon__face-platform'
		}
		if (item === 'back') {
			return 'chosProc-icon chosProc-icon__back-platform '
		}
		if (item === 'right') {
			return 'chosProc-icon chosProc-icon__right-platform'
		}
		if (item === 'left') {
			return 'chosProc-icon chosProc-icon__left-platform'
		}
		if (item === 'top') {
			return 'chosProc-icon chosProc-icon__top-platform '
		}
		if (item === 'bottom') {
			return 'chosProc-icon chosProc-icon__bottom-platform'
		}
		if (['Круглий виріз', 'Прямокутний виріз', 'Г-подібний виріз', 'П-подібний виріз', 'Посмішка', 'Радіусний кут', 'Радіусний кут стільниці', 'Імпортована криволінійна обробка', 'Зріз замка стільниці', "Срез замка столешницы"].includes(item)) {
			return 'rectangleClassForItemFirstEl'
		}

		return errorClassNameData
	}

	const textStyle = (item) => {
		if (sidesArr.includes(item)) {
			return ''
		}
		return typeof item === 'number' ? item.toFixed(1) : item
	}

	const itemAxis = (item1, item2) => {
		return `${item1}_${item2}`
	}

	const createParamsHandler = (item) => {
		switch (item.subType) {
			case "corner":
				return {
					[Languages.getTranslation("corner", true)]: item._angle,
					[Languages.getTranslation("proce", true)]: item.type === 'line' && item.type !== 0 ? [Languages.getTranslation("slice", true)] : [Languages.getTranslation("radius", true)],
					[Languages.getTranslation("edge", true)]: !empty(item._edge) ? [Languages.getTranslation("yes", true)] : [Languages.getTranslation("no", true)],
					...(!empty(item._edge) ? { [Languages.getTranslation("article-edge", true)]:  item.edge.article } : {}),
					...(item.type === 'line' && item.type !== 0 ? {
						'x': item._x,
						'y': item._y,
					} : {}),
					...(item.type === 'line' && item.type !== 0 ? {} : {[Languages.getTranslation("radius", true)]: item._r,}),
				}
			case "groove":
				return {
                    [Languages.getTranslation("bind", true)]: itemAxis(item._x_axis, item._y_axis),
                    [Languages.getTranslation("side", true)]: item._side,
                    [Languages.getTranslation("height", true)]: item._width,
                    [Languages.getTranslation("width", true)]: item._height,
                    [Languages.getTranslation("depth", true)]: item._depth,
					...(['left', 'right', 'top', 'bottom'].includes(item.side) ? {"z": item._z} : {}),
					...(['front', 'back', 'top', 'bottom'].includes(item.side) ? {"x": item._x} : {}),
					...(['left', 'right', 'front', 'back'].includes(item.side) ? {"y": item._y} : {}),
                    [Languages.getTranslation("direction", true)]: item._direction,
                    [Languages.getTranslation("locked", true)]: item._ext ?  [Languages.getTranslation("yes", true)] : [Languages.getTranslation("no", true)],
                    [Languages.getTranslation("radius", true)]: item._r,
				};
			case "rabbet":
				return {
					[Languages.getTranslation("side", true)]: item._side,
					[Languages.getTranslation("tip", true)]: item._edgeSide,
					[Languages.getTranslation("displacement", true)]: item._offset,
					[Languages.getTranslation("height", true)]: item.width,
					[Languages.getTranslation("width", true)]: item.height,
					[Languages.getTranslation("depth", true)]: item._depth,
				};

			case "circle":
				return {
					[Languages.getTranslation("type", true)]: 	Languages.getTranslation("circle-proc", true),
					[Languages.getTranslation("bind", true)]: itemAxis(item._x_axis, item._y_axis),
					"Х": item._x,
					"У": item._y,
					[Languages.getTranslation("radius", true)]: item._r,
				};

			case "rectangle":
				return {
					[Languages.getTranslation("type", true)]: Languages.getTranslation("rectangle-proc", true),
					[Languages.getTranslation("bind", true)]: itemAxis(item._x_axis, item._y_axis),
					"Х": item._x,
					"У": item._y,
					"L": item._height,
					"W": item._width,
					[Languages.getTranslation("radius", true)]: item._r,
				};

			case "uShape":
				return {
					[Languages.getTranslation("tip", true)]: item._edgeSide,
					[Languages.getTranslation("displacement", true)]: item._offset,
					[Languages.getTranslation("height", true)]: item._width,
					[Languages.getTranslation("width", true)]: item._height,
					[Languages.getTranslation("radius", true)]: item._r,
					[Languages.getTranslation("edge", true)]: !empty(item._edge) ? Languages.getTranslation("yes", true)
						: Languages.getTranslation("no", true),
				}
			case 'bevel':
				return {
					[Languages.getTranslation("side", true)]: item._side,
					[Languages.getTranslation("tip", true)]: item._edgeSide,
					[Languages.getTranslation("displacement", true)]: item._z,
					[Languages.getTranslation("corner", true)]: item._angle,
				}
			case "hole":
				return {
					[Languages.getTranslation("bind", true)]: itemAxis(item._x_axis, item._y_axis),
					[Languages.getTranslation("side", true)]: item._side,
					"Х": item._x,
					"У": item._y,
					[Languages.getTranslation("diam", true)]: item._diam,
					[Languages.getTranslation("depth", true)]: item._depth,
				}
			case 'tableProc':
				switch (item.type) {
					case 'tableTop' :
						return {
							[Languages.getTranslation("type", true)]: Languages.getTranslation("Cut-worktop-lock", true),
							[Languages.getTranslation("tip", true)]: item._edgeSide,
							[Languages.getTranslation("width", true)]: item.width,
						}
					case 'tableCorner' :
						return {
							[Languages.getTranslation("type", true)]: Languages.getTranslation("Radius-angle-worktop", true),
							[Languages.getTranslation("corner", true)]: item._angle,
							"r": item.r,
							[Languages.getTranslation("edge", true)]: !empty(item._edge)? Languages.getTranslation("yes", true)
								: Languages.getTranslation("no", true),
						}
				}
			case 'mill':
				switch (item.type) {
					case 'uShape' :
						return {
							[Languages.getTranslation("type", true)]: Languages.getTranslation("U-shaped-neckline", true),
							[Languages.getTranslation("tip", true)]: item._edgeSide,
							[Languages.getTranslation("displacement", true)]: item._offset,
							[Languages.getTranslation("height", true)]: item._width,
							[Languages.getTranslation("width", true)]: item._height,
							[Languages.getTranslation("radius", true)]: item._r,
							[Languages.getTranslation("edge", true)]: !empty(item._edge)? Languages.getTranslation("yes", true)
								: Languages.getTranslation("no", true),
						}
						case 'gEdge' :
							return {
								[Languages.getTranslation("type", true)]: Languages.getTranslation("G-shaped-neckline", true),
								[Languages.getTranslation("corner", true)]: item._angle,
								[Languages.getTranslation("width", true)]: item._height,
								[Languages.getTranslation("height", true)]: item._width,
								"r": item._r,
								[Languages.getTranslation("edge", true)]: !empty(item._edge)? Languages.getTranslation("yes", true)
									: Languages.getTranslation("no", true),
							}
						case "radiusEdge" :
							return {
								[Languages.getTranslation("type", true)]: Languages.getTranslation("radius-angle", true),
								[Languages.getTranslation("corner", true)]: item._angle,
								[Languages.getTranslation("indentation-X", true)]: item._x,
								[Languages.getTranslation("indentation-Y", true)]: item._y,
								"r": item._r,
								[Languages.getTranslation("edge", true)]: !empty(item._edge)? Languages.getTranslation("yes", true)
									: Languages.getTranslation("no", true),
							}
						case 'smile' :
						return {
							[Languages.getTranslation("type", true)]: Languages.getTranslation("smile", true),
							[Languages.getTranslation("tip", true)]: item._edgeSide,
							[Languages.getTranslation("height", true)]: item.width,
							[Languages.getTranslation("displacement", true)]: item._offset,
							[Languages.getTranslation("edge", true)]: !empty(item._edge)? Languages.getTranslation("yes", true)
								: Languages.getTranslation("no", true),
						}
						case 'tableTop' :
						return {
							[Languages.getTranslation("type", true)]: Languages.getTranslation("Cut-worktop-lock", true),
							[Languages.getTranslation("tip", true)]: item._edgeSide,
							[Languages.getTranslation("width", true)]: item.width,
						}
						case 'tableCorner' :
						return {
							[Languages.getTranslation("type", true)]: Languages.getTranslation("Radius-angle-worktop", true),
							[Languages.getTranslation("corner", true)]: item._angle,
							"r": item.r,
							[Languages.getTranslation("edge", true)]: !empty(item._edge)? Languages.getTranslation("yes", true)
								: Languages.getTranslation("no", true),
						}
					case 'partial':
					case 'closed':
						return {
							[Languages.getTranslation("type", true)]: Languages.getTranslation("import-mill", true),
							[Languages.getTranslation("edge", true)]: !empty(item._edge)? Languages.getTranslation("yes", true)
								: Languages.getTranslation("no", true),
							...(!empty(item._edge) ? { [Languages.getTranslation("article-edge", true)]:  construction.edges.find(el => el.index === item._edge).article } : {}),
						}
				}
				break
			case 'template':
				return {
					[Languages.getTranslation("template", true)]: item.name
				}
			case 'cutOut' :
				switch (item.type) {
					case '':
					case 'cutout':
					case 'compactLock':
						return {
							[Languages.getTranslation("side", true)]: item.mainElement.side,
							"Х": item.mainElement.x,
							"У": item.mainElement.y,
							[Languages.getTranslation("depth", true)]: item.mainElement.depth,
						}
					case 'Sampling':
						return {
							[Languages.getTranslation("side", true)]: item.mainElement.side,
							[Languages.getTranslation("height", true)]: item.mainElement.height,
							[Languages.getTranslation("width", true)]: item.mainElement.width,
							"Х": item.mainElement.x,
							"У": item.mainElement.y,
							[Languages.getTranslation("depth", true)]: item.mainElement.depth,
						}
					default:
						return {
							[Languages.getTranslation("type", true)]: "Undefined",
						};
				}
		}
	};

	const handlePromiseOperations = () => {
		return param.remove()
			.then(() => detail.validate(detail))
			.then(() => {
				createProc();
				dispatch(modalsOperations.switchStateForModal({
					show: false,
					type: null,
				}));
			});
	}

	const deleteParam = () => {
		param.isSelected = false;
		param.isEdit = true;

		setSelectedItems((prevState) => prevState.filter(el => el !== param.id));

		if (param.subType !== 'template') {
			param.checkIfTemplate().then(handlePromiseOperations)
		} else {
			handlePromiseOperations();
		}

		updateParams();

		setTimeout(() => {
			setRunContour(true);
		}, 200);
		setTimeout(() => {
			setRunContour(false);
		}, 330);
	};

	const edit = () => {
		param.isEdit = true;
		dispatch(
			projectOperations.setTreatment({
				Treatment: param,
			})
		);
		dispatch(
			panelOperations.switchStateForPanel({
				show: 'procPanel',
				isEditPanel: true
			})
		)
	};

	const setColorBack = () => {
		param.isActive = false
		param.showArrow = false
	};
	const colorProcess = () => {
		param.isActive = true
		if (!['partial', 'closed'].includes(param.type)) param.showArrow = true
	}

	const millClassForItem = param.subType==='mill' ? 'millClassForItemChosProc' : ''
	const millClassForHeader = param.subType==='mill' ? 'millClassForHeader' : ''

	const rectangleClassForItem = param.subType==='rectangle' || param.subType==='circle' ? 'rectangleClassForItem' : ''

	const rectangleClassForHeader = param.subType === 'rectangle' || param.subType==='circle' ? 'rectangleClassForHeader' : ''
	const rectangleClassForHeaderTitle = param.subType === 'rectangle'  ? 'rectangleClassForHeaderTitle' : ''

	const errorClassName = `chosProcesses__item ${millClassForItem} ${rectangleClassForItem}  ${param.isErrorText !== '' ? 'chosProcesses__item_error' : ""}`
	const errorClassNameData = `chosProcesses__data ${param.isErrorText !== '' ? 'chosProcesses__data_error' : ""}`

	const sidesArr = ['right_top', 'right_bottom', 'left_top', 'left_bottom', 'ver', "hor", 'front', 'back', 'right', 'left', 'top', 'bottom']
	const grooveStyle = ['groove', 'cutOut', 'corner'].includes(param.subType) ? `chosProcesses__item-header-groove ${Languages.lang === "ru" ? "chosProcesses__item-header-groove_ru" : ""}` : 'chosProcesses__item-header'
	const grooveStyleBody = ['groove'].includes(param.subType) && Languages.lang === "ru" ? "chosProcesses__item-body__groove_ru" : 'chosProcesses__item-body'


	const rectangleNumber = param.subType === 'rectangle' || param.subType==='circle' ? 'rectangleClassForNumber' : ''
	const millNumber = param.subType === 'mill' ? 'millClassForNumber' : ''
	const millNumber2 = param.subType === 'mill' && !['uShape', 'gEdge'].includes(param.type) ? 'millClassForNumber__uShape' : ''
	const millSubNumber = param.subType === 'mill' && !['uShape', 'gEdge'].includes(param.type) ? 'millClassForSubNumber' : ''

	/**
	 * Returns a checkbox input element for multiple edit.
	 *
	 * @returns {HTMLElement} The checkbox input element.
	 */
	const getMultipleEditCheckbox = () => {
		if (param.subType === 'template' || param.type === 'tableTop') return null;
		return <input
			className="paramIcon__ext"
			checked={!!param.isSelected}
			onChange={() => {
				select(uId)
			}}
			type='checkbox'
			name='selected'
			id={uId}
		/>
	}
	const getMultipleCheckboxTitle = () => {
		if (param.subType === 'template' || param.type === 'tableTop'  || param.type === 'Sampling' || permissions.includes("KM_disableSelectHandles")) return null;
		return <div className="chosProcesses__item-headers-checkbox chosProcesses__item-number1">
			<input
				className="paramIcon__ext"
				checked={isAllSelected}
				onChange={selectAll}
				type='checkbox'
				name='selected'
				style={{display: 'none'}}
			/>
			<div
				onClick={selectAll}
				className={`checkbox-display ${isAllSelected ? 'checked' : ''}`}>
			</div>
		</div>
	}
    const editProcessesButton = () => {
		if(!permissions.includes('KM_editProcessing')) return null;

		return (
			<>
			  {!clone && !['partial', "closed"].includes(param.type) &&
	        	<button className="chosProcesses__btn-edit" onClick={edit} disabled={isEditPanel || disabledEditForPartial}
				title={Languages.getTranslation('edit-process-params', true)}></button>
		      }
			</>
		)
	}
	const removeProcessesButton = () => {
		if(!permissions.includes('KM_removeProcessing')) return null;

		return (
				<button className="chosProcesses__btn-delete" onClick={() => deleteParam(idx)} disabled={isEditPanel}
						title={Languages.getTranslation('delete-proc', true)}></button>
		)
	}
	const addCommentProcessesButton = () => {
		if(!permissions.includes('KM_addComment')) return null;

		return (
			!clone && !['partial', "closed"].includes(param.type) && <button className="chosProcesses__comment" onClick={() => createComment(param.comment)}
																			 title={Languages.getTranslation('open-comment', true)}>
			</button>
		);
	}
	return <div
		data-tooltip-id={param.isErrorText !== '' ? `error-${uId}` : ''}
		className={`${errorClassName}${param.isSelected ? ' chosProcesses__selected' : ''}`}
				key={idx}
				id={idx}
				onMouseOver={colorProcess}
				style={{height: ['groove', 'cutOut', 'corner'].includes(param.subType) ? 80 : ''}}
				onMouseLeave={setColorBack}>
		<div
			className={`chosProcesses__number ${millNumber} ${rectangleNumber} ${millNumber2}`}>
			<div className="chosProcesses__item-headers">
				{getMultipleCheckboxTitle()}
				<div className="chosProcesses__item-number1" style={{fontSize: 14}}>№</div>
		</div>

            {
                runContour && (
                    <Contour
                        key={detail.id}
                        detail={detail}
                        rects={detail.getCutoutsData()}
                        holes={detail.holes}
                    />
                )
            }
			<div className={`chosProcesses__item-number chosProcesses__data ${millSubNumber}`}
				 style={{background: "transparent", minWidth: 15, height: 21}}>
				{!permissions.includes("KM_disableSelectHandles") && (
					getMultipleEditCheckbox()
				)}
				<label htmlFor={uId}></label>
				<span className="chosProcesses__item-number-index">{idx + 1}</span>
		</div>
	</div>
	<div

		className={`chosProcesses__item-datas ${rectangleClassForItem} ${millClassForItem}`}>
		<div className={`${grooveStyle} ${millClassForHeader} ${rectangleClassForHeader}`}>
				{headerValues.map((el, id) => <div className={`chosProcesses__item-header-title ${rectangleClassForHeaderTitle}`} key={id}>{el}</div>)}
			</div>
			<div className={`chosProcesses__item-body ${grooveStyleBody}`}>
				{bodyValues.map((el, id) =>
					<div className='chosProcesses__item-body-container' key={id}>
						<div className={`${edgeIcons(param, el)}`} >{textStyle(el)}</div>
					</div>
					)}
			</div>
		</div>
		{!isAllSelected && (

		<div className="chosProcesses__btns">

			<div className="chosProcesses__btns-container">
				{editProcessesButton()}
				{addCommentProcessesButton()}
				{removeProcessesButton()}
			</div>
		</div>
		)}

		{param.isErrorText !== '' &&
			<Tooltip
				className='chosProcesses__item_tooltip-error'
				id={`error-${uId}`}
				place="bottom-center"
				variant='light'
				// openOnClick={true}
			>
				<div className='chosProcesses__item_tooltip-error_body'>
					{`${param.isErrorText}`}
				</div>
			</Tooltip>
		}
	</div>

}

export default ParamItem