import React from 'react'
import img from "../../../../media/material-add-img-test.svg";
import {materialTitle} from "../../../../helpers/helper";

const MaterialItemPart = ({material,materialMain,  index, deleteMaterialPart, number, imgLink}) => {
	return (
		<div className="table__item materialItem__part">
			<div className="materialItem__container">
			<div className="table__number-and-name materialItem__number-and-name">
				<div className="table__number">{index + 1}:{number + 1}</div>
				<img
					src={materialMain?.images?.length > 0 && materialMain.images[0].hasOwnProperty('small') ? materialMain.images[0]?.small : imgLink}
					alt="image"
					className="material__img"
				/>
				<div className="table__name">
					{" "}
					{materialTitle(material)}
				</div>
			</div>
			</div>
			<div className="materialItem__data-and-btn" >
			<div className="table__data-container materialItem__data-container" style={{marginRight: 35}}>
				<div className="table__data">
					{material.article}
				</div>
				<div className="table__data">
					{material.height}
				</div>
				<div className="table__data">
					{material.width}
				</div>
				<div className="table__data">
					{material.thickness}
				</div>
				<div className="table__data">
					{material.count}
				</div>
			</div>
			<button
				className="btn"
				onClick={() => deleteMaterialPart(material)}
			>
				<i className="btn__icon-delete"></i>
			</button>
			</div>
		</div>

	)
}
export default MaterialItemPart
