import  { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { modalsOperations } from "redux/modals";
import AppHeader from "components/AppHeader";
import Modal from "components/UI/Modal";
import DetailProps from "components/UI/Modal/DetailProps";
import DetailInfo from "components/UI/Modal/DetailInfo";

const PrivateTemplate = ({ children }) => {
  const dispatch = useDispatch();
  let location = useLocation();

  useEffect(() => {
    if (location.pathname.indexOf("processes") == -1) {
      dispatch(
        modalsOperations.showStateSelectedTreatments({
          show: false,
        })
      );
      dispatch(
        modalsOperations.showStateDetailInfo({
          show: false,
        })
      );
    }
  }, [location]);

  return (
    <>
       <Modal />
       {/*<SelectedTreatments />*/}
       <DetailProps />
       <DetailInfo />
       <AppHeader />
       {children}
    </>
  );
};
export default PrivateTemplate;
