import { useDispatch, useSelector } from 'react-redux'
import { errorMessageOperations, errorMessageSelectors } from '../../redux/errors'
import { toast } from 'react-toastify'

function DisplayHandler() {
	const dispatch = useDispatch()
	const errorMessage = useSelector(errorMessageSelectors.getErrorMessageState)

	const length = errorMessage.length

	if(length > 0 && errorMessage[length - 1].show) {
		toast[`${errorMessage[length - 1].type}`]
		(errorMessage[length - 1].message, {
			onClose: () =>
				dispatch(errorMessageOperations.switchStateError(
					{message: errorMessage[length - 1].message, action: 'close'})),
			autoClose: errorMessage[length - 1]?.autoHide === 'none' ? false : 2500,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
			toastId: `${errorMessage[length - 1].message}`,
		})
	}

	return  null
}

export default DisplayHandler