import {components} from "react-select";

const stopPropagation = (e) => {
	e.stopPropagation();
}

export const CustomOption = (props) => {
	return (
		<div title={props.label}>
			<components.Option {...props} />
		</div>
	);
};


export const CustomMenuPortal = ({children, ...props}) => (
	<components.MenuPortal {...props}>
		<div onClick={stopPropagation}>{children}</div>
	</components.MenuPortal>
);