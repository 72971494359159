import {isFractional} from "../../../../../helpers/helper";
import {useSelector} from "react-redux";
import {userSelectors} from "redux/user";
import {useCallback, useMemo} from "react";
import {DeleteBtn, DetailWrapper, Checkbox, Texture} from "../common";
import Draw from "./Draw";
import Handlers from "./Handlers";
import Errors from "./Errors";
import Edges from "./Edges";
import MaterialPreview from "./Material";
import ProductPreview from "./Product";

export const Detail = ({
                                style,
                                detail,
                                hasErrors,
                                index,
                                isSelected,
                                selectDetailHandler,
                                remove,
                                handleDragStart,
                                handleDrop,
                                dragOverHandler,
                                dragEndHandler,
												 				construction
                            }) => {
    const permissions = useSelector(userSelectors.permissions);
		const detailMaterial = useMemo(() => construction.materials.find(el => detail.material === el.index), [detail.material, construction.materials]);

    const selectDetail = useCallback(() => {
        selectDetailHandler(detail);
    }, [selectDetailHandler, detail]);

    return (
        <DetailWrapper
            detailId={detail.id}
            className="detailsTab__dnd"
            style={style}
            draggable
            onDragStart={(e) => handleDragStart(e, detail)}
            onDrop={(e) => handleDrop(e, detail?.order, detail.id)}
            onDragOver={(e) => dragOverHandler(e)}
            onDragEnd={(e) => dragEndHandler(e)}
        >
            <Checkbox onChange={selectDetail} isSelected={isSelected}/>
            <span className="detailsTab__number">{index + 1}</span>
            <Draw detail={detail}/>
            <div className="detailsTab__name">{detail.name}</div>
            <div
                className="detailsTab__data-item">{!isFractional(Number(detail.l)) ? Math.round(Number(detail.l)) : Number(detail.l).toFixed(1)}</div>
            <div
                className="detailsTab__data-item">{!isFractional(Number(detail.h)) ? Math.round(Number(detail.h)) : Number(detail.h).toFixed(1)}</div>
            <div className="detailsTab__data-item">{detail.count}</div>
            <div className="detailsTab__data-item">
                <Handlers
									detail={detail}
									detailId={detail.id}
									detailMultiplicity={detail.multiplicity}
									detailGrooves={detail.grooves}
									detailRabbets={detail.rabbets}
									detailHoles={detail.holes}
									ssSign={detail.isSlidingSystem}
								/>
            </div>
            <div className="detailsTab__data-item">
                <MaterialPreview detailId={detail.id} detailMaterial={detail.material}/>
            </div>
            <div className="detailsTab__data-item">{detail.w}</div>
            <div className="detailsTab__data-item">
                <ProductPreview detailId={detail.id} productId={detail.productId} />
            </div>
            <div className="detailsTab__data-item">
                <Texture
										detailMaterial={detailMaterial}
                    isRotateTexture={detail.isRotateTexture}
                    id={detail.id}
                />
            </div>
            <div className="detailsTab__data-item">
                <Edges detailId={detail.id} detailMaterial={detail.material} detailEdges={detail.edges} />
            </div>
            {hasErrors && <div className="detailsTab__data-item detailsTab__number">
                <Errors detailId={detail.id} errorMessages={detail?.error?.allErrorsMessage} />
            </div>}
            <div className="detailsTab__data-item detailsTab__number">
                <DeleteBtn detail={detail} remove={remove} isDisabled={!permissions.includes("KM_deleteDetail")}/>
            </div>
        </DetailWrapper>
    )
};