import Helpers from "./3D/Helpers";
import SizeArrow from "./3D/components/SizeArrow";
import Processing from "./3D/Processing";
import {empty, isDefined, isset} from "../helpers/helper";
import Languages from "../translation/Languages";
import * as template from './template.json'

export default class Groove extends Processing {
  _ext = false;
  _direction = 'hor'; //hor || ver
  _subType = 'groove';
  _isActive = false;
  _x;
  _y;
  _showArrow = false;
  checkReqFieldsTimer;
  _additionalCutter = true;
  isAdditionalCutterDisabled = false;

  constructor({
      detail,
      dataForConstructor = {},
      side = 'back',
      groove = 'select',
      x = 0,
      y = detail.h / 2,
      r = 2,
      z = detail.w / 2,
      depth = 4,
      ext = true,
      comment = "",
      height = 6,
      width = detail.l,
    x_axis = 'left',
    y_axis = 'bottom',
      id,
      isErrorText = '',
      additionalInfo = '',
      isInit = false,
                additionalCutter = true,
                isTemplate = null
    }) {

    super({x, y, z, r, x_axis, y_axis, depth, detail, side, id, comment, isErrorText, additionalInfo, isInit, isTemplate});
    this._groove = groove
    this._dataForConstructor = dataForConstructor
    this._additionalCutter = additionalCutter
    // if(!empty(dataForConstructor.direction)) {
    //   this._direction = dataForConstructor.direction;
    // } else {
      switch (this.side) {
        case 'left':
        case 'right':
          this._direction = 'ver';
          this._x_axis = this.side;
          break
        case 'top':
        case 'bottom':
          this._direction = 'hor';
          this._y_axis = this.side;
          break
        default:
          this._direction = height > width ? 'ver' : 'hor';
          break
      }
    //   this.updateDataForConstructor({name: 'direction', value: this.direction})
    // }

    if(!empty(this.getDataForConstructor().height)) {
      this._height = this.getDataForConstructor().height
    } else {
      this._height = Math.min(height, width)
      this.updateDataForConstructor({name: 'height', value: this.height})
    }
    if(!empty(this.getDataForConstructor().width)) {
      this._width = this.getDataForConstructor().width
    } else {
      this._width = Math.max(height, width);
      this.updateDataForConstructor({name: 'width', value: this.width})
    }
    this._ext = ext;
    // if(isset(this.getDataForConstructor().ext)) {
    //   this._ext = this.getDataForConstructor().ext
    // } else {
    //   this._ext = ext
    //   this.updateDataForConstructor({name: 'ext', value: ext})
    // }
    if(!empty(this.getDataForConstructor().x_axis)) {
      this._x_axis = this.getDataForConstructor().x_axis;
    } else {
      this._x_axis = 'left';
      this.updateDataForConstructor({name: 'x_axis', value: 'left'})
    }
    if(!empty(this.getDataForConstructor().x)) {
      this._x = this.getDataForConstructor().x
    }
    if(!empty(this.getDataForConstructor().y_axis)) {
      this._y_axis = this.getDataForConstructor().y_axis;
    } else {
      this._y_axis = 'bottom';
      this.updateDataForConstructor({name: 'y_axis', value: 'bottom'})
    }

    if(!empty(this.getDataForConstructor().y)) {
      this._y = this.getDataForConstructor().y
    }


    this.addParams();
    this.initRequiredFields()
    if ((this.height === this.detail.h && this.direction === 'ver') || (this.width === this.detail.l && this.direction === 'hor')) {
      this.r = 0
      this.setFormField({name: 'r', visible: false, value: this.r})
    }
  }

  calcMaxWidth() {
    let val;
    switch (this.side) {
      case 'front':
      case 'back':
        val = this.direction === "hor" ? this.detail.l - this.x : this.detail.h - this.y
        break;
      case 'top':
      case 'bottom':
        val = this.detail.l - this.x
        break;
      case 'left':
      case 'right':
        val = this.detail.h - this.y
    }
    return val;
  }

  calcMaxHeight() {
    let val;
    switch (this.side) {
      case 'front':
      case 'back':
        val = this.direction === "hor" ? this.detail.h - this.y : this.detail.l - this.x
        break;
      case 'top':
      case 'bottom':
      case 'left':
      case 'right':
        val = this.detail.w - this.z
        break;
    }
    return val;
  }

  initRequiredFields () {
    // clearTimeout(this.checkReqFieldsTimer);
    // setTimeout(() => {
      this.requiredFields = {
        r: {
          max:  Math.min(this.width, this.height) / 2,
          min: ['left', 'top', 'right', 'bottom'].includes(this.side) ?  0 : this.minValueForValidate('r', 2)
        },
        width: { //Довжина
          min: this.r * 2 || 3,
          max: this.calcMaxWidth()
        },
        height: { //Ширина
          min: this.minValueForValidate('height', 4),
          max: this.calcMaxHeight()
        },
        x: {
          min: 0,
          max: this.detail.l - this.widthCalc
        },
        y: {
          min: 0,
          max: ['left','right', ].includes(this.side) ? this.height : this.detail.h - this.heightCalc
        },
        depth: {
          min: 1,
          max: ['front', 'back'].includes(this.side) ? this.detail.w : 100
        },
        z: {
          min: ['front', 'back'].includes(this.side) ? 0 : 3,
          max: ['front', 'back'].includes(this.side) ? 100 : this.detail.w - this.height -3
        }
      }
    // }, 10)

  }

  addParams() {
    let f_value = ''
    const temp = template.default.groove[this.side === "front" || this.side === 'back' ? 'face' : 'torec'].map(item => {
        return {key: item.name, value: Languages.getTranslation(item.name, true)}})

    this.setFormField({name:'groove',
                      label: Languages.getTranslation('type-of-groove', true),
                      type: 'select',
                      value: this.groove,
                      variables: [
                        { key: "select", value: Languages.getTranslation('Select-groove', true) }, ...temp], visible: true})
    this.setFormField({name:'side', label: Languages.getTranslation('side', true), type: 'select', typeProcce: 'groove', value: this.side, variables: [
        { key: "front", value: Languages.getTranslation('face', true) },
        { key: "back", value: Languages.getTranslation('back', true) },
        ...this.getSidesValues()
      ], visible: true})

    f_value = this._dataForConstructor.hasOwnProperty('f_width') ? this._dataForConstructor['f_width'] : '';
    this.setFormField({name: 'width', value: this.width, type: 'number', label: `${Languages.getTranslation('height', true)} (pw)`, additionalParam : [
        {name: 'formula', label: Languages.getTranslation('formula', true), value: f_value},
        {name: 'allWidth', label: Languages.getTranslation('full-length', true), callback: () => {
          this.setAllWidth();
        }}
      ]});
    this.setFormField({name: 'height', value: this.height, type: 'number', label: Languages.getTranslation('width', true)})
    // this.setFormField({name: 'x_axis', value: this.x_axis,  label: `${Languages.getTranslation('side-to-exist', true)} x`, type: 'select', visible: !['left', 'right'].includes(this.side)})
    // this.setFormField({name: 'y_axis', value: this.y_axis, label: `${Languages.getTranslation('side-to-exist', true)} y`, type: 'select', visible: !['top', 'bottom'].includes(this.side)})
    this.setFormField({name: 'z', value: this.z, label: 'z', type: 'number', visible: !['front', 'back'].includes(this.side), additionalParam : [
        {name: 'center_z', label: `${Languages.getTranslation('center-on-axis', true)} Z`, callback: () => {
            this.setCenterZ();
          }}
      ]})

    f_value = this._dataForConstructor.hasOwnProperty('f_y') ? this._dataForConstructor['f_y'] : '';
    this.setFormField({name: 'y', value: this.y, type: 'number', label: `${Languages.getTranslation('distance-for-groove', true)}, Y (py)`, visible: !['top', 'bottom'].includes(this.side), additionalParam : [
        {name: 'formula', label: Languages.getTranslation('formula', true), value: f_value},
        {name: 'center_y', label: `${Languages.getTranslation('center-on-axis', true)} Y`, callback: () => {
            this.setCenterY();
          }}
      ]})

    f_value = this._dataForConstructor.hasOwnProperty('f_x') ? this._dataForConstructor['f_x'] : '';
    this.setFormField({name: 'x', value: this.x, type: 'number', label: `${Languages.getTranslation('distance-for-groove', true)}, X (px)`, visible: !['left', 'right'].includes(this.side), additionalParam : [
        {name: 'formula', label: Languages.getTranslation('formula', true), value: f_value},
        {name: 'center_x', label: `${Languages.getTranslation('center-on-axis', true)} X`, callback: () => {
            this.setCenterX();
          }}
      ]})
    this.setFormField({name:'x_axis', value: this.x_axis, label: `${Languages.getTranslation('side-to-exist', true)} х`, type: 'select', variables: [
        { key: "left", value: Languages.getTranslation('to-left', true) },
        { key: "right", value: Languages.getTranslation('to-right', true) },
      ], visible: !['left', "right"].includes(this.side)})
    this.setFormField({name:'y_axis', value: this.y_axis, label: `${Languages.getTranslation('side-to-exist', true)} у`, type: 'select', variables: [
        { key: "top", value: Languages.getTranslation('to-top', true) },
        { key: "bottom", value: Languages.getTranslation('to-bottom', true) },
      ], visible: !['top', "bottom"].includes(this.side)})
    this.setFormField({name:'direction', type: 'select', label: Languages.getTranslation('direction', true), value: this.direction, variables: [
        { key: "hor", value: Languages.getTranslation('horizontal', true) },
        { key: "ver", value: Languages.getTranslation('vertical', true) },
      ], visible: ['front', 'back'].includes(this.side)})
    this.setFormField({name: 'ext', type: 'checkbox-orig', label: Languages.getTranslation('closed', true), value: this.ext, visible: true})
    this.setFormField({name: 'additionalCutter', type: 'checkbox', label: Languages.getTranslation('Finishing-milling-cutter', true), value: this.additionalCutter, visible: this.isShowAdditionalCutter()})
    this.setFormField({name: 'depth', label: Languages.getTranslation('depth', true), value: this.depth, type: 'number'})
    this.setFormField({name: 'r', label: Languages.getTranslation('radius', true), value: this.r, type: 'number', visible: !['top', 'right', 'left', "bottom"].includes(this.side)})
    this.getSidesForCompactPlite()
  }

  get paramsSorting() {
    const sortingArr = ['side', 'groove', 'width', 'height', 'x_axis', 'y_axis', 'x',  'direction', 'z', 'y',  'depth', 'additionalCutter', 'ext', 'r', 'comment']
    return this.formFields.sort((a, b)=> sortingArr.indexOf(a.name) - sortingArr.indexOf(b.name))
  }

  get cutOut() {
    // return [];
    let width = this.widthCalc,
        height = this.heightCalc,
        r = this.r;
    this.fixMultiplicity();
    let x = this.xCalcForCutOut;
    let y = this.yCalcForCutOut
    if(this.direction === 'hor') {
      r = x === 0 || x + width >= this.detail.l ? 0 : r;
    } else {
      r = y === 0 || y + height >= this.detail.h ? 0 : r
    }
    // if(this.side === 'right') y = this.y;
    const cutout = {
      x: x,
      y: y,
      z: this.z,
      side: this.side,
      height,
      width,
      type: 'cutout',
      subType: 'groove',
      depth: this.depth,
      multiplicity: this.multiplicity,
      ext: this.ext,
      edge: null,
      color: this.getMeshColor(),
      direction: this.direction,
      elements: []
    }

    switch (this.side) {
      case 'front':
      case 'back':
        if(r > 0) {
          cutout.elements.push(Helpers.createLine(0, r, 0, height - r));
          cutout.elements.push(Helpers.createArc(0, height - r, r, height, r, r, height - r));
          cutout.elements.push(Helpers.createLine(r, height, width - r, height));
          cutout.elements.push(Helpers.createArc(width - r, height, width, height - r, r, width - r, height - r));
          cutout.elements.push(Helpers.createLine(width, height - r, width, r));
          cutout.elements.push(Helpers.createArc(width, r, width - r, 0, r, width - r, r));
          cutout.elements.push(Helpers.createLine(width - r, 0, r, 0));
          cutout.elements.push(Helpers.createArc(r, 0, 0, r, r, r, r));
        } else {
          cutout.elements.push(Helpers.createLine(0, 0, 0, height));
          cutout.elements.push(Helpers.createLine(0, height, width, height));
          cutout.elements.push(Helpers.createLine(width, height, width, 0));
          cutout.elements.push(Helpers.createLine(width, 0, 0, 0));
        }
        break
      case 'left':
        if(r > 0) {
          cutout.elements.push(Helpers.createLine(0, r, 0, height - r));
          cutout.elements.push(Helpers.createArc(0, height - r, r, height, r, r, height - r));
          cutout.elements.push(Helpers.createLine(r, height, width - r, height));
          cutout.elements.push(Helpers.createArc(width - r, height, width, height - r, r, width - r, height - r));
          cutout.elements.push(Helpers.createLine(width, height - r, width, r));
          cutout.elements.push(Helpers.createArc(width, r, width - r, 0, r, width - r, r));
          cutout.elements.push(Helpers.createLine(width - r, 0, r, 0));
          cutout.elements.push(Helpers.createArc(r, 0, 0, r, r, r, r));
        } else {
          cutout.elements.push(Helpers.createLine(0, 0, 0, height));
          cutout.elements.push(Helpers.createLine(0, height, width, height));
          cutout.elements.push(Helpers.createLine(width, height, width, 0));
          cutout.elements.push(Helpers.createLine(width, 0, 0, 0));
        }
        cutout.x = this.detail.w - this.widthCalc - this.z
        break
      case 'top':
        if(r > 0) {
          cutout.elements.push(Helpers.createLine(0, r, 0, width - r));
          cutout.elements.push(Helpers.createArc(0, width - r, r, width, r, r, width - r));
          cutout.elements.push(Helpers.createLine(r, width, height - r, width));
          cutout.elements.push(Helpers.createArc(height - r, width, height, width - r, r, height - r, width - r));
          cutout.elements.push(Helpers.createLine(height, width - r, height, r));
          cutout.elements.push(Helpers.createArc(height, r, height - r, 0, r, height - r, r));
          cutout.elements.push(Helpers.createLine(height - r, 0, r, 0));
          cutout.elements.push(Helpers.createArc(r, 0, 0, r, r, r, r));
        } else {
          cutout.elements.push(Helpers.createLine(0, 0, 0, width));
          cutout.elements.push(Helpers.createLine(0, width, height, width));
          cutout.elements.push(Helpers.createLine(height, width, height, 0));
          cutout.elements.push(Helpers.createLine(height, 0, 0, 0));
        }
        cutout.x = this.detail.w - this.heightCalc - this.z
        cutout.y = this.x_axis === 'left'
          ? this.x
          : this.detail.l - this.widthCalc - this.x
        break
      case 'bottom':
        if(r > 0) {
          cutout.elements.push(Helpers.createLine(0, r, 0, width - r));
          cutout.elements.push(Helpers.createArc(0, width - r, r, width, r, r, width - r));
          cutout.elements.push(Helpers.createLine(r, width, height - r, width));
          cutout.elements.push(Helpers.createArc(height - r, width, height, width - r, r, height - r, width - r));
          cutout.elements.push(Helpers.createLine(height, width - r, height, r));
          cutout.elements.push(Helpers.createArc(height, r, height - r, 0, r, height - r, r));
          cutout.elements.push(Helpers.createLine(height - r, 0, r, 0));
          cutout.elements.push(Helpers.createArc(r, 0, 0, r, r, r, r));
        } else {
          cutout.elements.push(Helpers.createLine(0, 0, 0, width));
          cutout.elements.push(Helpers.createLine(0, width, height, width));
          cutout.elements.push(Helpers.createLine(height, width, height, 0));
          cutout.elements.push(Helpers.createLine(height, 0, 0, 0));
        }
        cutout.x = this.detail.w - this.heightCalc - this.z
        cutout.y = this.x_axis === 'right'
          ? this.x
          : this.detail.l - this.widthCalc - this.x
        break
      case "right":
        if(r > 0) {
          cutout.elements.push(Helpers.createLine(0, r, 0, height - r));
          cutout.elements.push(Helpers.createArc(0, height - r, r, height, r, r, height - r));
          cutout.elements.push(Helpers.createLine(r, height, width - r, height));
          cutout.elements.push(Helpers.createArc(width - r, height, width, height - r, r, width - r, height - r));
          cutout.elements.push(Helpers.createLine(width, height - r, width, r));
          cutout.elements.push(Helpers.createArc(width, r, width - r, 0, r, width - r, r));
          cutout.elements.push(Helpers.createLine(width - r, 0, r, 0));
          cutout.elements.push(Helpers.createArc(r, 0, 0, r, r, r, r));
        } else {
          cutout.elements.push(Helpers.createLine(0, 0, 0, height));
          cutout.elements.push(Helpers.createLine(0, height, width, height));
          cutout.elements.push(Helpers.createLine(width, height, width, 0));
          cutout.elements.push(Helpers.createLine(width, 0, 0, 0));
        }
        cutout.x = this.detail.w - this.widthCalc - this.z
        break
    }

    return cutout;
  }

  get realData() {
    this.updateDataForConstructor({name: 'x', value: this.x});
    this.updateDataForConstructor({name: 'y', value: this.y});
    this.updateDataForConstructor({name: 'x_axis', value: this.x_axis});
    this.updateDataForConstructor({name: 'y_axis', value: this.y_axis});
    let
      x = this.xCalc,
      y = this.yCalc,
      z = this.z,
      r = this.r,
      id = this.id,
      type = "Groove",
      depth = this.depth,
      ext = this.ext,
      height = this.heightCalc,
      width = this.widthCalc,
      x_axis = this.x_axis,
      y_axis = this.y_axis,
      side = this.side,
      edge= null,
      direction = this.direction,
      additionalCutter = this.additionalCutter

    return {x, y, id, z, r, additionalCutter, x_axis, y_axis, height, width, side, depth, type, ext, comment: this.comment, edge, direction, isTemplate: this.isTemplate}
  }

  get x(){
    return this._x
  }

  isShowAdditionalCutter() {
    let show = this.checkIfOnFace() && !this.checkFullWidth()
    if(this.height > 20 && show) {
      show = false
    }
    return show;
  }

  set x(x){
    this.initRequiredFields()
    if(typeof x === 'number'){
      if(!this.checkRequiredField('x', Number(x))) {
        this._x = Number(x);
        this.setFormField({
          name: 'x',
          value: this.x
        })
        this.updateDataForConstructor({name: 'f_x', value: ''})
        this.updateDataForConstructor({name: 'x', value: this.x})
        this.renderDetail()
      } else {
        this.showErrors();
      }
    } else {
      if(!empty(x)) {
        if (!empty(this._dataForConstructor['f_y'])
            && this._dataForConstructor['f_y'].includes('px')
            && x.includes('py')) {
          this.showToastError('refer-each-other')
        } else if (!empty(this._dataForConstructor['f_width'])
            && this._dataForConstructor['f_width'].includes('px')
            && x.includes('pw')) {
          this.showToastError('refer-each-other')
        } else if (!empty(this.formulaCalculation(x))) {
          const calculation = Number(Number(this.formulaCalculation(x)).toFixed(1));
          if(!this.checkRequiredField('x', calculation)) {
            this._x = calculation;
            this.setFormField({
              name: 'x',
              value: this.x,
              type: 'number',
              visible: !['left', 'right'].includes(this.side),
              additionalParam: [
                {name: 'formula', label: Languages.getTranslation('formula', true), value: x},
                {
                  name: 'center_x', label: `${Languages.getTranslation('center-on-axis', true)} X`, callback: () => {
                    this.setCenterX();
                  }
                }]
            })
            this.updateDataForConstructor({name: 'f_x', value: x})
            this.updateDataForConstructor({name: 'x', value: this.x})
            this.renderDetail()
          }else{
            this.showErrors()
          }
        }
      } else {
        this.updateDataForConstructor({name: 'f_x', value: ''})
      }
    }
    if(!empty(this._dataForConstructor['f_width']) && this._dataForConstructor['f_width'].includes('px')){
      this.width = this._dataForConstructor['f_width']
    }
    if(!empty(this._dataForConstructor['f_y']) && this._dataForConstructor['f_y'].includes('px')){
      this.y = this._dataForConstructor['f_y']
    }
    this.initRequiredFields()
  }

  get y(){
    return this._y;
  }

  set y(y){
    this.initRequiredFields()
    if(typeof y === 'number'){
      if(!this.checkRequiredField('y', Number(y))) {
        this._y = Number(y);
        this.setFormField({
          name: 'y',
          value: this.y
        })
        this.updateDataForConstructor({name: 'f_y', value: ''})
        this.updateDataForConstructor({name: 'y', value: this.y})
        this.renderDetail()
      } else {
        this.showErrors()
      }
    }else {
      if(!empty(y)){
        if (!empty(this._dataForConstructor['f_x'])
            && this._dataForConstructor['f_x'].includes('py')
            && y.includes('px')) {
          this.showToastError('refer-each-other')
        } else if (!empty(this._dataForConstructor['f_width'])
            && this._dataForConstructor['f_width'].includes('py')
            && y.includes('pw')) {
          this.showToastError('refer-each-other')
        } else if (!empty(this.formulaCalculation(y))) {
          const calculation = Number(Number(this.formulaCalculation(y)).toFixed(1));
          if(!this.checkRequiredField('y', calculation)) {
            this._y = calculation;
            this.setFormField({
              name: 'y',
              value: this.y
            })
            this.updateDataForConstructor({name: 'f_y', value: y})
            this.updateDataForConstructor({name: 'y', value: this.y})
            this.renderDetail()
          }else{
            this.showErrors()
          }
        }
      }else{
        this.updateDataForConstructor({name: 'f_y', value: ''})
      }
    }
    if(!empty(this._dataForConstructor['f_width']) && this._dataForConstructor['f_width'].includes('py')){
      this.width = this._dataForConstructor['f_width']
    }
    if(!empty(this._dataForConstructor['f_x']) && this._dataForConstructor['f_x'].includes('py')){
      this.x = this._dataForConstructor['f_x']
    }
    this.initRequiredFields()
  }

  addSizesArrows() {
    if(!this.isActive) return false;
    let pos = this.x_axis + '_' + this.y_axis;
    let x = this.x;
    let y = this.y;
    let size = null;
    switch (this.side) {
      case 'left':
        size = this.heightCalc
        x = this.depth
        break
      case 'top':
        y = this.depth
        size = this.widthCalc
        break;
      case 'right':
        size = this.heightCalc
        x = this.depth;
        break;
      case 'bottom':
        y = this.depth
        size = this.widthCalc
        break;
    }
    return SizeArrow.getElementSizeArrow(
      x,
      y,
      null,
      pos,
      this.detail,
      false,
      false,
      // size
    )
  }

  get dataForValidate() {
    return this.realData
  }

  get allWidth() {
    switch (this.direction) {
      case 'hor':
        return this.widthCalc === this.detail.l
      case 'ver':
        return this.widthCalc === this.detail.h
    }
  }

  changeRForAllWidth() {
    this.initRequiredFields()
    if ((this.height === this.detail.h && this.direction === 'ver') || (this.width === this.detail.l && this.direction === 'hor')) {
      this.r = 0
      this.setFormField({name: 'r', visible: false, value: this.r})
    }
  }

  get r() {
    return this._r;
  }

  set r(r) {
    this.initRequiredFields()
    if(!this.checkRequiredField('r', Number(r))) {
      this._r = Number(r);
      this.setFormField({name: 'r',value: this.r, })
      this.renderDetail()
    } else {
      this.showErrors()
    }

  }

  setCenterX() {
    this.x = this.detail.l / 2 - this.widthCalc / 2;
    this.setFormField({name: 'x', value: this.x});
  }
  setCenterY() {
    this.y = this.detail.h / 2 - this.heightCalc / 2;
    this.setFormField({name: 'y', value: this.y});
  }
  setCenterZ() {
    switch (this.side) {
      case 'top':
      case 'bottom':
        this.z = this.detail.w / 2 - this.heightCalc / 2;
        break
      case 'left':
      case 'right':
        this.z = this.detail.w / 2 - this.widthCalc / 2;
        break
    }
    this.setFormField({name: 'z', value: this.z});
    // this.buildDetail();
    this.renderDetail();
  }

  setAllWidth() {
    switch (this.direction) {
      case 'hor':
        switch (this.side) {
          case 'front':
          case 'back':
            this.x = 0;
            this.width = this.detail.l;
            break
          case 'top':
          case 'bottom':
            this.y = 0;
            this.width = this.detail.l;
            break
        }
        this.r = 0
        this.setFormField({name: 'r', visible: false});
        this.setFormField({name: 'width', value: this.width});
        this.additionalCutter = false
        this.setFormField({name: 'additionalCutter', visible: false})
        break
      case 'ver':
        this.y = 0;
        switch (this.side) {
          case 'front':
          case 'back':
            this.width = this.detail.h;
            break
          case 'left':
          case 'right':
            this.width = this.detail.h;
            break
        }
        this.r = 0
        this.setFormField({name: 'r', visible: false});
        this.additionalCutter = false
        this.setFormField({name: 'additionalCutter', value: this.additionalCutter, visible: false})
        break
    }

    // this.isAdditionalCutterDisabled = true;
    // this.additionalCutter = false;
    this.renderDetail();
    // this.buildDetail();
  }

  get direction() {
    return this._direction;
  }

  set direction(direction) {
    this.initRequiredFields()
    this._direction = direction;
    this.updateDataForConstructor({name: 'direction', value: this.direction});
    this.setFormField({name: 'direction', value: this.direction});
    switch (this.side) {
      case 'front':
      case 'back':
        this.setCenterX();
        this.setCenterY();
        if(this.direction === 'hor') {
          if(this.x < 0) {
            this.x = 0
          }
          if(this.width > this.detail.l - this.x) {
            this.width = this.detail.l - this.x;
          } else {
            this.renderDetail();
          }
        } else {
          if(this.y < 0) {
            this.y = 0
            this.initRequiredFields()
          }
          if(this.width > this.detail.h - this.y) {
            this.width = this.detail.h - this.y
          } else {
            this.renderDetail();
          }
        }
        break;
      default:
        this.renderDetail();
    }
    this.initRequiredFields();
    if ((this.height === this.detail.h && this.direction === 'ver') || (this.width === this.detail.l && this.direction === 'hor')) {
      this.r = 0
      this.setFormField({name: 'r', visible: false})
    } else {
      this.r = 2
      this.additionalCutter = true
      this.setFormField({name: 'r', visible: true})
    }
    this.updateDataForConstructor({name: 'height', value: this.height});
    this.updateDataForConstructor({name: 'width', value: this.width});
  }

  get width() {
    return this._width;
  }

  set width(width) {
    this.initRequiredFields()
    if(typeof width === 'number'){
      if(!this.checkRequiredField('width', Number(width))) {
        this._width = Number(width);
        this.setFormField({
          name: 'width', value: this.width, type: 'number', label: `${Languages.getTranslation('height', true)} (pw)`, additionalParam: [
            {name: 'formula', label: Languages.getTranslation('formula', true), value: ''},
            {
              name: 'allWidth', label: Languages.getTranslation('full-length', true), callback: () => {
                this.setAllWidth();
              }
            }
          ]
        });
        this.updateDataForConstructor({name: 'f_width', value: ''})
      } else {
        this.showErrors()
      }
    } else {
      if(!empty(width)){
        if (!empty(this._dataForConstructor['f_x'])
            && this._dataForConstructor['f_x'].includes('pw')
            && width.includes('px')) {
          this.showToastError('refer-each-other')
        } else if (!empty(this._dataForConstructor['f_y'])
            && this._dataForConstructor['f_y'].includes('pw')
            && width.includes('py')) {
          this.showToastError('refer-each-other')
        } else if (!empty(this.formulaCalculation(width))) {
          const calculation = Number(Number(this.formulaCalculation(width)).toFixed(1));
          if(!this.checkRequiredField('width', calculation)) {
            this._width = calculation;
            this.setFormField({
              name: 'width', value: this.width, type: 'number', label: `${Languages.getTranslation('height', true)} (pw)`, additionalParam: [
                {name: 'formula', label: Languages.getTranslation('formula', true), value: width},
                {
                  name: 'allWidth', label: Languages.getTranslation('full-length', true), callback: () => {
                    this.setAllWidth();
                  }
                }
              ]
            });
            this.updateDataForConstructor({name: 'f_width', value: width})
          }else{
            this.showErrors()
          }
        }
      }else{
        this.updateDataForConstructor({name: 'f_width', value: ''})
      }
    }

    if(!empty(this._dataForConstructor['f_x']) && this._dataForConstructor['f_x'].includes('pw')){
      this.x = this._dataForConstructor['f_x']
    }
    if(!empty(this._dataForConstructor['f_y']) && this._dataForConstructor['f_y'].includes('pw')){
      this.y = this._dataForConstructor['f_y']
    }
    if(this.checkFullWidth()) {
      this.additionalCutter = false;
    } else {
      if(this.checkIfOnFace()) {
        this.additionalCutter = true;
      }
    }
    if ((this.height === this.detail.h && this.direction === 'ver') || (this.width === this.detail.l && this.direction === 'hor')) {
      this.r = 0
      this.setFormField({name: 'r', visible: false})
    } else {
      this.r = 2
      this.setFormField({name: 'r', visible: true})
      this.additionalCutter = true
    }
    this.updateDataForConstructor({name: 'width', value: this.width});
    this.renderDetail()

  }

  checkFullWidth() {
    return this.direction === 'hor' && this.widthCalc === this.detail.l || this.direction === 'ver' && this.widthCalc === this.detail.h
  }

  checkIfOnFace() {
    return ['front', 'back'].includes(this.side)
  }

  get height() {
    return this._height;
  }

  set height(height) {
    this.initRequiredFields()
    if(!this.checkRequiredField('height', Number(height))) {
      this._height = Number(height);
      this.setFormField({name: 'height',value: this.height, label: Languages.getTranslation('width', true)})
      if(this.height <= 20 && !this.checkFullWidth() && this.checkIfOnFace()) {
        this.additionalCutter = true
      } else {
        this.additionalCutter = false
      }
      // this.setFormField({name: 'additionalCutter', visible: this.isShowAdditionalCutter()})
      this.updateDataForConstructor({name: 'height', value: this.height})
      // this.buildDetail()
      //   .then(() => this.renderDetail())
      this.initRequiredFields()
      this.renderDetail()
    } else {
      this.showErrors()
    }

  }

  set additionalCutter(additionalCutter) {
    this._additionalCutter = additionalCutter;
    this.setFormField({name: 'additionalCutter', value: this.additionalCutter, visible: this.isShowAdditionalCutter()})
  }

  get additionalCutter() {
    return this._additionalCutter;
  }

  get widthCalc() {
    if (this.direction === "hor") {
      return this.width;
    } else {
      return this.height;
    }
  }

  get heightCalc() {
    if (this.direction === "hor") {
      return this.height;
    } else {
      return this.width;
    }
  }

  get xCalc() {
    if (this.allWidth && this.direction === "hor") return 0;
    switch (this.side) {
      case 'front':
      case 'back':
      case 'top':
      case 'bottom':
        if (this.x_axis === "right") {
          return this.detail.l - this.x - this.widthCalc
        } else {
          return this.x;
        }
      case 'right':
        // if (this.x_axis === "right") {
        //   return this.detail.h - this.x;
        // } else {
          return this.x
        // }
      default:
        // if (this.x_axis === "right") {
        //   return this.detail.h - this.x - this.height;
        // } else {
          return this.x;
        // }
    }
  }

  get yCalc() {
    if (this.allWidth && this.direction === "ver") return 0;
    switch (this.side) {
      case 'front':
      case 'back':
        if (this.y_axis === "top") {
          return this.detail.h - this.y - this.heightCalc;
        } else {
          return this.y;
        }
      case 'right':
        if (this.y_axis === "top") {
          return this.detail.h - this.y - this.heightCalc;
        } else {
          return this.y
        }
      case 'left':
        if (this.y_axis === 'top') {
          return this.detail.h - this.y - this.heightCalc;
        } else {
          return this.y;
        }
      default:
        if (this.y_axis === "top") {
          return this.detail.l - this.y - this.widthCalc;
        } else {
          return this.y;
        }
    }
  }

  get xCalcForCutOut() {
    if (this.allWidth && this.direction === "hor") return 0;
    switch (this.side) {
      case 'front':
      case 'back':
      case 'top':
      case 'bottom':
        if (this.x_axis === "right") {
          return this.detail.l - this.x - this.widthCalc
        } else {
          return this.x;
        }
      case 'right':
        // if (this.x_axis === "right") {
        //   return this.detail.h - this.x;
        // } else {
        return this.x
      // }
      default:
        // if (this.x_axis === "right") {
        //   return this.detail.h - this.x - this.height;
        // } else {
        return this.x;
      // }
    }
  }

  get yCalcForCutOut() {
    if (this.allWidth && this.direction === "ver") return 0;
    switch (this.side) {
      case 'front':
      case 'back':
        if (this.y_axis === "top") {
          return this.detail.h - this.y - this.heightCalc;
        } else {
          return this.y;
        }
      case 'right':
        if (this.y_axis === "top") {
          return this.y
        } else {
          return this.detail.h - this.y - this.heightCalc;

        }
      case 'left':
        if (this.y_axis === 'top') {
          return this.detail.h - this.y - this.heightCalc;
        } else {
          return this.y;
        }
      default:
        if (this.y_axis === "top") {
          return this.detail.l - this.y - this.widthCalc;
        } else {
          return this.y;
        }
    }
  }

  get ext() {
    return this._ext;
  }

  set ext(ext) {
    this._ext = ext;
    this.setFormField({name: 'ext', value: ext})
    this.updateDataForConstructor({name: 'ext', value: ext});
    // this.buildDetail()
    //   .then(() => this.renderDetail())

    this.renderDetail()
  }

  get side() {
    return this._side;
  }
  set side(side) {
    const prewSide = this.side;
    this.defaultValue(prewSide, side);
    this._side = side;
    this.setFormField({name: 'side', value: side, label: Languages.getTranslation('side', true)});
    this.initRequiredFields()
    switch (side) {
      case 'left':
      case 'right':
        this.direction = 'ver';
        this.y = 0;
        this.x = side === 'left' ? 0 : this.detail.l - this.depth;
        this.r = 0;
        this.width = this.width > this.detail.h ? this.detail.h : this.width
        this.x_axis = side;
        this.y_axis = 'bottom';
        this.additionalCutter = false;
        this.setCenterZ()
        this.setFormField({name: 'direction', visible: false})
        this.setFormField({name: 'r', visible: false})
        this.setFormField({name: 'y', visible: true})
        this.setFormField({name: 'x', visible: false})
        this.setFormField({name: 'z', visible: true})
        this.setFormField({name: 'x_axis', visible: false})
        this.setFormField({name: 'y_axis', visible: true})
        this.setFormField({name: 'additionalCutter', visible: false})
        this.setFormField({name: 'groove', variables:
              [{ key: "select", value: Languages.getTranslation('Select-groove', true) }, ...template.default.groove.torec.map(item => {
                return {key: item.name, value: Languages.getTranslation(item.name, true)}})]})
        break
      case 'top':
      case 'bottom':
        this.direction = 'hor';
        this.y = side === 'bottom' ? 0 : this.detail.h - this.depth;
        this.x = 0
        this.y_axis = side;
        this.r = 0;
        this.width = this.x + this.width > this.detail.l ?  this.detail.l - this.x : this.width
        this.additionalCutter = false;
        this.setCenterZ()
        this.setFormField({name: 'r', visible: false})
        this.setFormField({name: 'direction', visible: false})
        this.setFormField({name: 'z', visible: true})
        this.setFormField({name: 'y', visible: false})
        this.setFormField({name: 'x', visible: true})
        this.setFormField({name: 'x_axis', visible: true})
        this.setFormField({name: 'y_axis', visible: false})
        this.setFormField({name: 'additionalCutter', visible: false})
        this.setFormField({name: 'groove', variables: []})
        this.setFormField({name: 'groove', variables:
              [{ key: "select", value: Languages.getTranslation('Select-groove', true) }, ...template.default.groove.torec.map(item => {
                return {key: item.name, value: Languages.getTranslation(item.name, true)}})]})
        break
      default:
        if(!['front', 'back'].includes(prewSide)) {
          this.x = this.direction === "hor" ? this.x : this.detail.l / 2
          this.y = this.direction === "ver" ? this.y : this.detail.h / 2
          this.r = 2


          this.setFormField({name: 'direction', visible: true})
          this.setFormField({name: 'ext', visible: true})
          this.setFormField({name: 'z', visible: false})
          this.setFormField({name: 'x', visible: true})
          this.setFormField({name: 'y', visible: true})
          this.setFormField({name: 'r', visible: true})
          this.setFormField({name: 'x_axis', visible: true})
          this.setFormField({name: 'y_axis', visible: true})
          this.setFormField({name: 'additionalCutter', visible: true})
          this.setFormField({name: 'groove', variables:
                [{ key: "select", value: Languages.getTranslation('Select-groove', true) }, ...template.default.groove.face.map(item => {
                  return {key: item.name, value: Languages.getTranslation(item.name, true)}})]})

          // this.buildDetail();
          // this.renderDetail();
          break
        }
    }
    this.initRequiredFields()
    this.renderDetail()
  }

  get groove(){
    return this._groove
  }

  defaultValue(prevSide, side){
    if((["left", "top", "right", "bottom"].includes(prevSide) && ["front", "back"].includes(side)) ||
        (["left", "top", "right", "bottom"].includes(side) && ["front", "back"].includes(prevSide))){
      this.height = 6;
      this.setFormField({name: 'height', value: 6, disabled: false})
      this.depth = 4;
      this.setFormField({name: 'depth', value: 4, disabled: false})
    }
  }

  set groove(groove) {
    this._groove = groove;
    this.setFormField({name: 'groove', value: this.groove, label: Languages.getTranslation('Select-groove', true)});

    const currentGroove = template.default.groove[
        this.side === "front" || this.side === 'back' ? 'face' : 'torec'].find(item => item.name === groove)

    if(groove === 'select'){
      this.height = 6;
      this.setFormField({name: 'height', value: 6, disabled: false})
      this.depth = 4;
      this.setFormField({name: 'depth', value: 4, disabled: false})
    }else{
        if (this.side === "front" || this.side === 'back') {
          this.height = currentGroove.w
          this.setFormField({name: 'height', value: currentGroove.w, disabled: true})
          this.depth = currentGroove.depth
          this.setFormField({name: 'depth', value: currentGroove.depth, disabled: true})
        }else{
            this.height = currentGroove.w
            this.setFormField({name: 'height', value: currentGroove.w, disabled: true})
        }
    }
    this.initRequiredFields()
  }

  // async updateDb() {
  //   return this.detailDb.updateDetail('rects', this.detail.getRectsData())
  // }
  get shape() {
    return this.realData
  }

  validate() {
    // if(this.height === 0) {
    //   this.detail.error.setError(`${Languages.getTranslation('width-cannot-be-0', true)}`, 'error', true);
    //   return Promise.reject('width-cannot-be-0')
    // }
    // if(this.depth === 0) {
    //   this.detail.error.setError(`${Languages.getTranslation('depth-cannot-be-0', true)}`, 'error', true);
    //   return Promise.reject('depth-cannot-be-0')
    // }
    return Promise.resolve()
  }

  fixMultiplicity() {
    if(!['front', 'back'].includes(this.side)) {
      if(this.z < 3 || this.z >  this.detail.w - 3) {
        this.setCenterZ();
      }
      return ;
    }
    const prData = this.getRectData()
    this._z = 0;
    this.multiplicity = 0;
    this._depth = (['circle', 'rectangle'].includes(this.subType)) ? this.detail.w : this.depth;
    this.detail.multiplicityClass.getRealParts().forEach(part => {
      if(!empty(prData) && Helpers.rectanglesAreNested(
        prData, part
      )) {
        this._z = this.detail.multiplicityClass.w;
        this._depth = this.depth > this.detail.w - this.detail.multiplicityClass.w ? this.detail.w - this.detail.multiplicityClass.w :  this.depth;
        this.multiplicity = this.detail.multiplicityClass.w
      }
    })
    this.setFormField({name: 'z', value: this.z});
    this.setFormField({name: 'depth', value: this.depth})
  }
  checkIfGrooveCenter(dir, data) {
    if (dir === 'h') {
      if ( (data.y === (this.detail.h / 2))) {
        data.error = "Can't Mirror"
      }
    }
    if (dir === 'v') {
      if ( (data.x === (this.detail.l / 2 - data.width / 2))) {
        data.error = "Can't Mirror"
      }
    }
  }

  minValueForValidate(flag, defaultValue) {

    if (flag === 'height') {
      if (['left', 'top', 'right', 'bottom'].includes(this.side)) {
        return 2.5
      }
    }
    if (flag === 'r') {
      if ((this.width === this.detail.h && this.direction === 'ver') || (this.width === this.detail.l && this.direction === 'hor')) {
        return 0
      }
    }
    return defaultValue;
  }

  mirror(axis, data, detailH, detailL) {
    const direction = data.width > data.height ? 'h' : 'v';
    if (axis === 'hor') {
      switch (data.side) {
        case 'front':
        case 'back':
          // this.checkIfGrooveCenter(direction, data)
          if(data.height < detailH / 2) {
            data.y = direction === "h" ? detailH - data.y : detailH - (data.y + data.height)
          }
          break
        case 'top' :
          data.side = 'bottom'
          break
        case 'bottom' :
          data.side = 'top'
          break
        case 'left' :
          if(data.height < detailH / 2) {
            data.side = 'left'
            data.y = detailH - data.y - data.height
          }
          break
        case 'right' :
          if(data.height < detailH / 2) {
            data.side = 'right'
            data.y = detailH - data.y - data.height
          }
          break
      }
    }
    if (axis === 'ver') {
      switch (data.side) {
        case 'front':
        case 'back':
          // this.checkIfGrooveCenter(direction, data)

          if(data.width < detailL / 2) {
            data.x = direction === "v" ? detailL - data.x : detailL - (data.x + data.width)
          }
              break
        case 'left' :
          data.side = 'right'
              break
        case 'right' :
          data.side = 'left'
              break
        case 'top' :
          if(data.width < detailL / 2) {
            data.side = 'top'
            data.x = detailL - data.x - data.width
          }
          break
        case 'bottom' :
          if(data.width < detailH / 2) {
            data.side = 'bottom'
            data.x = detailL - data.x - data.width
          }
          break
      }
    }
    if (data.error) {
      return null
    }
  }

  mirrorWithReverse(axis) {
    const data = {
      x: this.x,
      y: this.y,
      x_axis: this.x_axis,
      y_axis: this.y_axis
    }
    const direction = this.width > this.height ? 'h' : 'v';
    if (axis.hor) {
      switch (this.side) {
        case 'front':
        case 'back':
          this.y = direction === "h" ? this.detail.h - this.y : this.detail.h - (this.y + this.height)
          break
        case 'top' :
          this.side = 'bottom'
          this.x = data.x;
          this.x_axis = data.x_axis
          break
        case 'bottom' :
          this.x = data.x;
          this.x_axis = data.x_axis
          break
        case 'left' :
          if(this.height < this.detail.h / 2) {
            // this.side = 'left'
            const _axis = this.y_axis === 'top' ? 'bottom' : 'top';
            this.y_axis = _axis;
            this.updateDataForConstructor({name: 'y_axis', value: _axis})
          }
          break
        case 'right' :
          if(this.height < this.detail.h / 2) {
            // this.side = 'right'
            const _axis = this.y_axis === 'top' ? 'bottom' : 'top';
            this.y_axis = _axis;
            this.updateDataForConstructor({name: 'y_axis', value: _axis})

          }
          break
      }
    }
    if (axis.ver) {
      switch (this.side) {
        case 'front':
        case 'back':
          this.x = direction === "v" ? this.detail.l - this.x : this.detail.l - (this.x + this.width)
          break
        case 'left' :
          this.side = 'right'
          this.y = data.y;
          this.y_axis = data.y_axis
            break
        case 'right' :
          this.side = 'left'
          this.y = data.y;
          this.y_axis = data.y_axis
            break
        case 'top' :
          if(this.width < this.detail.l / 2) {
            const _axis = this.x_axis === 'left' ? 'right' : 'left';
            this.x_axis = _axis;
            this.updateDataForConstructor({name: 'x_axis', value: _axis})
          }
          break
        case 'bottom' :
          if(this.width < this.detail.h / 2) {
            // this.side = 'bottom'
            const _axis = this.x_axis === 'left' ? 'right' : 'left';
            this.x_axis = _axis;
            this.updateDataForConstructor({name: 'x_axis', value: _axis})
            // this.x = this.detail.l - this.x - this.widthCalc
          }
          break
      }
    }
    if (this.error) {
      return null
    }
  }

  rotateDetail(direction) {
    let x = this.y, y = this.x, x_axis, y_axis;
    this.direction = this.direction === 'hor' ? 'ver': 'hor';
    if(direction) {
      y_axis = (this.x_axis === 'right') ? 'bottom' : 'top';
      x_axis = (this.y_axis === 'bottom') ? 'left' : 'right';
    } else {
      y_axis = (this.x_axis === 'right') ? 'top' : 'bottom';
      x_axis = (this.y_axis === 'bottom') ? 'right' : 'left';
    }
    this.side = this.getNextSideForRotate(direction);
    this.initRequiredFields();
    this.x = x;
    this.y = y;
    this.x_axis = x_axis;
    this.y_axis = y_axis;
    this.updateDataForConstructor({name: 'x', value: this.x})
    this.updateDataForConstructor({name: 'y', value: this.y})
    this.updateDataForConstructor({name: 'x_axis', value: this.x_axis})
    this.updateDataForConstructor({name: 'y_axis', value: this.y_axis})
    this.updateDataForConstructor({name: 'direction', value: this.direction})
    return this.updateDb();
  }
}
