import {useEffect, useRef, useState} from "react";
import {toast} from "react-toastify";
import Languages from "../../../../../translation/Languages";

const EdgeItem = ({edge, idx, deleteEdge, edgesTypes, name}) => {
	const [edgeData, setEdgeData] = useState({})
	const inputThickness = useRef()
	const inputWidth = useRef()
	// TODO выя нить как будут переводится имена материалов
	const getEdgeData = () => {
		const edgeD = {
			thickness: edge.thickness || '',
			width: edge.width || '',
			name: edge.name || name,
			type: edge.type || 'АБС'
		}
		return edgeD
	}

	const handleChange = (event) => {
		let {name, value} = event.target;
		value = !isNaN(parseFloat(value))
			? parseFloat(value)
			: value;
		if(value > 3 && name === 'thickness'){
			value = 3
			toast.warning(Languages.getTranslation("biggest3mm", true), {
				onClose: () => {},
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
				toastId: `biggest3mm`,
			})
		}
		setEdgeData({
			...edgeData,
			[name]: value,
		});
	};

	const changeEdgeProps = () => {
		const {width, thickness, name, type} = edgeData
		edge.width = width
		edge.thickness = thickness
		edge.name = name
		edge.type = type
	}

	const enterKeyDownHandler = (e, inputType) => {
		if (e.key === 'Enter') {
			if (e.target.value === '') {
				toast.error(Languages.getTranslation('enter-value', true), {
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				})
				e.target.className = 'kronasMaterials__default-input kronasMaterials__error'
				return
			}
			e.target.className = 'kronasMaterials__default-input'
			inputType.current.focus()
		}
	}

	useEffect(() => {
		changeEdgeProps()
	}, [edgeData])

	useEffect(() => {
		setEdgeData(getEdgeData());
	}, [edge]);

	useEffect(() => {
		inputThickness.current.focus()
	}, [])

	return <div className="kronasMaterials__table-body-item" key={edge.article}>
		<div className="edgesKronas__container">
			<div className="kronasMaterials__data edgesKronas__height-width">
				<input type="number" className="kronasMaterials__default-input" value={edgeData.width}
					   name='width' onChange={(e) => handleChange(e)}
					   autoComplete='off'
					   ref={inputThickness}
					   onKeyDown={(e) => enterKeyDownHandler(e, inputWidth)}
				/>
			</div>
			<div className="kronasMaterials__data edgesKronas__height-width">
				<input type="number" className="kronasMaterials__default-input" value={edgeData.thickness}
					   // onKeyDown={(e) => enterKeyDownHandler(e, inputWidth)}
					   name='thickness' onChange={(e) => handleChange(e)}
					   ref={inputWidth}
					   autoComplete='off'
				/>
			</div>
			<div className="kronasMaterials__data edgesKronas__name">
				<input type="text" className="kronasMaterials__input kronasMaterials__input-name " value={edgeData.name}
					   name='name' onChange={(e) => handleChange(e)}
					   autoComplete='off'
				/>
			</div>
			<div className="kronasMaterials__data edgesKronas__type">
				<select className="kronasMaterials__select" defaultValue={edge.type} name='type'
						onChange={(e) => handleChange(e)}>
					{edgesTypes.map((type) => (<option key={type} value={type}>{type}</option>))}
				</select>
			</div>
		</div>
		<div className="kronasMaterials__btn">
			<button className="btn" style={{marginLeft: "auto"}} onClick={() => deleteEdge(edge)}>
				<i className="btn__icon-delete"></i>
			</button>
		</div>
	</div>
}

export default EdgeItem