import './style.scss';
import Languages from "../../../../translation/Languages";
import {modalsOperations, modalsSelectors} from "redux/modals";
import {useDispatch, useSelector} from "react-redux";
import CloseBtn from "../../Buttons/CloseBtn";
import React, {useEffect, useState} from "react";
import ReplaceMaterialProjectItem from "./ReplaceMaterialProjectItem";
import DefaultBtn from "../../Buttons/DefaultBtn";
import ReplaceMaterialProjectItem2 from "./ReaplceMaterialProjectItem2";
import {projectSelectors} from "redux/project";
import Requests from "../../../../api/API";
import {empty, validationInput} from "../../../../helpers/helper";
import {errorMessageOperations} from '../../../../redux/errors'
import Errors from "../../../../models/Errors";
import WarningModalReplace from "./WarningModalReplace";
import {toastWarning} from "../../../../helpers/toasts";
import Project from "../../../../models/Project";
import {useProjectTableContext} from "../../../../hooks/useContexts";

const _errors = new Errors([])

const ReplaceMaterialProject = () => {
    const construction = useSelector(projectSelectors.getConstruction);
    const isReplacedMaterialForProject = useSelector(modalsSelectors.getIsReplacedMaterialForProject)
    const selectedDetails = useSelector(modalsSelectors.getModalIsEdit);
    const [projectMaterials, setProjectMaterials] = useState([])
    const [warehouseMaterials, setWarehouseMaterials] = useState([])
    const [kronasMaterials, setKronasMaterials] = useState([])
    const [code, setCode] = useState("");
    const [selectMaterial, setSelectMaterial] = useState([construction.materials[0]])
    const [selectMaterial2, setSelectMaterial2] = useState([])
    const dispatch = useDispatch()
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [actualMaterial, setActualMaterial] = useState([]);
    const {rerenderTable} = useProjectTableContext();
    const [disableBtn, setDisableBtn] = useState(false)

	const search = async (code) => {
		const responseType = 'Sheet'
		await setCode(code);
		if (code.length >= 5) {

			const request2 = await Requests.Materials.getMaterialCurrentVendorCode(code, responseType ).catch(error => {
				_errors.setError(error.message, 'error', true)
			});
			if (!empty(request2)) {
				setKronasMaterials([]);
				if (request2[0].responsetype === 'Sheet' || request2[0].responsetype === 'sheet') {
					setKronasMaterials((materials) => [...materials, request2[0]]);
				}
			}else {
				setKronasMaterials([]);
			}
		}
        setDisableBtn(true)
    };


    useEffect(() => {
        if (warehouseMaterials.length) {
            setSelectMaterial2([warehouseMaterials[0]])
        }
    }, [warehouseMaterials.length])


    useEffect(() => {
        setProjectMaterials([...construction.materials])
        setWarehouseMaterials([...construction.materials])
        setSelectMaterial2([warehouseMaterials[0]])
    }, [])


    const selectMaterialHandler = (material) => {
        setSelectMaterial([material])
    }

    const selectMaterialHandler2 = (material) => {
        setSelectMaterial2([material])
    }

    const close = () => {
        dispatch(
            modalsOperations.switchStateForModal({
                show: false,
                type: null,
            })
        );
    };
    const closeWarningModal = () => {
        confirmSubmit(null)
        setIsSuccessModalOpen(false)
    }

	const validateDetails = () => {
		Project.validateProjectDetails().then(() => {}).catch(error => console.log(error))
	}
	const confirmSubmit = (selectedMaterial) => {

		const details = construction.details.filter((detail) => detail.material === selectMaterial[0].index || detail.multiplicity?.material === selectMaterial[0].index)
		const materialChoosen = selectedMaterial ? selectedMaterial : actualMaterial
		if (isReplacedMaterialForProject) {

			if (!empty(materialChoosen)) {

                if (construction.materials.some((material) => material.article === materialChoosen[0].article)) {
                    const neededMaterial = construction.materials.filter((material) => material.article === materialChoosen[0].article)
                    construction
                        .setDetailsMaterial(details, selectMaterial[0].index, neededMaterial[0].index)
                        .then(() => {
                            if (!empty(selectMaterial[0].parts)) {
                                async function deleteReservingMaterialsForKronasElements(materials, construction) {
                                    for (const part of materials.parts) {
                                        if (part.typeParts === 'kronasElement') {
                                            await construction.deleteReservingMaterials(construction.projectData.id, part.id);
                                        }
                                    }
                                }

                                try {
                                    deleteReservingMaterialsForKronasElements(selectMaterial[0], construction);
                                } catch (error) {
                                    toastWarning(Languages.getTranslation('defaultError', true))
                                }
                            }
                            return construction.deleteMaterial(selectMaterial[0].index)
                        })
                        .then(() => {
                            validateDetails()
                            setProjectMaterials([...construction.materials])
                            setWarehouseMaterials([...construction.materials])
                        })
                        .then(() => {
                            setActualMaterial([])
                            setKronasMaterials([])
                        })
                        .then(() => dispatch(errorMessageOperations.switchStateError({
                            message: Languages.getTranslation("replace-material-success", true),
                            type: 'success',
                            show: true
                        })))
                        .catch((e) => _errors.setError(`${Languages.getTranslation("error-replace-material", true)}`, 'error', true))
                } else {
                    construction.setProjectMaterials([...materialChoosen]).then(() => {
                        const neededMaterial2 = construction.materials.filter((material) => material.article === materialChoosen[0].article)
                        construction
                            .setDetailsMaterial(details, selectMaterial[0].index, neededMaterial2[0].index)
                            .then(() => {
                                if (!empty(selectMaterial[0].parts)) {
                                    async function deleteReservingMaterialsForKronasElements(materials, construction) {
                                        for (const part of materials.parts) {
                                            if (part.typeParts === 'kronasElement') {
                                                await construction.deleteReservingMaterials(construction.projectData.id, part.id);
                                            }
                                        }
                                    }

                                    try {
                                        deleteReservingMaterialsForKronasElements(selectMaterial[0], construction);
                                    } catch (error) {
                                        toastWarning(Languages.getTranslation('defaultError', true))
                                    }
                                }
                                return construction.deleteMaterial(selectMaterial[0].index)
                            })
                            .then(() => {
                                setProjectMaterials([...construction.materials])
                                setWarehouseMaterials([...construction.materials])
                            })
                            .then(() => {
                                setActualMaterial([])
                                setKronasMaterials([])
                            })
                            .then(() => dispatch(errorMessageOperations.switchStateError({
                                message: Languages.getTranslation("replace-material-success", true),
                                type: 'success',
                                show: true
                            })))
                            .catch((e) => _errors.setError(`${Languages.getTranslation("error-replace-material", true)}`, 'error', true))
                    })
                }
                rerenderTable();
            }
        } else {
            if (empty(selectMaterial)) {
                _errors.setError(`${Languages.getTranslation("choose-one-material", true)}`, 'error', true)
                return
            }

            if (!empty(materialChoosen)) {
                if (construction.materials.some((material) => material.article === materialChoosen[0].article)) {
                    const neededMaterial = construction.materials.filter((material) => material.article === materialChoosen[0].article)

                    construction
                        .setDetailsMaterial(selectedDetails, selectMaterial[0].index, neededMaterial[0].index)
                        .then(() => construction.replaceDetailsMaterial(selectedDetails, neededMaterial[0].index))
                        .then(() => dispatch(errorMessageOperations.switchStateError({
                            message: Languages.getTranslation("replace-material-success", true),
                            type: 'success',
                            show: true
                        })))
                } else {
                    construction.setProjectMaterials([...materialChoosen]).then(() => {
                        const neededMaterial2 = construction.materials.filter((material) => material.article === materialChoosen[0].article)
                        construction
                            .setDetailsMaterial(selectedDetails, selectMaterial[0].index, neededMaterial2[0].index)
                            .then(() => construction.replaceDetailsMaterial(selectedDetails, neededMaterial2[0].index))
                            .then(() => dispatch(errorMessageOperations.switchStateError({
                                message: Languages.getTranslation("replace-material-success", true),
                                type: 'success',
                                show: true
                            })))

                    })
                }
                validateDetails();
                rerenderTable();
            }
        }
    }

	const submit = (selectedMaterial) => {
		setDisableBtn(true)
		setActualMaterial(selectedMaterial)
		if (isReplacedMaterialForProject){
			if (selectMaterial[0].article === selectedMaterial[0].article) {
				dispatch(errorMessageOperations.switchStateError(
					{ message: Languages.getTranslation("cant-replace-on-same-material", true), type: 'warning', show: true }))
				return
			}
		}


		setTimeout(() => {
			if (selectMaterial[0].thickness !== selectedMaterial[0].thickness) {
				setIsSuccessModalOpen(true)
			}else{
				confirmSubmit(selectedMaterial)
			}
		}, 100)
		setDisableBtn(false)

	}

    const disabledClass = empty(kronasMaterials)
    return <div className={`replaceMaterialProject${!isReplacedMaterialForProject ? ' small' : ''}`}>

        <div className="replaceMaterialProject__header">
            {
                isReplacedMaterialForProject ? (<span
                        className="replaceMaterialProject__title">{Languages.getTranslation('replace-materials-project', true)}</span>
                ) : (<span
                    className="replaceMaterialProject__title"></span>)}
            <CloseBtn handler={close}/>
        </div>
        {
            isReplacedMaterialForProject && (
                <div>
                    <div className="replaceMaterialProject__table-header">

                        <div className="replaceMaterialProject__content-container">
                            <div
                                className="replaceMaterialProject__table-header-name replaceMaterialProject__name">{Languages.getTranslation('name', true)}</div>
                        </div>
                        <div className="replaceMaterialProject__data-container">
                            <div className="replaceMaterialProject__data-item">
								<span
                                    className="replaceMaterialProject__data-header">{Languages.getTranslation('article', true)}</span>
                            </div>
                            <div className="replaceMaterialProject__data-item">
								<span
                                    className="replaceMaterialProject__data-header">{Languages.getTranslation('height', true)}</span>
                            </div>
                            <div className="replaceMaterialProject__data-item">
								<span
                                    className="replaceMaterialProject__data-header">{Languages.getTranslation('width', true)}</span>
                            </div>
                            <div className="replaceMaterialProject__data-item">
								<span
                                    className="replaceMaterialProject__data-header">{Languages.getTranslation('thickness', true)}</span>
                            </div>
                        </div>
                        <div className='replaceMaterialProject__table-header_invincible'></div>
                    </div>

                    <div className="replaceMaterialProject__chooseMaterialProject">
                        <div className="replaceMaterialProject__chooseMaterialProject-list">
                            {projectMaterials.length ? projectMaterials.map((material, idx) =>
                                <ReplaceMaterialProjectItem
                                    key={material.guid}
                                    material={material}
                                    idx={idx}
                                    selectMaterial={selectMaterial}
                                    selectMaterialHandler={selectMaterialHandler}
                                />
                            ) : <div></div>}
                        </div>
                    </div>
                </div>
            )
        }
        <div className="replaceMaterialProject__replaceMaterial-container">
            <div className="replaceMaterialProject__replaceMaterial-header">
                <span>{Languages.getTranslation('choose-materials-for-replace', true)}</span>
            </div>
            <div className="replaceMaterialProject__kronasMaterials">
                <div className="replaceMaterialProject__kronasMaterials-header">
					<span
						className="replaceMaterialProject__title">{Languages.getTranslation('material', true)} Kronas</span>
					<div className="addMaterial__header-searchField">
						<i className="addMaterial__input-icon"></i>
						<input
							className="addMaterial__input"
							type="text"
							placeholder={Languages.getTranslation("add-article-material",true)}
							onInput={(e) => validationInput(e)}
							onKeyUp={(e) => search(e.target.value)}
						/>
					</div>
				</div>
				<div className="replaceMaterialProject__kronasMaterials-materialsList">
					{kronasMaterials.length ? kronasMaterials.map((material, idx) => (
						<ReplaceMaterialProjectItem2 material={material} idx={idx}/>
					)) : <div
						className="replaceMaterialProject__kronasMaterials-noneMaterial">{Languages.getTranslation('dont-have-material', true)}</div>}
				</div>
				<div className="replaceMaterialProject__kronasMaterials-footer">
					<DefaultBtn title={Languages.getTranslation('replace-material', true)}
								handler={() => submit(kronasMaterials)} disabled={!disableBtn}/>
				</div>
			</div>
			<div className="replaceMaterialProject__customerWarehouse">
				<div className="replaceMaterialProject__customerWarehouse-header">
					<span
                        className="replaceMaterialProject__title"> {Languages.getTranslation('materials-from-project', true)}</span>
                </div>
                <div className="replaceMaterialProject__customerWarehouse-materialsList">
                    {warehouseMaterials.length ? warehouseMaterials.map((material, idx) =>
                        <ReplaceMaterialProjectItem
                            key={material.guid}
                            material={material}
                            idx={idx}
                            selectMaterialHandler={selectMaterialHandler2}
                            selectMaterial={selectMaterial2}
                        />
                    ) : <div
                        className="replaceMaterialProject__kronasMaterials-noneMaterial">{Languages.getTranslation('dont-have-material', true)}</div>}
                </div>
            </div>
        </div>

        <div className="replaceMaterialProject__footer">

			<div className="replaceMaterialProject__footer-btns">
				<DefaultBtn title={Languages.getTranslation('replace-material', true)}
							handler={() => submit(selectMaterial2, "materials")} disabled={disableBtn}/>
			</div>
		</div>
		{isSuccessModalOpen &&
			<WarningModalReplace text={Languages.getTranslation('warning-replace-material-message', true)}
								 cancel={() => setIsSuccessModalOpen(false)} save={() => closeWarningModal()}/>}


    </div>

}

export default ReplaceMaterialProject