import Languages from "../../../../../translation/Languages";
import React from "react";
import img1 from "../../../../../media/addMaterial-test.png";
import {empty, materialTitle, imgLink} from "../../../../../helpers/helper";
import RoundCheckBox from "../../../Inputs/Input/RoundCheckBox";
import img from "../../../../../media/material-add-img-test.svg";
import MaterialEdgeImg from "../../../../CreateProject/Materials/MaterialEdgeImg/MaterialEdgeImg";

const ReplaceMaterialProjectItem = ({material, idx, selectMaterialHandler, selectMaterial}) => {

	const activeClass = `replaceMaterialProject__material ${material === selectMaterial[0] ? `replaceMaterialProject__material_active` : ''}`

	return <div
		className={activeClass} key={idx}>
		<div className="replaceMaterialProject__content-container">
			<div className="material__number">{`№${idx + 1}`}</div>
			{/*<img src={img1} alt="image" className="replaceMaterialProject__img"/>*/}
			<MaterialEdgeImg item={material} />

			<h3 className="replaceMaterialProject__name">
				{materialTitle(material)}
			</h3>
		</div>
		<div className="replaceMaterialProject__data-container">
			<div className="replaceMaterialProject__data-item replaceMaterialProject__data">
				{material.article || material.code}
			</div>
			<div className="replaceMaterialProject__data-item replaceMaterialProject__data">
				{material.l || material.height}
			</div>
			<div className="replaceMaterialProject__data-item replaceMaterialProject__data">
				{material.w || material.width}
			</div>
			<div className="replaceMaterialProject__data-item replaceMaterialProject__data">
				{material.thickness}
			</div>
		</div>
		<div className="deleteMaterial__chosen-material">
			<RoundCheckBox value={material} setMaterial={selectMaterialHandler} selectedMaterial={selectMaterial}/>
		</div>
	</div>
}

export default ReplaceMaterialProjectItem