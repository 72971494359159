import {projectOperations} from "../../../redux/project";
import React, {Component} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {connect} from "react-redux";
import {constructor3dOperations } from "../../../redux/constructor";
import './style.scss';
import Scene from './../../model/3d/Scene'
import LeftPanel from "../layuot/LeftPanel";
import EditDetailOptions from "../components/EditDetailOptions";
import RightPanel from "../layuot/RightPanel";
import CProject from "./../../model/components/CProject";
import changeView from "../components/ChangeView";
import ConnectDetailsOptions from "../components/ConnectDetailsOptions";
import TopPanel from "../layuot/TopPanel";

class ConstructorScene extends Component {

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		const scene = new Scene(this.mount);
		this.props.setScene(scene);
		new CProject();
	}

	changeView(axis) {
		window.dispatchEvent( new CustomEvent('changView', {detail: axis}));
	}

	render() {
		return (
			<div className="constructor">
				<TopPanel />
				<LeftPanel />
				<div className="d-flex change-view-panel">
					<button onClick={e => this.changeView('x')}>x</button>
					<button onClick={e => this.changeView('y')}>y</button>
					<button onClick={e => this.changeView('z')}>z</button>
				</div>
				<div
					className={
						"three-scene "
					}
					ref={(mount) => {
						this.mount = mount;
					}}
				/>
				<RightPanel />
				<ConnectDetailsOptions />
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setScene: (scene) => {
			dispatch(
				constructor3dOperations.setScene(scene)
			);
		},
	};
};

const mapState = state => {
	return {
		// panel: panelSelectors.getPanelShowState(state),
	};
};

export default connect(mapState, mapDispatchToProps)(ConstructorScene);
