import React, {useState, useEffect, useRef} from "react";
import Input from "./UI/Input";
import Select from "./UI/Select";
import Checkbox from "./UI/Checkbox";

import "./style.scss";
import {empty} from "../../../helpers/helper";
import Languages from "../../../translation/Languages";
import DefaultBtn from "../Buttons/DefaultBtn";

const Param = ({param, updateParams, treatmentType, treatment, detail}) => {
	const [value, setValue] = useState(param.value);
	const [addedFormula, setAddedFormula] = useState(false)
	const [areaInput, setAreaInput] = useState('')

	const tooltipRef = useRef(null)
	const areaRef = useRef(null)
	const paramTooltipRef = useRef(null)

	const handleClickOut = e => {
		if (tooltipRef.current && !tooltipRef.current.contains(e.target) && addedFormula) {
			setAddedFormula(prevState => !prevState);
		}
	};

	const [error, setError] = useState(false)
	const changeStyles = () => {
		if (
			(treatmentType === "corner" && param.name === "r") ||
			(treatmentType === "circle" && param.name === "r") ||
			(treatmentType === "rabbet" && param.name === "r") ||
			(treatmentType === "hole" && param.name === "direction") ||
			(treatmentType === "hole" && param.name === "directionSide") ||
			(treatmentType === "groove" && param.name === "r") ||
			(treatmentType === "rectangle" && param.name === "r") ||
			(treatmentType === "uShape" && param.name === "r") ||
			(treatmentType === "mill" && param.name === "r" && ( !['radiusEdge', 'uShape'].includes(treatment.type ))) ||
			(['tableTop', 'tableCorner'].includes(treatment.type)) ||
			param.name === "comment" || param.name === 'template'
		) {
			return "100%";
		}
	};

	const handlerAdditionalParams = (el) => {
		el.callback();
		setValue(param.value)
		updateParams();
	}

	const handlerAdditionalCheckbox = () => {
		updateParams();
	}

	useEffect(() => {
		const { additionalParam } = param;
		if(!empty(additionalParam)) {
			additionalParam.map(el => {
				if(el.name === 'formula'){ setAreaInput(el.value) }
			})
		}

		if(addedFormula) {
			areaRef.current.focus()
			areaRef.current.setSelectionRange(areaInput.length, areaInput.length)
			document.addEventListener('click', handleClickOut)
			if (tooltipRef.current && paramTooltipRef.current) {
				const elementRect = tooltipRef.current.getBoundingClientRect();

				const parentRect = tooltipRef.current.parentElement.getBoundingClientRect();
				if(parseInt(parentRect.right) - parseInt(elementRect.right) < 15){
					paramTooltipRef.current.style.right = '24px';
				}
			}
		}

		return () => {
			document.removeEventListener('click', handleClickOut);
		};
	}, [addedFormula, param.additionalParam])

	useEffect(() => {
		setValue(param.value)
	},[handlerAdditionalParams])


	if(!param.visible) {
		return null;
	}



	const addAdditionalParams = () => {
		const { additionalParam } = param;
		if(empty(additionalParam)) {
			return null
		}


		return (
			<>
				{additionalParam.map((el, idx) => {
					switch (el.type) {
						case 'checkbox':
							return (
								<div key={idx} className={`param__additional-checkbox param__additional-${el.place}`}>
									<label className="param__label">
										{el.label}
										<Checkbox
											param={el}
											handleChange={handlerAdditionalCheckbox}
										/>

									</label>
								</div>
							)
						default:
							return (
								<button
									onClick={() => el.name === 'formula' ? setAddedFormula(prev => !prev) : handlerAdditionalParams(el)}
									title={el.label}
									className={`paramIcon__${el.name === "formula" ? el.value === "" ? 'formulaEmpty' : 'formula' : el.name}`}
									key={idx}></button>
							)
					}
				})}
			</>
		)
	}

	const handlerAddFormula = () => {
		param.onChange(areaInput);
		updateParams()
		setAddedFormula(prev => !prev)
	}

	const closeByEnter = (e) => {
		if (e.key === "Enter") {
			handlerAddFormula()
		}
	};

	return (
		<>
			{(() =>{
				if (param.type === "number" || param.type === "text") {
					return (
						<>
							<div className="param" ref={tooltipRef}
								style={{width: changeStyles()}}>
								<label className="param__label">
									{param.label}
								</label>
								<div
									className={`param__default-input ${param?.disabled ? 'bg-error' : ''} ${param.isError ? 'bg-error errorValidationOnInput' : ''}`}
									style={{width: changeStyles()}}
								>
									<Input treatment={treatment} error={error} setError={setError} treatmentType={treatmentType} detail={detail} param={param} value={value} classCustom={param?.disabled ? 'bg-error' : ''} updateParams={updateParams} handlerAdditionalParams={handlerAdditionalParams}/>
									{addAdditionalParams()}
								</div>
								<div
									ref={paramTooltipRef}
									onKeyDown={(e) => closeByEnter(e)}
									style={addedFormula ? {display: 'flex'} : {display: 'none'} }
									className='param_tooltip'>
									<div className='param_tooltip-item-wrapper'>
										<textarea
											ref={areaRef}
											placeholder={Languages.getTranslation('add-formula', true)}
											value={areaInput}
											onChange={event => setAreaInput(event.target.value)}
											className='param_tooltip-item-wrapper__area'
											name="formula" id="formula" cols="30" rows="10"></textarea>
										<div className='param_tooltip-item-wrapper_btns'>
											<DefaultBtn
												title={Languages.getTranslation("cancel2", true)}
												handler={() => setAddedFormula(prev => !prev)}
											/>
											<DefaultBtn
												title={Languages.getTranslation('save', true)}
												handler={handlerAddFormula}/>
										</div>
									</div>
								</div>
							</div>
							{param.name === 'comment' && treatmentType === "hole" && !treatment.isEdit && <div className='groupHoles'>
								{Languages.getTranslation('multiple-addition', true)}</div>}
						</>
					);
				}
				if (param.type === "select") {
					return (
						<div className={`param`} style={{width: param.name === 'subType' ? '100%' : changeStyles()}}>
							<label className="param__label">
								{param.label}
							</label>
							<Select treatmentType={treatmentType} param={param} updateParams={updateParams} treatment={treatment}/>
							{addAdditionalParams()}
						</div>
					);
				}
				if (param.type === "checkbox") {
					return (
						<label className={`directionLabel directionLabel__${param.name}`}>
							<span style={{fontSize: 14}}>{param.label}</span>
							<Checkbox param={param} handleChange={updateParams}/>
						</label>
					)
				}
				if (param.type === "checkbox-orig") {
					return (
						<div className="param" style={{maxHeight: 150, height: '100%', width: '100%'}}>
							{param.value && treatmentType === "rectangle"  && <div className='chosProcesses__item_tooltip-error_body param__error errorValidationOnInput'>
								{Languages.getTranslation('milling-error', true)}
							</div>
							}
							<div className='param__checkbox-orig-container'>
								<label className={`param__label param__label2 `}>
									<span style={{fontSize: 14}}>{param.label}</span>
									<Checkbox param={param} handleChange={updateParams}/>
								</label>
							</div>
							{/*<span className='param__label'>{Languages.getTranslation('milling-error', true)}</span>*/}
						</div>
					)
				}
				if (param.type === "infoText") {
					return (
						<div className='param chosProcesses__item_tooltip-error_body param__error errorValidationOnInput' style={{maxHeight: 150, height: '100%', width: '100%', background: 'transparent'}}>
							<span style={{fontSize: 16}}>{Languages.getTranslation(param.value, true)}</span>
						</div>
					)
				}
			})()}
		</>
	);
};

export default Param;
