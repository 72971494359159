import React from 'react';
import History from "../../components/History";
import './style.scss'
import {useDispatch, useSelector} from "react-redux";
import {constructor3dOperations, constructor3dSelectors} from "../../../../redux/constructor";
import ProjectDetails from "../../components/ProjectDetails";

const RightPanel = () => {
	const opened = useSelector(constructor3dSelectors.rightPanelOpened)
	const dispatch = useDispatch();

	const togglePanel = () => {
		dispatch(constructor3dOperations.switchRightPanel(!opened))
	}

	return (
		<div className={`panel-wrapper panel-wrapper-right ${opened ? 'opened' : ''}`}>
			<div className={'panel-wrapper-header'}>
				{/*<button>t</button>*/}
				{/*<button>s</button>*/}
				<button className={'icon-button-arrow'} onClick={togglePanel}></button>
			</div>
			<div className={'panel-wrapper-body'}>
				<ProjectDetails/>
			</div>
		</div>
	)
}

export default RightPanel;