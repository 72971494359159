import { combineReducers } from 'redux';
import { createReducer } from "@reduxjs/toolkit";
import ACTIONS from "./actions";
import detail from "../../models/Detail";



const detailsReducer = createReducer([], (builder) => {
  builder
    .addCase(ACTIONS.addCDetail, (state, action) => {
      state.push(action.payload);
    })
    .addCase(ACTIONS.removeCDetail, (state, action) => {
      return state.filter(el => el?.detailCId !== action.payload?.detailCId)
    })
    .addCase(ACTIONS.addDetailGroup, (state, action) => {
      return state.map(el => {
        if(el.detailCId === action.payload?.detailCId) {
          el.group = action.payload.group;
        }
        return el
      })
    })
    .addCase(ACTIONS.removeDetailGroup, (state, action) => {
        return state.map(el => {
          if(el.detailCId === action.payload) {
            el.group = null;
          }
          return el
        })

    })
});

const sceneReducer = createReducer({}, builder => {
  builder
    .addCase(ACTIONS.setScene, (state, action) => {
       return state = {...state, sceneClass: action.payload}
    })
})

const editedObjectReducer = createReducer({}, builder => {
  builder
    .addCase(ACTIONS.setEditedObject, (state, action) => {
      return state = {...action.payload};
    })
})

const productsReducer = createReducer([{
  productId: 1,
  name: 'Тумба ТЕСТ'
}], builder => {
  builder
    .addCase(ACTIONS.addProduct, (state, action) => {
      state.push(action.payload);
    })
    .addCase(ACTIONS.removeProduct, (state, action) => {
      return state.filter(el => el?.productId !== action.payload?.productId)
    })
})

const selectedObjectsReducer = createReducer([], builder => {
  builder
    .addCase(ACTIONS.addSelectedObject, (state, action) => {
      state.push(action.payload);
    })
    .addCase(ACTIONS.removeSelectedObject, (state, action) => {
      return state.filter(el => el?.object.uuid !== action.payload)
    });
})

const historyReducer = createReducer([], builder => {
  builder
    .addCase(ACTIONS.addHistory, (state, action) => {
      if(state.length === 20) {
        state.slice(0, 1)
      }
      state.forEach(el => el.isCurrent = false);
      state.push(action.payload);
    })
    .addCase(ACTIONS.historySetCurrent, (state, action) => {
      state.forEach((el, i) => {
        el.isCurrent = i === action.payload;
      });
    })
    .addCase(ACTIONS.setHistory, (state, action) => {
      return state = [...action.payload]
    })
})

const showProcessingReducer = createReducer({hole: false, saw: false, cutter: false}, builder => {
  builder
    .addCase(ACTIONS.setShowedProcessing, (state, action) => {
      return state = {...state, ...action.payload}
    })
})

const connectionReducer = createReducer([], builder => {
  builder
      .addCase(ACTIONS.addConnection, (state, action) => {
        const index = state.findIndex(el => el.connectionId === action.payload.connectionId);
        if(index === -1) {
          state.push(action.payload)
        } else {
          state.splice(index, 1, action.payload)
        }
      })
      .addCase(ACTIONS.removeConnection, (state, action) => {
        return state.filter(el => el.connectionId !== action.payload)
      });
})

const connectOptionsReducer = createReducer(false, builder => {
  builder
    .addCase(ACTIONS.showConnectOptions, (state, action) => {
      return action.payload;
    })
})

const connectValuesReducer = createReducer({
  connectionId: null,
  yOffsetMin: '',
  yOffsetMax: '',
  xOffsetMin: '',
  xOffsetMax: '',
  zOffsetMin: '',
  zOffsetMax: '',
}, builder => {
  builder
    .addCase(ACTIONS.setConnectValues, (state, action) => {
      return state = {...state, ...action.payload}
    })
    .addCase(ACTIONS.dropConnectValues, (state, action) => {
      return {
        connectionId: null,
        yOffsetMin: '',
        yOffsetMax: '',
        xOffsetMin: '',
        xOffsetMax: '',
        zOffsetMin: '',
        zOffsetMax: '',
      };
    })
})

const groupReducer = createReducer([], builder => {
  builder
    .addCase(ACTIONS.addGroup, (state, action) => {
      state.push(action.payload);
    })
    .addCase(ACTIONS.removeGroup, (state, action) => {
      return state.filter(el => {el.group_id !== action.payload})
    })
})

const interfaceReducer = createReducer({
  leftPanelOpened: true,
  rightPanelOpened: true,
  currentCamera: 1, //1- PerspectiveCamera, 2 - OrthographicCamera
  topPanelItems: [
    {
      name: 'showProcessing',
      items: [
        {
          name: 'holes',
          call: 'toggleHoles'
        },
        {
          name: 'saw',
          call: 'toggleSaw'
        }
      ]
    },
    {
      name: 'renderType_2',
      call: 'renderType2'
    },
    {
      name: 'renderType_1',
      call: 'renderType1'
    }
  ],
  renderType: 1,
  connectModalOpened: false
}, builder => {
  builder
    .addCase(ACTIONS.leftPanelOpened, (state, action) => {
      state.leftPanelOpened = action.payload;
    })
    .addCase(ACTIONS.rightPanelOpened, (state, action) => {
      state.rightPanelOpened = action.payload;
    })
    .addCase(ACTIONS.setCurrentCamera, (state, action) => {
      return state.currentCamera = Number(action.payload) || 1;
    })
    .addCase(ACTIONS.addTopPanelItem, (state, action) => {
      return state.topPanelItems.push(action.payload)
    })
    .addCase(ACTIONS.removeTopPanelItem, (state, action) => {
      state.topPanelItems.splice(action.payload, 1)
    })
    .addCase(ACTIONS.toggleConnectModal, (state) => {
      state.connectModalOpened = !state.connectModalOpened;
    })
    .addCase(ACTIONS.getRenderType, (state, action) => {
      return state.renderType
    })
    .addCase(ACTIONS.setRenderType, (state, action) => {
      state.renderType = action.payload
    })

})

export default combineReducers({
  cDetails: detailsReducer,
  scene: sceneReducer,
  editedObject: editedObjectReducer,
  products: productsReducer,
  selectedObjects: selectedObjectsReducer,
  history: historyReducer,
  showProcessing: showProcessingReducer,
  connections: connectionReducer,
  connectOptions: connectOptionsReducer,
  connectValues: connectValuesReducer,
  group: groupReducer,
  interface: interfaceReducer
  // cSceneModel: sceneModelReducer
});
