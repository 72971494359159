const Accordion = ({title, isOpen, onToggle, children}) => (
	<>
		<div className="filter-sidebar_content-title" onClick={onToggle}>
			<div className="filter-sidebar_content-title-text">{title}</div>
			<div className={`filter-sidebar_content-title-angle ${isOpen ? 'filter-sidebar_content-title-angle-open' : ''}`}></div>
		</div>
		<div className={`filter-sidebar_content-materials ${isOpen ? "filter-sidebar_content-materials--opened" : ""}`}><div className="filter-sidebar_content-materials-list">{children}</div></div>
	</>
);

export default Accordion;