import {List} from "react-virtualized";
import {Detail} from "./Detail";
import {forwardRef, useCallback} from "react";
import {useProjectTableContext} from "../../../../../hooks/useContexts";
import {useDispatch, useSelector} from "react-redux";
import {swapElements, updateDetailOrderInDB} from "../../../../../helpers/helper";
import Languages from "../../../../../translation/Languages";
import {projectSelectors} from "redux/project";

const Table = forwardRef(({ selectDetail, selectedDetails, remove, details, setDetails, height, isScrolling, onChildScroll, hasErrors, scrollTop, width, }, ref) => {
    const {tableRef, rerenderTable} = useProjectTableContext();
    const dispatch = useDispatch();
		const construction = useSelector(projectSelectors.getConstruction);

    const handleDragStart = useCallback((e, detail) => {
        e.dataTransfer.setData('order', detail?.order);
        e.dataTransfer.setData('dragId', detail.id);
    }, []);

    const handleDrop = useCallback(async (e, targetOrderId) => {
        e.preventDefault();
        const draggingOrderId = e.dataTransfer.getData('order');


        const draggingItem = details.find(detail => detail.order === Number(draggingOrderId));
        const targetItem = details.find(detail => detail.order === targetOrderId);

        const draggingIndex = details.indexOf(draggingItem);
        const targetIndex = details.indexOf(targetItem);


        if (draggingIndex !== -1 && targetIndex !== -1 && draggingIndex !== targetIndex) {
            let updatedDetails = details;

            swapElements(updatedDetails, draggingIndex, targetIndex);

            const promisesForUpdateDetails = [];

            const maxIndex = Math.max(draggingIndex, targetIndex);

            for (let i = 0; i <= maxIndex; i++) {
                const newOrder = i + 1;

                updatedDetails[i].order = newOrder;

                promisesForUpdateDetails.push(updateDetailOrderInDB(updatedDetails[i], newOrder));
            }

            await Promise.all(promisesForUpdateDetails);

            setDetails(updatedDetails);
            rerenderTable();
        }
    }, [dispatch, details, rerenderTable]);

    const dragOverHandler = useCallback((e) => {
        e.preventDefault()
    }, []);

    const rowRenderer = ({index, style}) => {
			if (details.length === 0) {
				return (
					<div key="empty-project" style={style} className="details__empty-project">{Languages.getTranslation("empty-project", true)}</div>
				);
			}
			
			const detail = details[index];

        return (
            <Detail
                key={detail.id}
                style={style}
                selectDetailHandler={selectDetail}
                detail={detail}
                index={index}
                hasErrors={hasErrors}
                remove={remove}
                isSelected={selectedDetails.includes(detail)}
                dragEndHandler={dragOverHandler}
                dragOverHandler={dragOverHandler}
                handleDragStart={handleDragStart}
                handleDrop={handleDrop}
								construction={construction}
            />
        )
    }

    return (
        <div ref={ref} className="table-wrapper">
            <List
                ref={tableRef}
                autoHeight
                className="default-details-table"
                height={height}
                isScrolling={isScrolling}
                onScroll={onChildScroll}
                overscanRowCount={3}
                rowCount={details.length === 0 ? 1 : details.length}
                rowHeight={70}
                rowRenderer={rowRenderer}
                scrollTop={scrollTop}
                width={width}
            />
        </div>
    )
});

export default Table;