import {Group} from "three";
import Helpers from "./Helpers";
import {empty, isset} from "../../helpers/helper";

export default class ContourEdges {
	_w;
	_h;
	_l;
	material3D;
	mesh;
	side;
	shapes;
	holes;
	_contour;
	_baseContour;
	_start = 0;
	_startTop;
	_startRight;
	_startBottom;
	_edgesArray = [];
	bevels = []
	multiplicity;

	constructor({ w, h, l, contour = [], shapes, side, holes = [], edges = [], multiplicityClass, bevels = []}) {
		this.w = w;
		this.h = h;
		this.l = l;
		this.holes = holes;
		this.side = side;
		this.shapes = shapes;
		this.multiplicity = multiplicityClass || null;
		const leftY = [];
		const rightY = [];
		this._baseContour = contour.contour;
		this._edgesArray = edges;
		this.bevel = bevels;
		this.contour = [...this._baseContour];
		this.contour.forEach(el => {
			if (!el) {
				return
			}
			if(el.x1 === 0) {
				leftY.push(el.y1);
			}
			if(el.x1 >= this.l - 1) {
				rightY.push(el.y1)
			}
		})
		leftY.sort((a, b) => a - b)
		rightY.sort((a, b) => a - b)

		this._startTop = this.contour.findIndex(el => el?.x1 === 0 && el?.y1 === leftY[leftY.length - 1])
		this._startRight = this.contour.findIndex(el => el?.x1 >= this.l - 1 && el?.y1 === rightY[rightY.length - 1])
		this._startBottom = this.contour.findIndex(el => el?.x1 >= this.l - 1 && el?.y1 === rightY[0])

		this.mesh = this.build()
	}

	set w(w) {
		this._w = w;
	}

	get w() {
		return this._w;
	}

	set h(h) {
		this._h = h;
	}

	get h() {
		return this._h;
	}

	set l(l) {
		this._l = l;
	}

	get l() {
		return this._l;
	}

	get contour() {
		return this._contour;
	}

	set contour(contour) {
		this._contour = [...contour];
	}
	getContourPositionX(x) {
		return x - this.l / 2
	}

	getContourPositionY(y) {
		return y - this.h / 2
	}

	getSideContour(side) {
		switch (side) {
			case 'left':
				return this.contour.slice(this._start, this._startTop);
			case 'top':
				return this.contour.slice(this._startTop, this._startRight);
			case 'right':
				return this.contour.slice(this._startRight, this._startBottom);
			case 'bottom':
				return this.contour.slice(this._startBottom);
		}
	}

	addLine({x1, y1, x2, y2, holes}, color = null, side) {
		const mesh = Helpers.getLineMesh(
			{ x1, y1, x2, y2, holes, w: this.w},
			color, side, true
		)
		mesh.position.x = this.l - (this.l - x1);
		mesh.position.y = this.h - (this.h - y1);
		mesh.rotation.z = Math.atan2(y2-y1, x2-x1) - Math.PI / 2
		mesh.position.z = this.w - this.w / 2;
		return mesh
	}

	addArc({x1, y1, x2, y2, xc, yc, r, dir}, color = null, side) {
		const mesh = Helpers.getArcMesh(
			{x1, y1, x2, y2, xc, yc, r, dir, w: this.w},
			color,
			side,
			true
		)

		mesh.position.z = - this.w / 2;
		return mesh;
	}

	build() {
		const side = this.side;
		const points = this.getSideContour(side);
		const _bevels = [];
		if(!empty(this.bevel)) {
			let addSide = true;
			this.bevel.forEach(bevel => {
				if(bevel.edgeSide === this.side && bevel.z === 0) {
					addSide = false;
				} else {
					const _holes = bevel.getEdgeHoles(this.side);
					if(!empty(_holes)) {
						_bevels.push(_holes);
					}

				}
			})
			if(!addSide) return null;
		}

		const mesh = new Group();
		points.forEach(contour => {
			if (!contour ) {
				return
			}
			let edge;
			switch (contour.type) {
				case 'line':
					if(!empty(this.holes)) {
						this.holes.forEach((hole) => {
							if (contour.holes.findIndex(el => el.id === hole.id) === -1) {
								contour.holes.push({
									id: hole.id,
									type: 'circle',
									side: hole.side,
									x: hole.x_axis === 'left' ? hole.x : this.l - hole.x,
									y: hole.y_axis === 'bottom' ? hole.y : this.h - hole.y,
									z: !empty(hole.z) ? this.w - hole.z : this.w / 2,
									r: hole.diam / 2
								})
							}
						})
					}

					if(!empty(_bevels)) {
						_bevels.forEach(bevel => {
							if (contour.holes.findIndex(el => el.id === bevel.id) === -1) {
								contour.holes.push({
									...bevel,
									type: 'bevel'
								})
							}
						})
					}

					if(!empty(this.shapes)) {
						this.shapes.forEach(shape => {
							const cutOut = shape.cutOut;
							contour.holes.push({
								...cutOut,
								type: 'contour',
								z: shape.z || this.w / 2
							})
						})
					}
					const holes = this.multiplicity.edgeShapes;
					if(!empty(holes) && !empty(holes[side])) {
						contour.holes.push({
							depth : this.multiplicity.w,
							side: side,
							ext: false,
							intersections: holes[side],
						})
					}
					if(!empty(contour.edge)) {
						edge = this._edgesArray.find(el => el.index === contour.edge)
						mesh.add(this.addLine(contour, contour.color ?? Helpers.getColorByThickEdge(edge?.thickness), side))
					} else {
						mesh.add(this.addLine(contour, contour.color ?? null, side))
					}
					break
				case 'arc':
					if(!empty(contour.edge)) {
						edge = this._edgesArray.find(el => el.index === contour.edge)
						mesh.add(this.addArc(contour, contour.color ?? Helpers.getColorByThickEdge(edge?.thickness), side))
					} else {
						mesh.add(this.addArc(contour, contour.color, side))
					}
					break
			}
		})
		return mesh;
	}
}