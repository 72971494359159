import Base from "./Base";
import {Vector3, Box3, Quaternion, Euler, Matrix3} from "three";
import {empty, isset} from "../../../helpers/helper";
import {v4 as uuidv4} from "uuid";

export default class ConnectDetails extends Base {
	_xOffsetMax = null;
	_xOffsetMin = null;
	_yOffsetMax = null;
	_yOffsetMin = null;
	_zOffsetMax = null;
	_zOffsetMin = null;

	_mainObject;
	_targetObject;




	constructor() {
    super();
  }


	get mainObject() {
		return this._mainObject;
	}

	set mainObject(object) {
		this._mainObject = object;
	}

	connect(axis, dir = true, offset=0, offsetFrom = 'left') {
		const mainObjectIndex = this.getMainObjectIndex();
		const mainObject = this.objects[mainObjectIndex].object;
		const object2 = this.objects.find((el, i) => i !== mainObjectIndex).object;

		let globalPosition1 = this.getMeshGlobalPosition(mainObject);
		let globalPosition2 = this.getMeshGlobalPosition(object2);
		const size1 = this.getMeshSize(mainObject);
		const size2 = this.getMeshSize(object2);
		let trueOffset = 0;
		switch (axis) {
			case 'x':
				trueOffset = offsetFrom === 'bottom' ? offset + size2.y/2 : size1.y - offset - size2.y / 2
				if(dir) {
					return new Vector3(
						globalPosition1.x + size1.x/2 + size2.x/2,
						globalPosition1.y - size1.y/2 + trueOffset,
						globalPosition2.z
					)
				} else {
					return new Vector3(
						globalPosition1.x - size1.x/2 - size2.x/2,
						globalPosition1.y - size1.y/2 + trueOffset,
						globalPosition2.z
					)
				}
			case 'y':
				trueOffset = offsetFrom === 'left' ? offset + size2.x/2 : size1.x - offset - size2.x / 2
				if(dir) {
					return new Vector3(
						globalPosition1.x - size1.x/2 + trueOffset,
						globalPosition1.y - size1.y/2 - size2.y/2,
						globalPosition2.z
					)

				} else {
					return new Vector3(
						globalPosition1.x - size1.x/2 + trueOffset,
						globalPosition1.y + size1.y/2 + size2.y/2,
						globalPosition2.z
					)
				}
			case 'z':
				if(dir) {
					return new Vector3(
            globalPosition2.x,
            globalPosition2.y,
            globalPosition1.z + size1.z/2 + size2.z/2
          )
				} else {
					return ''
				}
		}
	}

	getAlignForObjects(align, axis, dir) {

	}

	getMainObjectIndex() {
		return this.objects.findIndex(obj => this.getDetailMeshFromChild(obj.object).userData.isMain)
	}

	align(axis, dir = true) {
		let mainObjectIndex = this.getMainObjectIndex();
		if(mainObjectIndex === -1) mainObjectIndex = 0;
		const mainPosition = this.getMeshGlobalPosition(this.objects[mainObjectIndex].object)
		const mainSize = this.getMeshSize(this.objects[mainObjectIndex].object)
		return this.objects.map((obj, i) => {
			if(i === mainObjectIndex) {
				return mainPosition
			} else {
				switch (axis) {
					case 'x':
						if(dir) {
							return new Vector3(
								mainPosition.x + mainSize.x/2 - this.getMeshSize(obj.object).x,
								obj.object.position.y,
								obj.object.position.z
							)
						} else {
							return new Vector3(
								mainPosition.x - mainSize.x/2 + this.getMeshSize(obj.object).x / 2,
								obj.object.position.y,
								obj.object.position.z
							)
						}
					case 'y':
						if(dir) {
							return new Vector3(
								obj.object.position.x,
                mainPosition.y + mainSize.y/2 - this.getMeshSize(obj.object).y / 2,
                obj.object.position.z
              )
						} else {
							return new Vector3(
								obj.object.position.x,
                mainPosition.y - mainSize.y/2 + this.getMeshSize(obj.object).y / 2,
                obj.object.position.z
              )
						}
				}
			}
		})

	}
	//
	detectObjectSide(mainFacePos, detailBox, detailPos, detailRotation) {
		if(Math.round(mainFacePos.x) === Math.round(detailPos.x)) {
			if(Math.round(mainFacePos.y) === Math.round(detailPos.y)) {
				if(Math.round(mainFacePos.z) === Math.round(detailPos.z)) {
					return 'back';
				} else if(Math.round(mainFacePos.z) === Math.round(detailBox.max.z)) {
					return 'front';
				}
			}
			return (Math.round(mainFacePos.y) === Math.round(detailPos.y)) ? 'left' : 'top';
		} else {
			return (Math.round(mainFacePos.x) === Math.round(detailBox.max.x) && Math.round(mainFacePos.y) === Math.round(detailBox.max.y)) ? 'right' : 'bottom'
		}
	}




	/**
	 * Moves the connected details of a given detail.
	 *
	 * @param {Object} detail - The detail object to move its connected details.
	 * @param {Object} mainDetail - The main detail object.
	 * @param {Object} posDiff - The position difference to be added to each connected detail.
	 */
	moveConnectedDetails(detail, mainDetail, posDiff) {
		const allConnectedDetails = this.getConnectedDetails(detail);
		const connectedDetails = allConnectedDetails.filter(el => el.detailCId !== mainDetail.detailCId)
		if(empty(connectedDetails)) return;
		connectedDetails.forEach(el => {
			el.mesh.position.add(posDiff);
			el.updatePositionFromMesh();
			this.moveConnectedDetails(el, detail, posDiff);
		})
	}

	/**
	 * Connects objects based on the specified type.
	 *
	 * @param {string} type - The type of connection. Valid values are 'coincident' and 'parallel'.
	 *
	 * @param mainObject
	 * @param targetObject
	 * @param offset
	 * @param connectionId
	 * @return {void}
	 */
	connectObjects({type, mainObject, targetObject, offset,  connectionId=null}){
		if(empty(mainObject) || empty(targetObject)) return;
		for(const [prop, value] of Object.entries(offset)) {
			this[prop] = value;
		}

		this.mainObject = mainObject;
		const mainDetail = this.getDetailMeshFromChild(mainObject)
		const mainDetailClass = this.getDetailFromMesh(mainDetail)
		const targetDetail = this.getDetailMeshFromChild(targetObject);
		const targetDetailClass = this.getDetailFromMesh(targetDetail);
		this.connectDetails({
			connectionId,
			type,
			mainDetailClass,
			mainObject,
			targetDetailClass,
			targetObject
		})
	}

	/**
	 * Retrieves connection and related details based on the given connectionId.
	 * If connection is not provided, it will be looked up in the internal connections state.
	 *
	 * @param {string} connectionId - The identifier of the connection to retrieve.
	 * @param {object} connection - The optional connection object. If not provided, will be fetched based on connectionId.
	 *
	 * @return {object} An object containing connection, mainDetailClass, targetDetailClass, mainObject, and targetObject.
	 */
	getData(connectionId, connection = null) {
		if(empty(connection)) {
			connection = this.getState('connections').find(el => el.connectionId === connectionId);
		}
		const mainDetailClass = this.getDetailById(connection.mainDetail)
		const targetDetailClass = this.getDetailById(connection.targetDetail)
		const mainObject = connection.mainObjectId ? this.getDetailProcessing(mainDetailClass, connection.mainObjectId) : this.getDetailMeshBySide(mainDetailClass, connection.mainObject);
		const targetObject = connection.targetObjectId ? this.getDetailProcessing(targetDetailClass, connection.targetObjectId) : this.getDetailMeshBySide(targetDetailClass, connection.targetObject);
		return {connection, mainDetailClass, mainObject, targetDetailClass, targetObject}
	}

	/**
	 * Connects details between main and target objects based on provided parameters.
	 *
	 * @param {string} options.type - The type of connection. Default is 'coincident'.
	 * @param {Object} options.mainDetailClass - The main detail class object.
	 * @param {Object} options.mainObject - The main object where the connection is made.
	 * @param {Object} options.targetDetailClass - The target detail class object.
	 * @param {Object} options.targetObject - The target object where the connection is made.
	 * @param {Object} [options.offset=null] - The offset values for the connection. Default is null.
	 * @param {string} [options.connectionId=null] - The connection ID. Default is null.
	 *
	 * @return {Promise} A Promise that resolves once the connection is established and objects are moved accordingly.
	 */
	connectDetails({type = 'coincident', mainDetailClass, mainObject, targetDetailClass, targetObject, offset = null, connectionId = null}) {
		if(!empty(offset)) {
			for(const off in offset) {
				this[off] = offset[off];
			}
		}
		const productId = mainDetailClass.productId === targetDetailClass.productId ? mainDetailClass.productId : null;
		this.storeConnections({
			connectionId,
			type,
			mainDetail: mainDetailClass.detailCId,
			mainObject: mainObject.userData.meshSide,
			mainObjectId: mainObject.userData.pId ?? null,
			targetDetail: targetDetailClass.detailCId,
			targetObject: targetObject.userData.meshSide,
			targetObjectId: targetObject.userData.pId ?? null,
			productId
		})
			.then(() => {
				this.connectAndMove({
					type, mainDetailClass, mainObject, targetDetailClass, targetObject
				})
				return Promise.resolve()
			})
	}

	/**
	 * Connects and moves the target detail based on the type specified.
	 *
	 * @param {Object} options - The options object.
	 * @param {string} options.type - The type of connection ('coincident' or 'parallel').
	 * @param {Object} options.mainDetailClass - The main detail class.
	 * @param {Object} options.mainObject - The main object.
	 * @param {Object} options.targetDetailClass - The target detail class.
	 * @param {Object} options.targetObject - The target object.
	 * @param {boolean} [options.moveConnected=true] - Whether to move the connected details.
	 *
	 * @return {void}
	 */
	connectAndMove({type = 'coincident', mainDetailClass, mainObject, targetDetailClass, targetObject, moveConnected=true}) {
		const mainDetail = mainDetailClass.mesh;
		const targetDetail = targetDetailClass.mesh;
		mainObject.updateMatrixWorld(true);
		targetObject.updateMatrixWorld(true);

		const diff = this.calculateDiff(mainObject, targetObject, mainDetailClass.mesh, targetDetailClass.mesh);
		switch (type) {
			case 'coincident':
				targetDetail.position.add(diff.posDiff);
				break;
			case 'parallel':
				targetDetail.quaternion.multiply(diff.rotDiff);
				break;
		}
		if(moveConnected) {
			this.moveConnectedDetails(targetDetailClass, mainDetailClass, diff.posDiff)
		}


		targetDetailClass.updatePosition({
			type,
			toDetail: mainDetail.userData.detailName,
			toObject: mainObject.userData.name,
			usedObject: targetObject.userData.name
		});

	}

	/**
	 * Updates an existing connection or creates a new connection based on the provided parameters.
	 *
	 * @param {Object} connection - The connection object to update or create.
	 * @param {boolean} [update=false] - A flag indicating whether to update an existing connection. Default is false.
	 *
	 * @return {Object} - The updated or newly created connection object.
	 */
	updateOrCreateConnection(connection, update = false) {
		if(update) {
			return this.db.updateConnection(connection)
		} else {
			return this.db.addConnection(connection)
		}
	}

	/**
	 * Update or create a new connection in the system.
	 *
	 * @param {object} options - The options for the new connection.
	 * @param {string} options.type - The type of the connection.
	 * @param {string} options.mainDetail - The main detail of the connection.
	 * @param {object} options.mainObject - The main object of the connection.
	 * @param {string} options.targetDetail - The target detail of the connection.
	 * @param {object} options.targetObject - The target object of the connection.
	 * @param {string=} options.connectionId - Optional connection ID to update existing connection.
	 *
	 * @return {Promise} A promise that resolves after the connection is stored and state is updated.
	 */
	storeConnections({type, mainDetail, mainObject, mainObjectId = null, targetDetail, targetObject, targetObjectId=null, connectionId = null, productId = null}) {
		const {xOffsetMax, xOffsetMin,yOffsetMax, yOffsetMin, zOffsetMax, zOffsetMin} = this;

		const connectionData = {
			connectionId: connectionId || uuidv4(),
			type,
			mainDetail,
			targetDetail,
			targetObject,
			targetObjectId,
			mainObject,
			mainObjectId,
			offset: {xOffsetMax, xOffsetMin,yOffsetMax, yOffsetMin, zOffsetMax, zOffsetMin},
			productId
		}

		return this.updateOrCreateConnection(connectionData, !empty(connectionId))
			.then(() => {
				this.updateState({
					type: 'ADD_CONNECTION',
					payload: connectionData
				})
				return Promise.resolve()
			})
	}

	removeDetailConnections(detailCId) {
		const connections = this.getConnections().filter(connection => connection.mainDetail === detailCId || connection.targetDetail === detailCId)
		return Promise.all(connections.map(connection => this.removeConnection(connection.connectionId)))
	}

	removeConnection(connectionId) {
		return this.db.removeConnection(connectionId)
			.then(() => {
				this.updateState({
					type: 'REMOVE_CONNECTION',
					payload: connectionId
				})
				return Promise.resolve()
			})
	}

	/**
	 * Restores the connection properties and configures a new connection with specified offset.
	 *
	 * @param {object} connection - The connection object containing connectionId and offset.
	 *
	 * @return {Promise}
	 */
	restoreConnection(connection) {
		return new Promise(resolve => {
			for (const off in connection.offset) {
				this[off] = connection.offset[off];
			}
			setTimeout(() => {
				this.connectAndMove({...this.getData(connection.connectionId, connection), moveConnected: true})
				resolve();
			}, 10)

		})

	}


	/**
	 * Restores the connected details by restoring each connection one by one.
	 *
	 * @returns {Promise} A Promise that resolves when all connections have been restored.
	 */
	restoreConnectedDetails() {
		return new Promise(resolve => {
			const connections = [...this.getConnections()];

			const restore = (i) => {
				const connection = connections[i];
				if(!empty(connection)) {
					this.restoreConnection(connection)
						.then(() => restore(i + 1))
					// restore();
					// setTimeout(() => {

					// }, 50);
				} else {
					resolve()
				}
			}

			restore(0);
		})

	}

	detectProcessingSide(processing, detail) {
		const processingBox = new Box3().setFromObject(processing);
		const detailBox = new Box3().setFromObject(detail);
		const ret = {};
		for (const [type, values] of Object.entries(processingBox)) {
			for(const [axis, value] of Object.entries(values)) {
				if(detailBox[type][axis].toFixed(3) === value.toFixed(3)) {
					ret[axis] = type
				}
			}
		}
		return ret;
	}

	/**
	 * Calculates the position and rotation difference between two objects.
	 *
	 * @param {Object3D} obj1 The first object.
	 * @param {Object3D} obj2 The second object.
	 * @param {boolean} detail1 Additional detail for obj1.
	 * @param {boolean} detail2 Additional detail for obj2.
	 * @return {Object} An object containing the position difference (posDiff) and rotation difference (rotDiff).
	 */
	calculateDiff(obj1, obj2, detail1, detail2) {
		const center1 = new Vector3();
		const boundingBox1 = new Box3().setFromObject(obj1);
		const isProcessing1 = ['hole'].includes(obj1.userData?.meshType)

		const center2 = new Vector3();
		const boundingBox2 = new Box3().setFromObject(obj2);
		const isProcessing2 = ['hole'].includes(obj2.userData?.meshType)
		boundingBox2.getCenter(center2);
		boundingBox1.getCenter(center1);

		let processingOffset1, processingOffset2;

		if(isProcessing1) {
			processingOffset1 = this.detectProcessingSide(obj1, detail1);
		}
		if(isProcessing2) {
			processingOffset2 = this.detectProcessingSide(obj2, detail2);
			for(const [axis, key] of Object.entries(processingOffset2)) {
				center2[axis] = boundingBox2[key][axis];
			}
		}
		console.log(processingOffset1)
		if(!empty(this.yOffsetMax)) {
			switch (obj1.userData?.meshType) {
				case 'hole':
					break;
				default:
					center1.y = boundingBox1.max.y - this.yOffsetMax - (boundingBox2.max.y - boundingBox2.min.y) / 2;
					break;
			}
		} else if(!empty(this.yOffsetMin)) {
			switch (obj1.userData?.meshType) {
				case 'hole':
					break;
				default:
					center1.y = boundingBox1.min.y + this.yOffsetMin + (boundingBox2.max.y - boundingBox2.min.y) / 2;
					break;
			}
		} else {
			switch (obj1.userData?.meshType) {
				case 'hole':
					if(!empty(processingOffset1?.y)) {
						center1.y = processingOffset1?.y === 'max' ? boundingBox1.max.y : boundingBox1.min.y
					}
					break;
				default:

					break;
			}
		}

		if(!empty(this.xOffsetMax)) {
			switch (obj1.userData?.meshType) {
				case 'hole':
					break;
				default:
					center1.x = boundingBox1.max.x - this.xOffsetMax - (boundingBox2.max.x - boundingBox2.min.x) / 2;
					break;
			}

		} else if(!empty(this.xOffsetMin)) {
			switch (obj1.userData?.meshType) {
				case 'hole':

					break;
				default:
					center1.x = boundingBox1.min.x + this.xOffsetMin + (boundingBox2.max.x - boundingBox2.min.x) / 2;
					break;
			}

		} else {
			switch (obj1.userData?.meshType) {
				case 'hole':
					if(!empty(processingOffset1?.x)) {
						center1.x = processingOffset1?.x === 'max' ? boundingBox1.max.x : boundingBox1.min.x
					}
					break;

				default:

					break;
			}
		}

		if(!empty(this.zOffsetMax)) {
			switch (obj1.userData?.meshType) {
				case 'hole':
					break;
				default:
					center1.z = boundingBox1.max.z - this.zOffsetMax - (boundingBox2.max.z - boundingBox2.min.z) / 2;
					break;
			}

		} else if(!empty(this.zOffsetMin)) {
			switch (obj1.userData?.meshType) {
				case 'hole':
					break;
				default:
					center1.z = boundingBox1.min.z + this.zOffsetMin + (boundingBox2.max.z - boundingBox2.min.z) / 2;
					break;
			}
		} else {
			switch (obj1.userData?.meshType) {
				case 'hole':
					if(!empty(processingOffset1?.z)) {
						center1.z = processingOffset1?.z === 'max' ? boundingBox1.max.z : boundingBox1.min.z
					}
					break;
				default:

					break;
			}
		}

		let posDiff = new Vector3().subVectors(center1, center2);
		let rotDiff = new Quaternion();
		let normal1, normal2;
		if(obj1.isMesh) {
			const normalMatrix1 = new Matrix3().getNormalMatrix( obj1.matrixWorld );
			const localNormal1 = new Vector3( 0, 0, 1 );
			normal1 = localNormal1.applyNormalMatrix( normalMatrix1 );
		} else {
			normal1 = new Vector3();
			const positions1 = obj1.geometry.attributes.position;
			const start1 = new Vector3(positions1.getX(0), positions1.getY(0), positions1.getZ(0));
			const end1 = new Vector3(positions1.getX(1), positions1.getY(1), positions1.getZ(1));
			// obj1.geometry.computeLineDistances();
			normal1.subVectors(start1, end1);
			normal1.normalize()
		}

		if(obj2.isMesh) {
			const normalMatrix2 = new Matrix3().getNormalMatrix( obj2.matrixWorld );
			const localNormal2 = new Vector3( 0, 0, 1 );
			normal2 = localNormal2.applyNormalMatrix( normalMatrix2 );
		} else {
			normal2 = new Vector3();
			const positions2 = obj2.geometry.attributes.position;
			// obj2.geometry.computeLineDistances();
			const start2 = new Vector3(positions2.getX(0), positions2.getY(0), positions2.getZ(0));
			const end2 = new Vector3(positions2.getX(1), positions2.getY(1), positions2.getZ(1));
			normal2.subVectors(end2, start2);
			normal2.normalize()
		}
		rotDiff = new Quaternion().setFromUnitVectors(normal1, normal2);

		// if(!empty(obj1.getWorldQuaternion(obj1WorldQuat)) && !empty(obj2.getWorldQuaternion(obj2WorldQuat))) {
		// 	rotDiff = obj1WorldQuat.clone().multiply(obj2WorldQuat.clone().invert());
		// }

		return { posDiff, rotDiff };
	}

	get xOffsetMax() {
		return this._xOffsetMax
	};
	get xOffsetMin() {
		return this._xOffsetMin
	};
	get yOffsetMax() {
		return this._yOffsetMax
	};
	get yOffsetMin() {
		return this._yOffsetMin
	};
	get zOffsetMax() {
		return this._zOffsetMax
	};
	get zOffsetMin() {
		return this._zOffsetMin
	};

	set xOffsetMax(val) {
		const _val = Number(val);
			this._xOffsetMax = isNaN(_val) || empty(val) ? null : _val;
	};
	set xOffsetMin(val) {
		const _val = Number(val);
			this._xOffsetMin = isNaN(_val) || empty(val) ? null : _val;
	};
	set yOffsetMax(val) {
		const _val = Number(val);
			this._yOffsetMax = isNaN(_val) || empty(val) ? null : _val;
	};
	set yOffsetMin(val) {
		const _val = Number(val);
			this._yOffsetMin = isNaN(_val) || empty(val) ? null : _val;
	};
	set zOffsetMax(val) {
		const _val = Number(val);
		this._zOffsetMax = isNaN(_val) || empty(val) ? null : _val;
	};
	set zOffsetMin(val) {
		const _val = Number(val);
		this._zOffsetMin = isNaN(_val) || empty(val) ? null : _val;
	};
}