import DefaultBtn from "../../Buttons/DefaultBtn";
import Languages from "../../../../translation/Languages";

const Actions = ({resetFilters, filterDetails}) => {
	return <div className="filter-sidebar-actions">
		<DefaultBtn
			title={Languages.getTranslation("cancel-filters", true)}
			handler={resetFilters}
		/>
		<DefaultBtn
			title={Languages.getTranslation("to-apply", true)}
			handler={filterDetails}
		/>
	</div>
}

export default Actions;