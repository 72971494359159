import './style.scss';
import Languages from "../../../../translation/Languages";

const Table = ({ data, name }) => {
    return (
        <table className="details-table">
            <thead>
            <tr className="details-table-row-header">
                <th className="details-table-header">№</th>
                <th className="details-table-header">{Languages.getTranslation('article', true)}</th>
                <th className="details-table-header">{name}</th>
                <th className="details-table-header">{Languages.getTranslation('amount', true)}</th>
                <th className="details-table-header">{Languages.getTranslation('price', true)}</th>
                <th className="details-table-header">{Languages.getTranslation('sale', true)}</th>
                <th className="details-table-header">{Languages.getTranslation('general', true)}</th>
            </tr>
            </thead>
            <tbody>
            {data.map((item, i) => {
                return (
                <tr key={i} className="details-table-row-item">
                    <td className="details-table-item details-table-item-first">{i + 1}</td>
                    <td className="details-table-item">{item?.article}</td>
                    <td className="details-table-item" style={{ textAlign: 'left', paddingLeft: '10px'}}>
                        {item?.name?.ua}
                    </td>
                    <td className="details-table-item">{item?.qty}</td>
                    <td className="details-table-item">{(item?.price).toFixed(2)}</td>
                    <td className="details-table-item">{item?.discount}</td>
                    <td className="details-table-item">{item?.sum}</td>
                </tr>
            )})}
            </tbody>
        </table>
    );
};
export default Table