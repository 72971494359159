import API from "config/api";
import store from 'redux/store'
import {userSelectors} from "redux/user";
import {empty} from "../helpers/helper";
import Project from "../models/Project";


export default class Detail {
  userDepartment;
  constructor(axios, token, headers) {
    this.axios = axios;
    this.token = token;
    this.headers = headers;
    // this.userDepartment = $store

  }



  // async validate(detail, detailArr = null) {
  //   const data = {};
  //   data.lang = "ua";
  //   data.department = this.getCookie('department') || '54096';
  //   data.details = [detail.dataForValidate];
  //
  //   return await this.axios
  //     .post(API.ROUTE.validate(), data)
  //     .then((response) => {
  //       detail.error.dropErrors()
  //       if(!empty(detailArr)) {
  //         detailArr.error.dropErrors()
  //       }
  //       return response.data;
  //     })
  //     .catch((error) => {
  //       if (
  //         error.response &&
  //         error.response.data &&
  //         error.response.data.error &&
  //         error.response.data.error.message
  //       ) {
  //         detail.setError(error);
  //         return {error: error.response.data.error};
  //       }
  //     });
  // }

  async validate(detail = {}, detailArr = null) {
    let data = {};
    const { details } = store.getState().project.project.construction
    await Project.getDataJSON(false)
        .then(structure => {
          if(!empty(detail)){
            data = {...structure, details: [detail.getDetailForProjectValidate(structure.edges, structure.materials)]}
          }else{
            const _details = []
            details.forEach(item => {
              _details.push(item.getDetailForProjectValidate(structure.edges, structure.materials))
            })
            data = {...structure, details: _details}
          }
        })
        .catch(error => console.log(error))
    data.lang = "ua";
    data.department = Number(this.getCookie('department')) || 54096;
    // data.details = [detail.dataForValidate];
    //   data.department = Number(data.department)

    //   detail.error.dropErrors()
    //   if(!empty(detailArr)) {
    //     detailArr.error.dropErrors()
    //   }
      return await this.axios
        .post(API.ROUTE.validate(), JSON.stringify(data), {headers: {'content-type': 'application/json'}})
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          if (
              error.response &&
              error.response.data &&
              error.response.data.error &&
              error.response.data.error.message
          ) {
            // detail.setError(error);
            return {error: error.response.data.error};
          }
        });
  }

  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift().replace('%7C', '|');
    } else {
      return null;
    }
  }
}
