import Languages from "../../../../../translation/Languages";
import {customStylesMaterial, selectConfig} from "./select-styles";
import Select from "react-select";
import {memo, useCallback} from "react";
import CONSTANTS from "../../../../../config/constants";

const Material = ({
                                 detail,
                                 isDisabled,
                                 selectedMaterial,
                                 optionsMaterial,
                                 changeSelectType
                             }) => {
	const filterOptions = useCallback((option, input) => {
		if (input) {
			return option.value === Number(input - 1)
		}
		return true;
	}, [])
	const formatOptionLabel = useCallback(({label, index, article}, {context}) => {
		switch (context) {
			case 'menu':
				return `${index}: ${article} ${label}`;
			case 'value':
				return `${index}: ${article}`;
			default:
				return label;
		}
	}, []);
	
	const onChangeHandler = (event, action) => {
		if (event.value === detail[action.name]) {
			return;
		}
		
		const customEvent = {...event, name: action.name};
		
		changeSelectType(detail, customEvent)
	}
	
    return (<Select
            {...selectConfig}
            noOptionsMessage={() => Languages.getTranslation('not-find-variant', true)}
            filterOption={filterOptions}
            isDisabled={isDisabled}
						tabIndex={isDisabled ? -1 : 0}
            styles={customStylesMaterial}
            name={CONSTANTS.detailFieldNames.MATERIAL}
            formatOptionLabel={formatOptionLabel}
            value={selectedMaterial}
            onChange={onChangeHandler}
            options={optionsMaterial}/>
    )
};

export default memo(Material);