import React from "react";
import DefaultBtn from "../../Buttons/DefaultBtn";
import Languages from "../../../../translation/Languages";
import img from "../../../../media/material-add-img-test.svg";
import detail_img from "../../../../media/detail_img.svg";
import '../../../../components/CreateProject/Materials/style.scss'
import "./style.scss";

const AddDetailsErrorModal = ({errorDetailsImport,
                                  errorFurnitureImport = [],
                                  closeErrorDetailsImport,
                                  titleDetail, titleFurniture = '', allErrorsMessage = false, close}) => {

    return (
        <div className='modal'>
            <div className="modal-add-details_error">
                {errorDetailsImport.length > 0 &&
                    <>
                        <div className="modal-add-details_error__title">{`${titleDetail}:`}</div>
                        <h2 className="modal-add-furniture_error__title">{Languages.getTranslation("details", true)}</h2>
                        <div className="modal-add-details_error__header">
                            <div className="modal-add-details_error__info-container"
                                 style={{paddingTop: 10, paddingBottom: 7}}>
                                <div className="modal-add-details_error__header-item modal-add-details_error__number">№
                                </div>
                                <div className="modal-add-details_error__header-item modal-add-details_error__name">
                                    {Languages.getTranslation("name", true)}
                                </div>
                            </div>
                            <div className="modal-add-details_error__data-container">
                                <div style={{marginLeft: '20px'}}
                                     className='modal-add-details_error__header-item modal-add-details_error__data-item'>
                                    {Languages.getTranslation("height", true)}
                                </div>
                                <div
                                    className='modal-add-details_error__header-item modal-add-details_error__data-item'>
                                    {Languages.getTranslation("width", true)}
                                </div>
                                <div
                                    className='modal-add-details_error__header-item modal-add-details_error__data-item'>
                                    {Languages.getTranslation("amount", true)}
                                </div>
                            </div>
                        </div>
                        <div className="modal-add-details_error__body">
                            {errorDetailsImport.map((detail, key) => {
                                return (
                                    <>
                                        <div key={key}
                                             className={`modal-add-details_error__body-item`}>
                                            <div className="modal-add-details_error__info-container">
                                            <span
                                                className=" modal-add-details_error__number">{detail.indexArr + 1}</span>
                                                <div
                                                    className="modal-add-details_error__name">{detail[allErrorsMessage ? '_name' : 'name']}</div>
                                            </div>
                                            <div className="modal-add-details_error__data-container">
                                                <div
                                                    className="modal-add-details_error__data-item">{detail[allErrorsMessage ? '_l' : 'l']}</div>
                                                <div
                                                    className="modal-add-details_error__data-item">{detail[allErrorsMessage ? '_h' : 'h']}</div>
                                                <div
                                                    className="modal-add-details_error__data-item">{detail[allErrorsMessage ? '_count' : 'count']}</div>
                                            </div>
                                        </div>
                                        {!allErrorsMessage && detail.messages.map((err, index) => (
                                                <div key={index} className={`modal-add-details_error__body-itemError`}>
                                                    <svg width="12" height="13" viewBox="0 0 12 13" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd"
                                                              d="M11.8924 6.50003C11.8924 9.78402 9.23019 12.4462 5.9462 12.4462C2.6622 12.4462 0 9.78402 0 6.50003C0 3.21604 2.6622 0.553833 5.9462 0.553833C9.23019 0.553833 11.8924 3.21604 11.8924 6.50003ZM5.468 7.7925L5.38183 3.44125H6.51057L6.42441 7.7925H5.468ZM5.4163 8.5335V9.55885H6.47611V8.5335H5.4163Z"
                                                              fill="#F04444"/>
                                                    </svg>
                                                    {err.ua.split(',')[0].split('-')}</div>
                                            )
                                        )}
                                        {allErrorsMessage && detail.error.allErrorsMessage.map((err, index) => {
                                                return (
                                                    <div key={index} style={{color: `var(--toastify-icon-color-${err.type})`}}
                                                         className={`modal-add-details_error__body-itemError`}>
                                                        {err.message}</div>
                                                )
                                            }
                                        )}
                                    </>
                                );
                            })}
                        </div>
                    </>}
                {errorFurnitureImport.length > 0 &&
                    <>
                        <h2 className="modal-add-furniture_error__title">
                            {`${titleFurniture}`}</h2>
                        <div className="modal-add-furniture_error__header">
                            <div className="modal-add-furniture_error__info-container"
                                 style={{paddingTop: 10, paddingBottom: 7}}>
                                <div
                                    className="modal-add-furniture_error__header-item modal-add-furniture_error__number">№
                                </div>
                                <div className="modal-add-furniture_error__header-item modal-add-furniture_error__name">
                                    {Languages.getTranslation("name", true)}
                                </div>
                            </div>
                            <div className="modal-add-furniture_error__data-container">
                                <div style={{marginLeft: '20px'}}
                                     className='modal-add-furniture_error__header-item modal-add-furniture_error__data-item'>
                                    {Languages.getTranslation("article", true)}
                                </div>
                            </div>
                        </div>
                        <div className="modal-add-furniture_error__body">
                            {errorFurnitureImport.map((fur, key) => {
                                return (
                                    <div key={key}
                                         className={`modal-add-furniture_error__body-item`}>
                                        <div className="modal-add-furniture_error__info-container">
                                            <span className=" modal-add-furniture_error__number">{key + 1}</span>
                                            <div className="modal-add-furniture_error__name">{fur.name}</div>
                                        </div>
                                        <div className="modal-add-furniture_error__data-container">
                                            <div className="modal-add-furniture_error__data-item">{fur.article}</div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        {/*<div className="itemError">*/}
                        {/*    <svg width="12" height="13" viewBox="0 0 12 13" fill="none"*/}
                        {/*         xmlns="http://www.w3.org/2000/svg">*/}
                        {/*        <path fill-rule="evenodd" clip-rule="evenodd"*/}
                        {/*              d="M11.8924 6.50003C11.8924 9.78402 9.23019 12.4462 5.9462 12.4462C2.6622 12.4462 0 9.78402 0 6.50003C0 3.21604 2.6622 0.553833 5.9462 0.553833C9.23019 0.553833 11.8924 3.21604 11.8924 6.50003ZM5.468 7.7925L5.38183 3.44125H6.51057L6.42441 7.7925H5.468ZM5.4163 8.5335V9.55885H6.47611V8.5335H5.4163Z"*/}
                        {/*              fill="#F04444"/>*/}
                        {/*    </svg>*/}
                        {/*    {titleFurniture}*/}
                        {/*</div>*/}
                    </>
                }
                <div className='modal-add-details_error__wraperBtn'>
                    <div
                        className={`${allErrorsMessage ? 'modal-add-details_error__wraperBtn__btns' : 'modal-add-details_error__wraperBtn__btnOne'}`}>
                        {allErrorsMessage &&
                            <DefaultBtn title={Languages.getTranslation('cancel', true)} handler={close}/>}
                        <DefaultBtn title={Languages.getTranslation('continue', true)}
                                    handler={closeErrorDetailsImport}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddDetailsErrorModal;

