import React, {useEffect, useState} from "react";
import "./index.scss";
import Languages, {LANGS} from "../../../../translation/Languages";
import {useSelector} from "react-redux";
import {projectSelectors} from "redux/project";
import {empty} from "../../../../helpers/helper";
import Requests from "../../../../api/API";
import Loader from "../../Loader";
import SubmitFurnitureModal from "../../Modal/SubmitFurnitureModal";
import {langSelectors} from "redux/lang";

const TableHardware = () => {
    const construction = useSelector(projectSelectors.getConstruction);
    const { furnitures } = construction
    const curLang = useSelector(langSelectors.getLangState);
    const [isLoaded, setIsLoaded] = useState(false)
    const [hardwareSelected, setHardwareSelected] = useState([]);
    const [furnituresArr, setFurnituresArr] = useState([])
    const [submitDelete, setSubmitDelete] = useState({is: false, name: '', index: null})

    const loadFurniture = async (article) => {
        Requests.Materials.getFurnitureAll(article)
            .then(data => {
                if (!empty(data)) {
                    const tempData = []
                    data.data.forEach(item => {
                        const currentFur = furnitures.find(el => el.article === item.vendor_code)
                        if(!empty(currentFur)){
                            tempData.push({...item, link: item.link, count: currentFur.count, index: currentFur.index});
                        } else {
                            console.log('not find furnitures')
                        }

                    })
                    setFurnituresArr([...tempData])
                    setIsLoaded(false)
                }
                return Promise.resolve();
            })
            .catch(error => console.log(error.message))
    }

    useEffect(() => {
        if(!empty(furnitures)){
            setIsLoaded(true)
            let reqStr = ''
            furnitures.forEach((item, index) => { //TODO: ???
                reqStr += `${index > 0 ? '&' : ''}vendorCodes[]=${item.article}`
            })

            loadFurniture(reqStr)
                .then(() => {})
                .catch(error => console.log(error));

        } else {
            setFurnituresArr([])
        }
    }, [furnitures])

    const setAllHardware = () => {
        if(!empty(hardwareSelected) && hardwareSelected.length === furnituresArr.length){
            setHardwareSelected([])
        } else {setHardwareSelected(furnituresArr)}
    }

    const deleteSelectHardware = () => {
        setIsLoaded(true);
        construction.deleteFurnitures([...hardwareSelected])
            .then(() => {
                setIsLoaded(false)
                setHardwareSelected([])
            })
            .catch(error => {
                setIsLoaded(false);
                console.log(error);
            });
    }

    const deleteOneHardware = (index) => {
        construction.deleteFurniture(index)
            .then(() => {
                setFurnituresArr(prev => [...prev.filter((el) => el.index !== index )]);
                setSubmitDelete( {is: false, name: '', index: null})
            }).catch(error => console.log(error))
    }

    const activeCheckBoxHardware = (type, item) => {
        if (type === "checkbox") {
            return hardwareSelected.includes(item)
                ? "detailsTab__checkbox detailsTab__checkbox_active"
                : "detailsTab__checkbox";
        } else {
            return hardwareSelected.includes(item)
                ? "checkbox__icon checkbox__icon_active"
                : "checkbox__icon";
        }
    };

    const selectHardwareHandler = (item) => {
        if (hardwareSelected.includes(item)) {
            const filteredHardware = hardwareSelected.filter(
                (el) => el.index !== item.index
            );
            setHardwareSelected([...filteredHardware]);
        } else {
            setHardwareSelected([...hardwareSelected, item]);
        }
    };

    const calcSum = () => {
        return furnituresArr.reduce((acc, el) => acc + el.count * el.info.price, 0).toFixed(2);
    }
    const calcSumDiscount = () => {
        return furnituresArr.reduce((acc, {count, info: {price, discount, actionDiscount}}) => {
            const effectiveDiscount = discount > actionDiscount ? discount : actionDiscount;
            return effectiveDiscount > 0
                ?  acc + count * (price * effectiveDiscount / 100)
                : acc + 0;
        }, 0).toFixed(2);
    }

    const closeSubmitDelete = () => {
        setSubmitDelete( {is: false, name: '', index: null})
    }

    return (
        <div className='hardwareTab'>
            <div className='hardwareTab_oneCol'>
                <div className='hardwareTab_oneCol__header'>
                    <div className='hardwareTab_oneCol__header_wrap'>
                        <div className='flex'>
                            <div className='hardwareTab_oneCol__header_wrap_nameCol'>
                                {Languages.getTranslation("added-product", true)}
                            </div>
                        </div>
                        {hardwareSelected.length > 0 && <div className='hardwareTab_oneCol__header_wrap_delete'>
                            <span>{Languages.getTranslation("delete-all-selected-furnitures", true)}</span>
                            <button className={`btn`}
                                    title={Languages.getTranslation("delete-all-selected-furnitures", true)}
                                 onClick={deleteSelectHardware}>
                                <i className="btn__icon-delete"></i>
                            </button>
                        </div>}
                    </div>

                    <div className='hardwareTab_oneCol__header_sub'>
                        <div
                            className={
                                hardwareSelected.length > 0 && hardwareSelected.length === furnituresArr.length
                                    ? "hardwareTab_oneCol__header_wrap__checkbox hardwareTab_oneCol__header_wrap__checkbox_active"
                                    : "hardwareTab_oneCol__header_wrap__checkbox"
                            }
                            onClick={setAllHardware}
                        >
                            <i className={hardwareSelected.length > 0 ? "checkbox__icon checkbox__icon_active" : ''}></i>
                        </div>
                        <div className='hardwareTab_oneCol__header_sub_text'>№</div>
                        <div
                            className='hardwareTab_oneCol__header_sub_text hardwareTab_oneCol__header_sub_name'>
                            {Languages.getTranslation("name", true)}</div>
                        <div
                            className='hardwareTab_oneCol__header_sub_text'>{Languages.getTranslation("article", true)}</div>
                        <div
                            className='hardwareTab_oneCol__header_sub_text'>{Languages.getTranslation("amount", true)}</div>
                        <div
                            className='hardwareTab_oneCol__header_sub_text'>{Languages.getTranslation("price", true)}</div>
                       <div
                            className='hardwareTab_oneCol__header_sub_text'>{Languages.getTranslation("total", true)}</div>
                        <div></div>
                    </div>

                    {isLoaded ?
                        <div className='hardwareTab_oneCol__header_loader'>
                            <Loader/>
                        </div> :
                        !empty(furnituresArr) ? furnituresArr.sort((a, b) => a.index - b.index).map((item, index) => {
                            return (
                                <div key={index} className='hardwareTab_oneCol__header_listItem'>
                                    <div
                                        className={activeCheckBoxHardware("checkbox", item)}
                                        onClick={() => selectHardwareHandler(item)}
                                    >
                                        <i className={activeCheckBoxHardware("icon", item)}></i>
                                    </div>
                                    <div className='hardwareTab_oneCol__header_listItem_text'>{index + 1}</div>
                                    <div
                                        className='hardwareTab_oneCol__header_listItem_text'>
                                        <img className='hardwareTab_oneCol__header_listItem_text_img'
                                             src={item?.media?.images[0]?.small} alt={item.article}/>
                                        <div className='hardwareTab_oneCol__header_listItem_text_subText'>
                                            <a className='hardwareTab_oneCol__header_listItem_text_subText_link'
                                                href={curLang === LANGS.UA
                                                    ? `https://kronas.com.ua/ua/api/v2/product/link/${item.vendor_code}`
                                                    : `https://kronas.com.ua/api/v2/product/link/${item.vendor_code}`}
                                               target='_blank'>
                                                {Languages.getAvaliableTranslation(item.translations)
                                                || item.name}
                                            </a>
                                        </div>
                                    </div>
                                    <div
                                        className='hardwareTab_oneCol__header_listItem_text'>
                                        {item.vendor_code}
                                    </div>
                                    <div
                                        className='hardwareTab_oneCol__header_listItem_text'>
                                        {item.count}
                                    </div>
                                    <div
                                        className='hardwareTab_oneCol__header_listItem_text'>
                                        {item.info.price.toFixed(2)}
                                    </div>
                                    { item.info.price.discount > 0 &&
                                        <div
                                        className='hardwareTab_oneCol__header_listItem_text'>
                                        {item.info.price.toFixed(2)}
                                    </div>}
                                    <div
                                        className='hardwareTab_oneCol__header_listItem_text'>
                                        {(item.count * item.info.price).toFixed(2)}
                                    </div>
                                    <button style={{marginLeft: '5px'}} className={`btn`}
                                            onClick={() => setSubmitDelete({
                                                is: true,
                                                name: Languages.getAvaliableTranslation(item.translations)
                                                    || item.name,
                                                index: item.index
                                            })}>
                                        <i className="btn__icon-delete"></i>
                                    </button>
                                </div>
                            )
                        }) : <div className='hardwareTab_oneCol__header_empty'>
                            {Languages.getTranslation('add-furnitures-in-list', true)}</div>}
                </div>
                <div className='hardwareTab_oneCol_sum'>
                    <div className='hardwareTab_oneCol-container'>
                        <div className='hardwareTab_oneCol-name'>{`${Languages.getTranslation('total-sum', true)}: `}</div>
                        <b className='hardwareTab_oneCol_sum_sub'>{calcSum() + ' ₴'}</b>
                    </div>
                    <div className='hardwareTab_oneCol-container'>
                        <div className='hardwareTab_oneCol-name'>{`${Languages.getTranslation('total-sum-discont', true)}: `}</div>
                        <b className='hardwareTab_oneCol_sum_sub'>{calcSumDiscount() + ' ₴'}</b>
                    </div>
                    <div className='hardwareTab_oneCol-container'>
                        <div className='hardwareTab_oneCol-name'>{`${Languages.getTranslation('total-sum-for-payment', true)}: `}</div>
                        <b className='hardwareTab_oneCol_sum_sub'>{(calcSum() - calcSumDiscount()).toFixed(2) + ' ₴'}</b>
                    </div>
                </div>
            </div>
            {submitDelete.is && <SubmitFurnitureModal
                index={submitDelete.index}
                deleteOneHardware={deleteOneHardware}
                close={closeSubmitDelete}
                submitModalText={submitDelete.name} />}
        </div>
    )
}

export default TableHardware