import img from "../../../../media/material-add-img-test.svg";
import {imgLink} from "../../../../helpers/helper";
import "./style.scss";

import { useState } from 'react';

const MaterialEdgeImg = ({ item, isMaterial = true }) => {
    const [isHovered, setIsHovered] = useState(false);


    const smallImage = item?.images?.length > 0 && item.images[0]?.small;
    const bigImage = item?.images?.length > 0 && item.images[0]?.original;

    return (
        <div
            className="material__img-container"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <img
                src={smallImage ? smallImage : imgLink}
                alt="image"
                className={`material__img ${isMaterial ? 'material__img--border' : ''}`}
            />
            {bigImage && (
                <img
                    src={bigImage}
                    alt="enlarged image"
                    className={`material__img--big ${isHovered ? 'visible' : ''}`}
                />
            )}
        </div>
    );
};

export default MaterialEdgeImg;