import React from 'react';
import SelectedDetails from "../../components/SelectedDetails";
import UsedDetails from "../../components/UsedDetails";
import ProjectDetails from "../../components/ProjectDetails";
import {useSelector, useDispatch} from "react-redux";
import {constructor3dSelectors, constructor3dOperations} from "../../../../redux/constructor";
import './style.scss';
import SelectedObjects from "../../components/SelectedObjects";
import ChangeView from "../../components/ChangeView";
import {empty} from "../../../../helpers/helper";

const LeftPanel = () => {
	const opened = useSelector(constructor3dSelectors.leftPanelOpened)
	const products = useSelector(constructor3dSelectors.getProducts)
	const dispatch = useDispatch();

	const togglePanel = () => {
		dispatch(constructor3dOperations.switchLeftPanel(!opened))
	}

	const getSearchResults = (res) => {
		if(empty(res)) return null;
		return (
			res.map((r, key) => {
				return( <div key={key}>{r}</div> )
			})
		)
	}

	return (
		<div className={`panel-wrapper panel-wrapper-left ${opened ? 'opened' : ''}`}>
			<div className={'panel-wrapper-header'}>
				{/*<button>t</button>*/}
				{/*<button>s</button>*/}
				<button className={'icon-button-arrow'} onClick={togglePanel}></button>
			</div>
			<div className={'panel-wrapper-body'}>
				{/*<ChangeView />*/}
				<SelectedObjects />
				{products.map(el => {
					return (<UsedDetails key={el.id} productId={el.id} title={el.name} />)
				})}
				{/*<ProjectDetails/>*/}
			</div>
		</div>
	)
}

export default LeftPanel;