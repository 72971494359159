import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { modalsOperations } from "redux/modals";
import "./style.scss";
import {projectOperations, projectSelectors} from "redux/project";
import { useSelector } from "react-redux";
import DefaultBtn from "../../Buttons/DefaultBtn";
import Languages from "../../../../translation/Languages";
import CloseBtn from "../../Buttons/CloseBtn";

const IncreaseDetail = () => {
  const dispatch = useDispatch();
  const detail = useSelector(projectSelectors.getDetail);

  const [values, setValues] = useState({
    right: '',
    left: '',
    top: '',
    bottom: ''
  });
  const [errors, setErrors] = useState({
    right: '',
    left: '',
    top: '',
    bottom: ''
  });

  useEffect(() => {
    if (detail?.preCutting) {
      const updatedValues = Object.fromEntries(
          Object.entries(detail.preCutting).map(([key, value]) => [
            key,
            value === null ? '' : value
          ])
      );
      setValues(updatedValues);
    }
  }, [detail]);

  const [disableBtn, setDisableBtn] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target;
    const numberValue = parseInt(value, 10);

    setValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));

    if (value === '' || (!isNaN(numberValue) && numberValue >= 0 && numberValue <= 999)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: ''
      }));
      setDisableBtn(false)

    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: Languages.getTranslation('increaseDetailShould1to999', true)
      }));
      setDisableBtn(true)
    }
  };

  const close = () => {
    dispatch(
      modalsOperations.switchStateForModal({
        show: false,
        type: null,
      })
    );
  };
  function transformValuesToNull(obj) {
    return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [key, value === 0 ? null : value])
    );
  }
  const submit = async () => {
    const transformedValues = Object.fromEntries(
        Object.entries(values).map(([key, value]) => [
          key,
          value  === '' ? null : Number(value)
        ])
    );
      const result =  transformValuesToNull(transformedValues)
     detail.setHandlePreCutting(result)

     detail.updateIsPreCuttingManually(true)
     detail.fullRender()

     close()
  };


  return (
    <>
      <div className="increase-detail">
        <div className="increase-detail__title">
          <span className="increase-detail__title-text">
          {Languages.getTranslation('increase-details', true)}
          </span>
          <CloseBtn handler={close}/>

        </div>
        <div className="increase-detail__body">
          <div className="increase-detail__form-control">
            <label
                htmlFor="name"
                className="increase-detail__form-control-label"
            >
              {Languages.getTranslation('increase-from-top', true)}
            </label>
            <input
                id="name"
                onChange={handleChange}
                name="top"
                value={values.top}
                type="number"
                className="increase-detail__form-control-input"
            />

              {errors.top && <div className="increase-detail__form-control-error">{errors.top}</div>}
          </div>
          <div className="increase-detail__form-control">
            <label
                htmlFor="name"
                className="increase-detail__form-control-label"
            >
              {Languages.getTranslation('increase-from-bottom', true)}
            </label>
            <input
                id="name"
                onChange={handleChange}
                name="bottom"
                value={values.bottom}
                type="number"

                className="increase-detail__form-control-input"
            />
            {errors.bottom && <div className="increase-detail__form-control-error">{errors.bottom}</div>}

          </div>


          <div className="increase-detail__form-control">
            <label
                htmlFor="name"
                className="increase-detail__form-control-label"
            >
              {Languages.getTranslation('increase-from-left', true)}
            </label>
            <input
                id="name"
                onChange={handleChange}
                name="left"
                value={values.left}
                type="number"
                className="increase-detail__form-control-input"
            />
            {errors.left && <div className="increase-detail__form-control-error">{errors.left}</div>}

          </div>


          <div className="increase-detail__form-control">
            <label
                htmlFor="name"
                className="increase-detail__form-control-label"
            >
              {Languages.getTranslation('increase-from-right', true)}
            </label>
            <input
                id="name"
                onChange={handleChange}
                name="right"
                value={values.right}
                type="number"

                className="increase-detail__form-control-input"
            />
            {errors.right && <div className="increase-detail__form-control-error">{errors.right}</div>}

          </div>
        </div>
        <div className="increase-detail__btns">
          <DefaultBtn className="defaultBtn5" title={Languages.getTranslation('cancel', true)} handler={close}/>
          <DefaultBtn className="defaultBtn5" title={Languages.getTranslation('save', true)} handler={submit} disabled={disableBtn}/>
        </div>
      </div>
    </>
  );
};

export default IncreaseDetail;
