import {memo} from "react";

const CustomInput = ({onChange, value, name, className, isDisabled, ...rest}) => {
	
	const onInputChange = (event) => {
		const { name, value } = event.target;
		
		onChange({name, value: value.trim()})
	}
	
	return <input
		disabled={isDisabled}
		name={name}
		className={`detailsTab__input ${className}`}
		type="text"
		tabIndex={isDisabled ? -1 : 0}
		value={value}
		onChange={onInputChange}
		autoComplete='off'
		{...rest}
	/>
};

export default memo(CustomInput)