import React, {useEffect, useRef, useState} from "react";
import "./style.scss";
import {useDispatch} from "react-redux";
import {empty, validateDetail, validationInput, handlePaste} from "../../../../../helpers/helper";
import Languages from "../../../../../translation/Languages";
import {errorMessageOperations} from '../../../../../redux/errors'

const TableItem = ({
	                   row,
	                   selectedMaterial,
	                   detail,
	                   deleteDetail,
	                   saveDetail,
	                   currentMaterials,
	                   edges,
	                   isEdgesHelperOn,
	                   setIsEdgesHelperOn,
	                   itemHelper,
	                   setCurrentDetail,
	                   setYPoint,
	                   setLeftPoint,
	                   setLeftPoint2,
	                   edgeHelperRef,
	                   focus,
	                   handleAddDetail,
	                   edgesMode,
	                   edgesModeOnly,
										 setSelectedMaterial,
	                   _errors
                   }) => {
	const [formData, setFormData] = useState({});
	const dispatch = useDispatch()

	const detailRef = useRef()
	const heightInput = useRef()
	const countInput = useRef()
	const edgeBottom = useRef()
	const edgeTop = useRef()
	const edgeRight = useRef()
	const edgeLeft = useRef()

	const inputs = [
		{id: 1, className: "label-height", value: formData.width, name: "width",},
		{id: 2, className: "label-width", value: formData.height, name: "height", ref: heightInput},
		{id: 3, className: "label-count", value: formData.count, name: "count", ref: countInput},
		{
			id: 4,
			type: "number",
			min: 1,
			max: edges.length,
			className: "tableItem__input tableItem__input-icon",
			value: formData.edgeTop,
			name: "edgeTop",
			ref: edgeTop
		},
		{
			id: 5,
			type: "number",
			min: 1,
			max: edges.length,
			className: "tableItem__input tableItem__input-icon",
			value: formData.edgeBottom,
			name: "edgeBottom",
			ref: edgeBottom
		},
		{
			id: 6,
			className: "tableItem__input tableItem__input-icon",
			value: formData.edgeLeft,
			min: 1,
			max: edges.length,
			type: "number",
			name: "edgeLeft",
			ref: edgeLeft
		},
		{
			id: 7,
			className: "tableItem__input tableItem__input-icon",
			value: formData.edgeRight,
			min: 1,
			max: edges.length,
			type: "number",
			name: "edgeRight",
			ref: edgeRight,

		},
	];

	const edgeModeOnlyValidate = () => {
		if (edgesModeOnly) {
			if ((!detail.l && !detail.h) || (!detail.count && !detail.l) || (!detail.count && !detail.h)) {
				return true
			}
		}
		return false
	}

	const changeInput = (e) => {
		const { keyCode } = e;
		if (keyCode === 38 || keyCode === 40 || e.key === '-' || e.key === "+") { // 38 и 40 - коды клавиш вверх и вниз соответственно
			e.preventDefault();
		}
		if (e.key === 'Enter') {
			switch (e.target.name) {
				case 'height' :
					countInput.current.focus()
					break
				case 'width' :
					heightInput.current.focus()
					break
				case 'count' :
					if (edgesMode && selectedMaterial.type !== 'Compact-плита' && selectedMaterial.type !== 'OSB') {
						edgeTop.current.focus()
						return
					}
					if (detailRef.current?.nextElementSibling) {
						detailRef.current?.nextElementSibling.childNodes[1]?.firstElementChild.focus()
						return
					} else {
						handleAddDetail()
					}
					break
				case 'edgeBottom' :
					if (edgeModeOnlyValidate()) {
						_errors.setError(`${Languages.getTranslation("part-are-not-correct", true)}`, 'error', true)
						return
					}
					edgeLeft.current.focus()
					break
				case 'edgeTop' :
					if (edgeModeOnlyValidate()) {
						_errors.setError(`${Languages.getTranslation("part-are-not-correct", true)}`, 'error', true)
						return
					}
					edgeBottom.current.focus()
					break
				case 'edgeRight' :
					if (edgeModeOnlyValidate()) {
						_errors.setError(`${Languages.getTranslation("part-are-not-correct", true)}`, 'error', true)
						return
					}
					if (detailRef.current?.nextElementSibling) {
						if (edgesModeOnly) {
							detailRef.current?.nextElementSibling?.childNodes[1].childNodes[3].focus()
							return;
						}
						detailRef.current?.nextElementSibling.childNodes[1]?.firstElementChild.focus()
						return
					} else {
						handleAddDetail()
					}
					break
				case 'edgeLeft' :
					if (edgeModeOnlyValidate()) {
						_errors.setError(`${Languages.getTranslation("part-are-not-correct", true)}`, 'error', true)
						return
					}
					edgeRight.current.focus()
					break
			}
		}
	};

	const getFormData = () => {
		return {
			name: detail.name,
			material: detail.material.index || selectedMaterial.index,
			height: detail.h || "",
			width: detail.l || "",
			count: detail.count || "",
			edgeBottom: detail.edges.bottom ?? "",
			edgeTop: detail.edges.top ?? "",
			edgeRight: detail.edges.right ?? "",
			edgeLeft: detail.edges.left ?? "",
			isRotateTexture: detail.isRotateTexture ?? true
		};
	}

	const prepareDetailData = () => {
		const edges = {
			top: !empty(formData.edgeTop) ? Number(formData.edgeTop) : null,
			left: !empty(formData.edgeLeft) ? Number(formData.edgeLeft) : null,
			bottom: !empty(formData.edgeBottom) ? Number(formData.edgeBottom) : null,
			right: !empty(formData.edgeRight) ? Number(formData.edgeRight) : null,
		}
		return {
			name: formData.name,
			material: formData.material,
			h: formData.height || 0,
			ch: formData.height || 0,
			l: formData.width || 0,
			cl: formData.width || 0,
			count: formData.count || 1,
			edges: edges,
			isRotateTexture: formData.isRotateTexture
		}
	}

	const handleChange = (event) => {
		let {name, value} = event.target;

		value = !isNaN(parseFloat(value))
			? parseFloat(value)
			: value;
		if (name === 'height' || name === 'width') {
			const newName = name === 'height' ? 'width' : 'height';
			let number = 26;
			if(['OSB'].includes(selectedMaterial.type)) {number = 0}
			if (['Постформінг', 'Постформинг', 'Стеновая панель', 'Стінова панель, Compact-плита'].includes(selectedMaterial.type)) {
				if (name === 'width') {
					setFormData({
						...formData,
						[name]: value
					})
				} else {
					setFormData({
						...formData,
						[name]: value
					})
				}
				if (value >= selectedMaterial[newName] && name === 'width') {
					dispatch(errorMessageOperations.switchStateError(
						{message: `${Languages.getTranslation("same-sides-detail-material", true)} 26мм ${Languages.getTranslation("smaller")}`, type: 'warning', show: true}))
				}
				return
			}
			if (validateDetail(`${value}`, selectedMaterial, name)) {
				setFormData({
					...formData,
					// [name]: selectedMaterial[newName] - number
					[name]: value
				})
				 dispatch(errorMessageOperations.switchStateError(
					{message: `${Languages.getTranslation("same-sides-detail-material", true)} 26мм меншими`, type: 'warning', show: true}))
				return
			}
		}
		if (['edgeBottom', 'edgeTop', 'edgeRight', 'edgeLeft'].includes(name)) {
			if (empty(edges)) {
				_errors.setError(`${Languages.getTranslation("project-dont-have-edges", true)}`, 'error', true)
				return
			}
		}
		if (edgeModeOnlyValidate()) {
			_errors.setError(`${Languages.getTranslation("part-are-not-correct", true)}`, 'error', true)
			return
		}

		setFormData({
			...formData,
			[name]: value,
		});
	};

	const changeDetailProps = () => {
		const {width, height, count} = formData;
		if (empty(Number(width)) || empty(Number(height)) || empty(Number(count)) || Number(count) === 0) {
			return
		}
		saveDetail(prepareDetailData(), row)
	};

	useEffect(() => {
		changeDetailProps();
	}, [formData]);

	useEffect(() => {
		setFormData(getFormData());
	}, [detail]);

	useEffect(() => {
		if(['OSB'].includes(detail.material?.type)){
			setFormData({
				...formData,
				isRotateTexture: detail.isRotateTexture
			})
		}
	}, [detail.isRotateTexture]);

	useEffect(() => {
		if (!detail.id) {
			setFormData({
				name: detail.name,
				material: selectedMaterial.index,
				height: detail.h || "",
				width: detail.l || "",
				count: detail.count || "",
				edgeBottom: detail.edges.bottom ?? "",
				edgeTop: detail.edges.top ?? "",
				edgeRight: detail.edges.right ?? "",
				edgeLeft: detail.edges.left ?? "",
				isRotateTexture: detail.isRotateTexture
			})
		}
		changeDetailProps();
	}, [setSelectedMaterial]);

	const isRotateTextureHandler = () => {
		if(['OSB'].includes(detail.material?.type) || ['OSB'].includes(currentMaterials.find(el => el.index === detail.material)?.type)) { return; }
		setFormData({
			...formData,
			isRotateTexture: !formData.isRotateTexture
		})
	}

	useEffect(() => {
		if (edgeRight.current) {
			setLeftPoint2(edgeRight.current.getBoundingClientRect().left)
		}
	}, [edgeRight.current])

	useEffect(() => {
		if (edgeTop.current) {
			setLeftPoint(edgeTop.current.getBoundingClientRect().left)
		}
	}, [edgeTop.current])

	const activeCheckBox = (type) => {
		if (type === "checkbox") {
			return formData.isRotateTexture
				? "addDetail__checkbox addDetail__checkbox_active"
				: "addDetail__checkbox";

		} else {
			return formData.isRotateTexture
				? "checkbox__icon checkbox__icon_active"
				: "checkbox__icon";

		}
	};

	const deleteDetailHandler = (detail, row) => {
		deleteDetail(detail, row);
		detailItemHelperOffHandler()
	}

	const edgesHelperHandler = (e) => {
		onFocusHandler(e)
		setIsEdgesHelperOn(true)
		itemHelper.current.className = 'itemHelper'
		edgeHelperRef.current.className = 'quickInputTable__edges-helper quickInputTable__edges-helper_active'
	}
	const edgesHelperOffHandler = () => {
		setIsEdgesHelperOn(false)
		itemHelper.current.className = 'itemHelper'
		edgeHelperRef.current.className = 'quickInputTable__edges-helper'
	}

	const detailItemHelperHandler = (event) => {
		setYPoint(event.target.getBoundingClientRect().top-265)
		setCurrentDetail(detail)
		if (isEdgesHelperOn) {
			itemHelper.current.className = 'itemHelper'
			return
		}
		if (itemHelper.current) {
			itemHelper.current.className = 'itemHelper itemHelper_active'
		}
	}

	const detailItemHelperOffHandler = () => {
		if (itemHelper.current) {
			itemHelper.current.className = 'itemHelper'
		}
	}

	const onFocusHandler = (e) => {
		e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })
		e.target.select()
	}

	const preventScrollChange = (event) => {
		// event.preventDefault();
		event.stopPropagation();
	}

	const handleKeyPress = (event) => {
		if (event.key === '*') {
			event.preventDefault();
		}
	};

	return (
		<div className="tableItem"
			ref={detailRef}
>
			<span className="tableItem__number  ">{row + 1}</span>
			<div className="tableItem__inputs-container">
				{inputs.map(({
					             id,
					             className,
					             value,
					             name,
					             type = "number",
					             min,
					             max,
					             ref
				             }, index) => {
					const autoFocus = focus === name;
					if (min && max) {
						return (
							<input
								key={id}
								className={`tableItem__input ${className}`}
								type={type}
								min={min}
								max={max}
								onInput={(e) => validationInput(e)}
								onKeyDown={(e) => changeInput(e)}
								value={value}
								name={name}
								disabled={['Compact-плита', 'OSB'].includes(empty(detail.material?.type)
									? currentMaterials.find(el => el.index === detail.material).type : detail.material.type) || !edges.length}
								onChange={(e) => handleChange(e)}
								ref={ref}
								onMouseEnter={(event) => detailItemHelperHandler(event)}
								onMouseLeave={detailItemHelperOffHandler}
								onFocus={(e) => edgesHelperHandler(e)}
								onBlur={edgesHelperOffHandler}
								autoComplete='off'
								onWheel={(e) => preventScrollChange(e)}
							/>
						)
					} else {
						return (
							<input
								key={id}
								className={`tableItem__input ${className}`}
								type={type}
								autoFocus={autoFocus}
								// onInput={(e) => validationInput(e)}
								onKeyDown={(e) => changeInput(e)}
								value={value}
								name={name}
								ref={ref}
								onFocus={(e) => onFocusHandler(e)}
								onChange={(e) => handleChange(e)}
								autoComplete='off'
								onWheel={(e) => preventScrollChange(e)}
							/>
						)
					}
				})}
			</div>

			<div className="quickInputTable__content-container">
				<div className="tableItem__texture quickInputTable__table-header-texture">
					<div className={activeCheckBox("checkbox")} onClick={isRotateTextureHandler}
						 style={['OSB'].includes(currentMaterials.find(el => el.index === detail.material)?.type)
							 || ['OSB'].includes(detail?.material?.type) ? {
							 cursor: 'not-allowed',
							 opacity: '.5', width: 25, height: 25
						 } : {cursor: "pointer", width: 25, height: 25}}>
						<i className={activeCheckBox("icon")}></i>
					</div>
				</div>

				<input
					type="text"
					className="  tableItem__input-name"
					value={formData.name}
					name="name"
					onPaste={(event) => handlePaste(event, setFormData)}
					onChange={(e) => handleChange(e)}
					onKeyPress={handleKeyPress}
					autoComplete='off'
				/>
				<div
					className="tableItem__name ">{detail.material.article ?? currentMaterials.find(el => el.index === detail.material).article}</div>
				<div className="tableItem__delete">
					<button onClick={() => {
						deleteDetailHandler(detail, row);
					}} className="btn ">
						<i className="btn__icon-delete"></i>
					</button>
				</div>
			</div>
		</div>
	);
};

export default TableItem;
