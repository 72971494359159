import React, {useEffect, useState} from 'react';
import CDetail from "../../../model/CDetail";
import {constructor3dOperations, constructor3dSelectors} from "./../../../../redux/constructor";
import {useDispatch, useSelector} from "react-redux";
import Tooltip from "../UI/Tooltip";

import './style.scss'

const ProjectDetail = ({ detail, used }) => {
	const dispatch = useDispatch()

	const addDetail = e => {
		e.preventDefault();
		if(detail.disabled) return;
		const _detail = new CDetail({
			name: detail.name,
			l: detail.l,
			h: detail.h,
			w: detail.w,
			detailId: detail.detailId,
			detailCId: detail.detailCId,
			contour3d: detail.contour3d,
			position: {x: 0, y: 0, z: 0},
			rotation: {x: 0, y: 0, z: 0},
			productId: detail.productId || 1,
			getEdgeData: detail.getEdgeData.bind(detail)
		});
		dispatch(constructor3dOperations.addCDetail(_detail))
			.then(() => _detail.create3DDetail())
	}

	return (
		<div className="block">
			<div className={`project-detail block__header `}>
				<div className={'block__header-title project-detail__title'}>{detail.name}</div>
				<Tooltip className={'icon-button-info'} showType={'hover'}>
					<p>Всього деталей в проєкті: {detail.count}</p>
					<p>Додано в конструктор: {used}</p>
				</Tooltip>
				<a className={`icon-button-plus ${detail.disabled ? 'disabled' : ''}`}  onClick={addDetail}></a>
			</div>
		</div>
	)
}

export default ProjectDetail