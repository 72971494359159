import React, {useEffect, useRef, useState} from "react";
import Requests from "api/API";
import {useSelector} from "react-redux";
import {projectSelectors} from "redux/project";
import {empty} from "../../../../helpers/helper";

import CloseBtn from "components/UI/Buttons/CloseBtn";
import Languages from "translation/Languages";
import DefaultBtn from "components/UI/Buttons/DefaultBtn";

import "./style.scss";
import Loader from "../../Loader";
import Errors from "../../../../models/Errors";

const _errors = new Errors([])

const AddHardwareModal = ({close, searchCatalog}) => {
    const construction = useSelector(projectSelectors.getConstruction);

    const [hardware, setHardware] = useState([])
    const [selectedHardware, setSelectedHardware] = useState([])
    const [code, setCode] = useState("");

    const [isLoaded, setIsLoaded] = useState(false);

    const [debouncedValue, setDebouncedValue] = useState('');

    const input = useRef(null);

    const search = async () => {
        setHardware([])
        if (code.length > 2) {
            setIsLoaded(true)

            const { data } = await Requests.Materials.getHardware(code).catch(error => {
                _errors.setError(error.message, 'error', true)
            });
            if (empty(data)) {
                setIsLoaded(false)
                setHardware(['empty'])
            }
            if (!empty(data)) {
                setIsLoaded(false)
                setHardware(data.map(item => {
                    return {...item, count: 1, article: item.vendor_code}
                }))
            }
        }
    };

    useEffect(() => {
        const delay = 500;
        const debounceTimer = setTimeout(() => {
            setDebouncedValue(code);
        }, delay);

        return () => {
            clearTimeout(debounceTimer);
        };
    }, [code]);

    useEffect(() => {
        if (debouncedValue !== '') {
            search().then(() => {}).catch(error => console.log(error))
        }
    }, [debouncedValue]);


    const submit = () => {
        if(empty(selectedHardware)){
            _errors.setError(`${Languages.getTranslation("hardware-not-chos", true)}`, 'error', true)
            return;
        }
        console.log(selectedHardware)
        construction
            .setProjectFurnitures([...selectedHardware.map(el => ({...el, article: el.vendor_code}))])
            .then(() => {
                // setIsLoaded(false)
                setSelectedHardware([])
                close();
            });
    };


    const activeCheckBox = (type, hardware) => {
        if (type === "checkbox") {
            return selectedHardware.includes(hardware)
                ? "detailsTab__checkbox detailsTab__checkbox_active"
                : "detailsTab__checkbox";
        } else {
            return selectedHardware.includes(hardware)
                ? "checkbox__icon checkbox__icon_active"
                : "checkbox__icon";
        }
    };

    const selectHardwareHandler = (item) => {
        if(item.count <= 0){
            item.count = 1
        }
        if (selectedHardware.includes(item)) {
            setSelectedHardware([...selectedHardware.filter((el) => el.id !== item.id)]);
        } else {
            setSelectedHardware([...selectedHardware, item]);
        }
    };

    const handleChangeCont = (event, el) => {
        event.target.value = event.target.value.replace(/[^0-9]+/g, "");
        const { value }  = event.target;
        const temp = [...hardware]
        temp.forEach(item => {
            if(item.id === el.id){ item.count = Number(value) }
        })
        setHardware(temp)
    }

    const validateEmpty = (event, item) => {
        const { value }  = event.target;
        if(value === '' && item.count === 0){
            const temp = [...selectedHardware]
            temp.forEach(el => {
                if(el.id === item.id){ item.count = 1 }
            })
            setSelectedHardware([...temp]);
        }
    }

    return (<div className='modal'>
        <div className="addHardware">
            <div className="addHardware__header">
                    <h3 className="addHardware__header-title">
                        {Languages.getTranslation("add-accessories", true)}
                    </h3>
                    <div className="addHardware__header-searchField">
                        <i className="addHardware__input-icon"></i>
                        <input
                            className="addHardware__input"
                            onChange={(e) => setCode(e.target.value)}
                            ref={input}
                            type="text"
                            placeholder={Languages.getTranslation("enter-name-or-article", true)}
                            autoComplete='off'
                        />
                    </div>
                    <CloseBtn handler={close}/>
            </div>

            <div className="addHardware__colmHeader">
                <div className="addHardware__colmHeader-item">
                    {Languages.getTranslation("name", true)}</div>
                <div className="addHardware__colmHeader-item">
                    {Languages.getTranslation("article", true)}</div>
                <div className="addHardware__colmHeader-item">
                    {Languages.getTranslation("amount", true)}</div>
                <div className="addHardware__colmHeader-item">
                    {Languages.getTranslation("price", true)}</div>
                <div className="addHardware__colmHeader-item">
                    {Languages.getTranslation("total", true)}</div>
            </div>

            <div className="addHardware__body">
                {isLoaded && <Loader/>}
                {!empty(hardware) && hardware[0] !== 'empty' ? (
                    <div className="addHardware__body-block">
                        <div className="addHardware__body-list">
                            {hardware.map((item, index) => {
                                if (item.active === "act" && (item.info.price > 0 || item.info.price_old > 0)) {
                                    return (
                                        <div className="addHardware__body-list-item" key={index}>
                                            <div
                                                className={activeCheckBox("checkbox", item)}
                                                onClick={() => selectHardwareHandler(item)}
                                            >
                                                <i className={activeCheckBox("icon", item)}></i>
                                            </div>
                                            <img
                                                className="addHardware__body-list-item-img"
                                                src={item.media.images[0].small}
                                                alt={Languages.getAvaliableTranslationBaseOnUk(item.translations)}/>
                                            <span className="addHardware__body-list-item-text" title={Languages.getAvaliableTranslationBaseOnUk(item.translations)}>
                                                 {Languages.getAvaliableTranslationBaseOnUk(item.translations)}
                                            </span>
                                            <span className="addHardware__body-list-item-text">
                                                {`${item.vendor_code || item.article}`}
                                            </span>
                                            <div className='addHardware__body-list-item__default-input'>
                                                <input
                                                    type="text"
                                                    className='addHardware__body-list-item__input'
                                                    name="count"
                                                    value={item.count || ''}
                                                    onChange={(event) => handleChangeCont(event, item)}
                                                    onBlur={(event) => validateEmpty(event, item)}
                                                    autoComplete='off'
                                                />
                                            </div>
                                            <span className="addHardware__body-list-item-text" style={{textAlign: 'center'}}>
                                                 {Number(item.info.price).toFixed(2)}
                                            </span>
                                            <span className="addHardware__body-list-item-text" title={(item.info.price * item.count).toFixed(2)}>
                                                 {(item.info.price * item.count).toFixed(2)}
                                            </span>
                                        </div>)
                                }
                            })}
                        </div>
                    </div>) : (code !== '' && code.length > 2 && hardware[0] === 'empty'
                    && <div className="addHardware__body_not_find">
                        {Languages.getTranslation("not-found-furnitures", true)} </div>)}
            </div>


            <div className="addHardware__btns">
                {/*<div className="addHardware__actions">*/}
                    <DefaultBtn
                        title={Languages.getTranslation("search-in-catalog", true)}
                        handler={searchCatalog}
                    />
                {/*</div>*/}
                <div className="addHardware__btns_wrap">
                    {/*<div className="addHardware__actions">*/}
                    <DefaultBtn
                        title={Languages.getTranslation("cancel2", true)}
                        handler={close}
                    />
                    {/*</div>*/}
                    <DefaultBtn
                        title={Languages.getTranslation("save", true)}
                        handler={submit}
                        disabled={empty(selectedHardware)}
                    />
                </div>
            </div>
        </div>
    </div>);
};

export default AddHardwareModal;
