import {memo, useCallback} from "react";
import Languages from "../../../../../translation/Languages";
import {customStylesProduct, selectConfig} from "./select-styles";
import Select from "react-select";
import CONSTANTS from "../../../../../config/constants";

const Product = ({optionsProduct, selectedProduct, isDisabled, detail, changeSelectType}) => {
	
	const filterOptionsProduct = useCallback((option, input) => {
		if (input) {
			return option.value === Number(input)
		}
		return true;
	}, []);
	
	const onChangeHandler = (event, action) => {
		if (event.value === detail[action.name]) {
			return;
		}
		
		const customEvent = {...event, name: action.name};
		
		changeSelectType(detail, customEvent);
	}

	return (
		<Select
			{...selectConfig}
			noOptionsMessage={() => Languages.getTranslation('not-find-variant', true)}
			filterOption={filterOptionsProduct}
			styles={customStylesProduct}
			isDisabled={isDisabled}
			tabIndex={isDisabled ? -1 : 0}
			name={CONSTANTS.detailFieldNames.PRODUCT_ID}
			value={selectedProduct}
			onChange={onChangeHandler}
			options={optionsProduct}/>
	)
}

export default memo(Product);