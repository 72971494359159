import {Tooltip} from "react-tooltip";
import {useSelector} from "react-redux";
import {projectSelectors} from "redux/project";
import {TooltipPortal} from "../../../TooltipPortal";
import {memo} from "react";
import ProductHelper from "../../../Modal/ProductHelper";

const Product = ({detailId, productId}) => {
  const construction = useSelector(projectSelectors.getConstruction);

    return (
        <>
            <div className="pointer" data-tooltip-id={`productHelper-tooltip-${detailId}`}>
                {construction.products.findIndex(el => el.id === productId) + 1}
            </div>
            <TooltipPortal>
                <Tooltip
                    key={`productHelper-${detailId}`}
                    className='productHelper-tooltip'
                    id={`productHelper-tooltip-${detailId}`}
                    place="bottom-start"
                    variant='light'
                    zIndex={1000}
                >
                    <ProductHelper productId={productId}/>
                </Tooltip>
            </TooltipPortal>
        </>
    )
}

export default memo(Product);