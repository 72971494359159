import React  from "react";
import {useSelector, useDispatch} from "react-redux";
import {constructor3dSelectors, constructor3dOperations} from "../../../../redux/constructor";

import ControlButton from "../../components/ControlButton";
import './style.scss';



export default function TopPanel() {
	const dispatch = useDispatch();
	const leftPanelShowed = useSelector(constructor3dSelectors.leftPanelOpened)
	const rightPanelOpened = useSelector(constructor3dSelectors.rightPanelOpened)
  const elements = useSelector(constructor3dSelectors.getTopPanelItems);

	const getClassName = () => {
		return `top-panel ${!leftPanelShowed ? 'left-hidden' : ''} ${!rightPanelOpened ? 'right-hidden' : ''}`
	}

	const getItems = () => {
		return (
			<>
				{elements.map((el, i) => <ControlButton button={el} key={i} />)}
			</>
		)
	}

	return (
		<div className={getClassName()}>
			{getItems()}
		</div>
	)
}