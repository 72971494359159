import Languages from "../../../../translation/Languages";
import {empty, validationInput} from "../../../../helpers/helper";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {modalsOperations, modalsSelectors} from "redux/modals";
import './style.scss'
import {projectSelectors} from "redux/project";
import {errorMessageOperations} from '../../../../redux/errors'
import CloseBtn from "../../Buttons/CloseBtn";
import ReplaceEdgesProjectItem1 from "../ReplaceEdgesProject/ReplaceEdgesProjectItem1";
import ReplaceEdgesProjectItem2 from "../ReplaceEdgesProject/ReplaceEdgesProjectItem2";
import logo from "../../../../media/triangle.png";
import DefaultBtn from "../../Buttons/DefaultBtn";
import Requests from "../../../../api/API";

const ReplaceEdge = () => {
	const selectedDetails = useSelector(modalsSelectors.getModalIsEdit);
	const dispatch = useDispatch()
	const construction = useSelector(projectSelectors.getConstruction);
	const [searchedEdge, setSearchedEdge] = useState([]);
	const [projectEdges, setProjectEdges] = useState([]);
	const [code, setCode] = useState('');
	const [selectEdge, setSelectEdge] = useState([]);
	const [selectEdgeSecond, setSelectEdgeSecond] = useState([]);

	useEffect(() => {
		setProjectEdges([...construction.edges])
		setSelectEdge([construction.edges[0]])
		setSelectEdgeSecond([construction.edges[0]])
	}, []);

	useEffect(() => {
		if (projectEdges.length) {
			setProjectEdges([...construction.edges])
		}
	}, [projectEdges.length])

	const search = async (code) => {
		const responseType = "Band";
		await setCode(code);
		if (code.length >= 5) {
			const request = await Requests.Materials.getMaterialCurrentVendorCode(code, responseType);

			if (!empty(request[0])) {
				setSearchedEdge([]);
				if (request[0].responsetype === 'Band' || request[0].responsetype === 'band') {
					setSearchedEdge([request[0]]);
				}
			}else {
				setSearchedEdge([]);

			}
		}
	}

	const selectEdgeHandler = (edge) => {
		setSelectEdge([edge])
	}
	const selectEdgeHandlerSecond = (edge) => {
		setSelectEdgeSecond([edge])
	}

	const close = () => {
		dispatch(
			modalsOperations.switchStateForModal({
				show: false,
				type: null,
			})
		);
	};

	const submit = (type) => {
		if (type === 'replaceWithProjectEdges') {
			if (selectEdge[0].article === selectEdgeSecond[0].article) {
				dispatch(errorMessageOperations.switchStateError({ message: Languages.getTranslation('same-edges', true), type: 'warning' }))
				return
			}

			construction.updateDetailsEdges(selectEdge[0], selectEdgeSecond[0], selectedDetails)
				.then(() => {
					setProjectEdges([...construction.edges])
					setSearchedEdge([])
					dispatch(errorMessageOperations.switchStateError({
						message: Languages.getTranslation('edges-replace-success', true),
						type: 'success', show: true
					}))
				})
				.catch((e) => {
					console.log(e.message)
					dispatch(errorMessageOperations.switchStateError({message: e.message, type: 'error', show: true}))
				})
			return
		}

		if (selectEdge[0].article === searchedEdge[0].article) {
			dispatch(errorMessageOperations.switchStateError({ message: Languages.getTranslation('same-edges', true), type: 'warning' }))
			return
		}

		const addedEdges = searchedEdge.filter(
			(el) => !construction.edges.find((el1) => el1.article === el.article)
		);
		construction.setProjectEdges(addedEdges).then(() => {
			const neededEdge = construction.edges.find((edge) => edge.article === code)
			construction.updateDetailsEdges(selectEdge[0], neededEdge, selectedDetails)
		}).then(() => {
			setProjectEdges([...construction.edges])
			setSearchedEdge([])
			dispatch(errorMessageOperations.switchStateError({
				message: Languages.getTranslation('edges-replace-success', true),
				type: 'success', show: true
			}))
		})
			.catch((e) => {
				console.log(e.message)
				dispatch(errorMessageOperations.switchStateError({message: e.message, type: 'error', show: true}))
			})
	}

	const createButtonForReplaceEdge = (flag) => {
		if (selectedDetails.some(detail => detail.mills.some(el => ['partial', 'closed'].includes(el.type)))) {
			const firstEdgeThicknessForReplace = selectEdge[0]?.thickness
			const secondEdgeThicknessForReplace = flag === 'default' ? selectEdgeSecond[0]?.thickness : searchedEdge[0]?.thickness
			if ( firstEdgeThicknessForReplace === secondEdgeThicknessForReplace) {
				return <div className='replaceEdges__btn-container'>
					<DefaultBtn title={Languages.getTranslation('replace-edge', true)}
											handler={() => submit(flag === 'default' ? 'replaceWithProjectEdges' : 'replaceWithSearchedEdge')}
					/>
				</div>

			} else {
				return <div className='replaceEdges__warning-container'>
					<span className='errorOnSearch'>{Languages.getTranslation('warning-replace-edge-in-project', true)}</span>
				</div>
			}

		} else {
			return <div className='replaceEdges__btn-container'>
				<DefaultBtn title={Languages.getTranslation('replace-edge', true)}
										handler={() => submit(flag === 'default' ? 'replaceWithProjectEdges' : 'replaceWithSearchedEdge')}
				/>
			</div>
		}
	}

	const disabled = empty(searchedEdge)

	return (
		<div className='replaceMaterialProject'>
			{<>
				<div className="replaceMaterialProject__header">
			<span
				className="replaceMaterialProject__title">{Languages.getTranslation('edge-to-detail', true)}</span>
					<CloseBtn handler={close}/>
				</div>
				<div className="replaceMaterialProject__table-header">
					<div className="replaceMaterialProject__content-container">
						<div
							className="replaceMaterialProject__table-header-name replaceMaterialProject__name">{Languages.getTranslation('name', true)}</div>
					</div>
					<div className="replaceMaterialProject__data-container">
						<div className="replaceMaterialProject__data-item">
							<span className="replaceMaterialProject__data-header">{Languages.getTranslation('article', true)}</span>
						</div>
						<div className="replaceMaterialProject__data-item">
							<span className="replaceMaterialProject__data-header">{Languages.getTranslation('width', true)}</span>
						</div>
						<div className="replaceMaterialProject__data-item">
							<span className="replaceMaterialProject__data-header">{Languages.getTranslation('thickness', true)}</span>
						</div>
					</div>
					<div className='replaceMaterialProject__table-header_invincible'></div>
				</div>

				<div className="replaceMaterialProject__chooseMaterialProject">
					<div className="replaceMaterialProject__chooseMaterialProject-list">
						{!empty(projectEdges) ? projectEdges.map((edge, idx) => <ReplaceEdgesProjectItem1 edge={edge} idx={idx}
								key={idx} selectEdge={selectEdge} selectEdgeHandler={selectEdgeHandler}/>) :
							<div className='replaceEdgesProject__edges-none'>{Languages.getTranslation('edges-none', true)}</div>}
					</div>
				</div>
				<div className="replaceMaterialProject__replaceMaterial-container replaceEdgesProject__replaceContainer">
					<div className="replaceMaterialProject__replaceMaterial-header">
						<span>{Languages.getTranslation('choose-edges-for-replace', true)}</span>
					</div>
					<div className="replaceMaterialProject__kronasMaterials">
						<div className="replaceMaterialProject__chooseMaterialProject-list">
							{!empty(projectEdges) ? projectEdges.map((edge, idx) => <ReplaceEdgesProjectItem1 edge={edge} idx={idx}
									key={idx} selectEdge={selectEdgeSecond} selectEdgeHandler={selectEdgeHandlerSecond}/>) :
								<div className='replaceEdgesProject__edges-none'>{Languages.getTranslation('edges-none', true)}</div>}
						</div>
						{createButtonForReplaceEdge('default')}
						<div className="replaceMaterialProject__kronasMaterials-header">
							<span className="replaceMaterialProject__title">{Languages.getTranslation('choose-edges-for-replace-kronas', true)}</span>
							<div className="replaceMaterialProject__chooseMaterialProject">

							</div>
							<div className="addMaterial__header-searchField">
								<i className="addMaterial__input-icon"></i>
								<input
									className="addMaterial__input"
									type="text"
									placeholder={Languages.getTranslation("add-article-edge", true)}
									onInput={(e) => validationInput(e)}
									onKeyUp={(e) => search(e.target.value)}
								/>
							</div>
						</div>
						<div className="replaceMaterialProject__kronasMaterials-materialsList">
							{!empty(searchedEdge) ? searchedEdge.map((edge, idx) => <ReplaceEdgesProjectItem2 edge={edge} idx={idx}
									key={idx}/>) :
								<div className='replaceEdgesProject__edges-none'>{Languages.getTranslation('edges-none', true)}</div>}
						</div>
						<div className="replaceMaterialProject__kronasMaterials-footer replaceEdgesProject__footer">
							{!empty(searchedEdge) ? createButtonForReplaceEdge('search') : <></>}
						</div>
					</div>
				</div>
			</>}
		</div>
	);
}

export default ReplaceEdge