import {useEffect} from "react";

export const useUpdateVirtualizedTablePosition = (windowScrollerRef) => {

    useEffect(() => {
        const ro = new ResizeObserver(entries => {
            if (windowScrollerRef.current) {
                windowScrollerRef.current.updatePosition();
            }
        });

        ro.observe(document.body);

        return () => {
            ro.disconnect();
        }
    }, [windowScrollerRef.current]);

}