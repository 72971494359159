import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useSelector} from "react-redux";
import "./index.scss";
import {empty, sortDetailsByOrder} from "../../../../helpers/helper";
import {modalsSelectors} from "redux/modals";
import DefaultHeader from "./DefaultView/Header";
import EditHeader from "./EditView/Header";
import {WindowScroller, AutoSizer} from 'react-virtualized';
import 'react-virtualized/styles.css';
import {atLeastOneHasErrors, sortByPropertyDesc} from "./helper";
import DefaultTable from "./DefaultView/Table";
import EditTable from "./EditView/Table";
import {useProjectTableContext} from "../../../../hooks/useContexts";
import {useUpdateVirtualizedTablePosition} from "../../../../hooks/useUpdateVirtualizedTablePosition";

const TableDetails = ({
                          isDetailsModeEqualsList,
                          selectedDetails,
                          setSelectedDetails,
                          setDetails,
                          details,
                          setDeleteDetail,
                          filteredActive
                      }) => {
    const modalState = useSelector(modalsSelectors.getModalShowState);
    const [isOrderConform, setIsOrderConform] = useState(false);
    const {rerenderTable} = useProjectTableContext();
    const windowScrollerRef = useRef(null);
    useUpdateVirtualizedTablePosition(windowScrollerRef);
		const hasErrors = atLeastOneHasErrors(details);
    const isAllDetailsSelected = useMemo(() => selectedDetails.length !== 0 && selectedDetails.length === details.length, [selectedDetails, details]);

    const selectDetail = useCallback((detail) => {
        setSelectedDetails((prevSelectedDetails) => {
            if (prevSelectedDetails.includes(detail)) {
                return prevSelectedDetails.filter((el) => el._id !== detail._id);
            } else {
                return [...prevSelectedDetails, detail];
            }
        })
    }, [setSelectedDetails]);

    const setAllDetails = useCallback((event) => {
        if (event.target.checked) {
            setSelectedDetails([...details]);
        } else {
            setSelectedDetails([]);
        }
    }, [setSelectedDetails, details]);

    const remove = useCallback((detail) => {
        setDeleteDetail(detail)
    }, [setDeleteDetail]);

    const sortDetails = (property) => {
        setDetails((prev) => property
                ? sortByPropertyDesc(prev, property)
                : prev.sort(sortDetailsByOrder)
        )
        rerenderTable();
    };

    useEffect(() => {
        if (modalState) {
            setSelectedDetails([]);
        }

        let orderSet = Array(details.length).fill(0);
        let maxOrder = 0;
        details.forEach((d, index) => {
            if (empty(d.order)) {
                d.order = index + 1;
            }
            if (orderSet[d.order - 1] !== 0) {
                d.order = orderSet.lastIndexOf(0) + 1;
            }
            orderSet[d.order - 1] = 1;
            maxOrder = Math.max(maxOrder, d.order);
        });
        setDetails(details.sort(sortDetailsByOrder));
        setIsOrderConform(true);
    }, [modalState]);

    return (
        <div className="detailsTab__wrapper">
            <div className="detailsTab">
                {isDetailsModeEqualsList ?
                    <DefaultHeader
                        hasErrors={hasErrors}
                        isAllDetailsSelected={isAllDetailsSelected}
                        sortDetails={sortDetails}
                        setAllDetails={setAllDetails}
                        filteredActive={filteredActive}
                    /> :
                    <EditHeader
                        isAllDetailsSelected={isAllDetailsSelected}
                        setAllDetails={setAllDetails}
												filteredActive={filteredActive}
                    />
                }
                <div className="detailsTab__body">
                    {isOrderConform ? (
                        <WindowScroller
                            ref={windowScrollerRef}
                            scrollElement={window}>
                            {({height, isScrolling, registerChild, onChildScroll, scrollTop}) => (
                                <div style={{flex: "1 1 auto"}}>
                                    <AutoSizer disableHeight>
                                        {({width}) => (
                                                <>{
                                                    isDetailsModeEqualsList ?
                                                        <DefaultTable
																														hasErrors={hasErrors}
                                                            selectedDetails={selectedDetails}
                                                            remove={remove}
                                                            selectDetail={selectDetail}
                                                            width={width}
                                                            details={details}
                                                            setDetails={setDetails}
                                                            height={height}
                                                            isScrolling={isScrolling}
                                                            onChildScroll={onChildScroll}
                                                            scrollTop={scrollTop}
                                                        /> :
                                                        <EditTable
                                                            ref={registerChild}
                                                            selectedDetails={selectedDetails}
                                                            remove={remove}
                                                            selectDetail={selectDetail}
                                                            width={width}
                                                            details={details}
                                                            setDetails={setDetails}
                                                            height={height}
                                                            isScrolling={isScrolling}
                                                            onChildScroll={onChildScroll}
                                                            scrollTop={scrollTop}
                                                        />
                                                }
                                            </>
                                        )}
                                    </AutoSizer>
                                </div>
                            )}
                        </WindowScroller>
                    ) : (
                        <div></div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TableDetails;
