import RoundCheckBox from "components/UI/Inputs/Input/RoundCheckBox";
import img from "../../../../../media/material-add-img-test.svg";
import Languages from "../../../../../translation/Languages";
import {materialTitle, imgLink} from "../../../../../helpers/helper";
import MaterialEdgeImg from "../../../../CreateProject/Materials/MaterialEdgeImg/MaterialEdgeImg";
import React from "react";

const changeTitle = (title, material) =>
  title
    .split("(LuxeForm)")
    .join("")
    .split(" ")
    .filter((el) => !el.includes(material.l))
    .join(" ");

const MaterialItem = ({
  material,
  index,
  setSelectedMaterial,
  selectedMaterial,
}) => {
  const setMaterial = (material) => {
    setSelectedMaterial(material);
  };

  return (
    <div className="quickInputTable__info-body-item">
      <RoundCheckBox
        value={material}
        setMaterial={setMaterial}
        selectedMaterial={[selectedMaterial]}
      />
      <div className="quickInputTable__info-body-item-number">{index + 1}</div>
        <MaterialEdgeImg item={material} />

        <span className="quickInputTable__info-body-item-name">
        {materialTitle(material)}
      </span>
    </div>
  );
};

export default MaterialItem;
