import {createContext, useCallback, useMemo, useRef} from "react";

export const TableRefContext = createContext(null);

export const TableRefContextProvider = ({children}) => {
    const tableRef = useRef(null);

    const rerenderTable = useCallback(() => {
        if (tableRef.current) {
            tableRef.current.forceUpdateGrid();
        }
    }, []);

    const value = useMemo(() => ({
        rerenderTable,
        tableRef
    }), [rerenderTable])

    return <TableRefContext.Provider value={value}>{children}</TableRefContext.Provider>
}