import Base from "./Base";

export default class Buttons extends Base {

	constructor() {
		super();
	}

	changeRenderType(type) {

	}

	changeDetailsView(type, value) {
		this.getState('cDetails').forEach(detail => {
			detail.changeView(value, type)
		})
	}

	toggleHoles() {
		const processing = this.getState('showProcessing')
		const current = processing?.hole
		this.updateState({type: 'SET_SHOWED_PROCESSING', payload: {hole: !current}})
		this.changeDetailsView('hole', !current)
	}

	toggleSaw() {
		const processing = this.getState('showProcessing')
		const current = processing?.saw
		this.updateState({type: 'SET_SHOWED_PROCESSING', payload: {saw: !current}})
		this.changeDetailsView('saw', !current)
	}

	renderType2() {
		this.updateState({type: 'SET_RENDER_TYPE', payload: 2})
		this.getState('cDetails').forEach(detail => detail.renderType = 2)
		this.getState('scene').sceneClass.render(true)
	}

	renderType1() {
		this.updateState({type: 'SET_RENDER_TYPE', payload: 1})
		this.getState('cDetails').forEach(detail => detail.renderType = 1)
		this.getState('scene').sceneClass.render(true)
	}
}