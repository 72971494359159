import Detail from "./Detail";
import {List} from "react-virtualized";
import {forwardRef, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {validateDetailInEditDetails} from "../../../../../helpers/helper";
import {errorMessageOperations} from "redux/errors";
import Languages from "../../../../../translation/Languages";
import {useDispatch, useSelector} from "react-redux";
import {projectOperations, projectSelectors} from "redux/project";
import CONSTANTS from "../../../../../config/constants";
import {useProjectTableContext} from "../../../../../hooks/useContexts";
import debounce from "lodash.debounce";
import isEqual from "lodash.isequal";
import {toastWarning} from "../../../../../helpers/toasts";
import {
	getFocusableFieldsByParentEl,
	selectFirstAvaliableDetailField,
	selectField,
	disableByMaterialType
} from "../helper";

const createDefaultStateOfWarnings = {
    [CONSTANTS.detailFieldNames.WIDTH]: false,
    [CONSTANTS.detailFieldNames.HEIGHT]: false,
    [CONSTANTS.detailFieldNames.COUNT]: false,
    [CONSTANTS.detailFieldNames.NAME]: false
};
const keysWhichNecessaryHandle = [CONSTANTS.keyCodes.top, CONSTANTS.keyCodes.bottom, CONSTANTS.keyCodes.enter, CONSTANTS.keyCodes.right, CONSTANTS.keyCodes.left];
const essentialInputFields = [CONSTANTS.detailFieldNames.NAME, CONSTANTS.detailFieldNames.COUNT, CONSTANTS.detailFieldNames.HEIGHT, CONSTANTS.detailFieldNames.WIDTH];

const Table = forwardRef(({
                   selectDetail,
                   selectedDetails,
                   remove,
                   details,
                   height,
                   setDetails,
                   isScrolling,
                   onChildScroll,
                   scrollTop,
                   width
               }, ref) => {
    const localListRef = useRef(null);
    const construction = useSelector(projectSelectors.getConstruction);
    const detailsTableConfig = useSelector(projectSelectors.getDetailsTableConfig);
    const dispatch = useDispatch();
    const {tableRef} = useProjectTableContext();
    const detailsCount = details.length;
    const [warningState, setWarningState] = useState(() => details.reduce((acc, _, i) => ({...acc, [i]: createDefaultStateOfWarnings}), {}));

    const updateSizeWarning = useCallback((index, name, value) => {
        setWarningState((prev) => ({...prev, [index]: {...prev[index], [name]: value}}));
    },[setWarningState]);

    const updateDetail = useCallback((detail, detailProperty, newValue) => {
        detail.updateDetail(detailProperty, newValue)
            .then(() => {
                dispatch(projectOperations.updateDetailInRedux(detail));
                setDetails((prev) => prev.map((d) => d.id === detail.id ? detail : d))
            })
            .catch(error => console.log(error));
    }, [setDetails, dispatch]);

    const changeInputType = useCallback((detail, name, value) => {
        if (name === CONSTANTS.detailFieldNames.NAME) {
					detail[name] = value;
        }

        if ([CONSTANTS.detailFieldNames.HEIGHT, CONSTANTS.detailFieldNames.WIDTH].includes(name)) {
						detail[`error_${name}`] = false

            const neededMaterial = construction.materials.find(el => el.index === detail.material);
            let gap = 0;

            const isMaterialTypeForBothWidthAndHeight = ["ЛДСП", "Compact-плита", "HDF", "МДФ", "OSB"].includes(neededMaterial.type);
            const isMaterialTypeForOnlyWidth = ['Постформинг', 'Постформінг', 'Стеновая панель'].includes(neededMaterial.type);
            const hasWidthField = [CONSTANTS.detailFieldNames.WIDTH].includes(name);
            const hasSawdust = neededMaterial.sawdust;

            if (isMaterialTypeForBothWidthAndHeight && hasSawdust) {
                gap = 26;
            }

            if (isMaterialTypeForOnlyWidth && hasWidthField && hasSawdust) {
                gap = 26;
            }

            const tempMat = {...neededMaterial}
            const convertedNameForValidation = name === CONSTANTS.detailFieldNames.HEIGHT ? "height": "width";

            if (validateDetailInEditDetails(value, tempMat, convertedNameForValidation, gap)) {
								detail[`error_${name}`] = true;
								detail[name] = value !== '' ? Number(value) : value;
                const message = gap ? `${Languages.getTranslation('same-sides-detail-material', true)} ${gap} ${Languages.getTranslation('same-sides-detail-material-text-two')}` : `${Languages.getTranslation('error-detail-params', true)}`;

                dispatch(errorMessageOperations.switchStateError({
                    message,
                    type: 'warning',
                    show: true
                }))
            } else {
								detail[name] = value !== '' ? Number(value) : value;
								detail[`error_${name}`] = Number(value) === 0;
            }
            value = detail[name];
        }

        if (name === CONSTANTS.detailFieldNames.COUNT) {
						detail[name] = Number(value) === 0 || value === '' ? 1 : Number(value);
            value = detail[name]
        }
				
				if ([CONSTANTS.detailFieldNames.TOP, CONSTANTS.detailFieldNames.BOTTOM, CONSTANTS.detailFieldNames.LEFT,CONSTANTS.detailFieldNames.RIGHT].includes(name)) {
					if (detail.edges.hasOwnProperty(name)) {
						detail.edges[name] = value;
					} else {
						detail.edges = {...detail.edges, [name]: value};
					}
					
					updateDetail(detail, 'edges', detail.edges);
					return;
				}

        updateDetail(detail, name, value);
    }, [dispatch, construction.materials, updateDetail]);

    const changeInputTypeDB = useMemo(() => debounce(changeInputType, CONSTANTS.debounceTimeDetailInput), []);

		const resetEdges = useCallback((detail, value) => {
			const neededMaterial = construction.materials.find(el => el.index === value);
			const detailMaterialType = neededMaterial.type;
			const uniquePFConditionU1 = ['Постформінг', 'Постформинг'].includes(detailMaterialType) && !detail.soft.top;
			const uniquePFConditionU2 = ['Постформінг', 'Постформинг'].includes(detailMaterialType) && neededMaterial.double_rounding && !detail.soft.bottom;
			
			if (disableByMaterialType(detailMaterialType)) {
				detail.edges = {
					top: CONSTANTS.defaultEdgeValueInDB,
					bottom: CONSTANTS.defaultEdgeValueInDB,
					left: CONSTANTS.defaultEdgeValueInDB,
					right: CONSTANTS.defaultEdgeValueInDB
				};
				updateDetail(detail, 'edges', detail.edges);
			}
			
			if (uniquePFConditionU1) {
				detail.edges = {...detail.edges, top: CONSTANTS.defaultEdgeValueInDB};
				updateDetail(detail, 'edges', detail.edges);
			}
			
			if (uniquePFConditionU2) {
				detail.edges = {...detail.edges, top: CONSTANTS.defaultEdgeValueInDB, bottom: CONSTANTS.defaultEdgeValueInDB};
				updateDetail(detail, 'edges', detail.edges);
			}
	}, [updateDetail, construction.materials]);

    const selectTexture = useCallback((detail, value) => {
        const neededMaterial = construction.materials.find(el => el.index === value);

        if (['Постформінг', 'Постформинг'].includes(neededMaterial.type)) {
						detail.isRotateTexture = true;

            updateDetail(detail, 'isRotateTexture', true);
        }
    }, [updateDetail, construction.materials]);

    const changeSelectType = useCallback((detail, event) => {
        let {value, name} = event;
				
				if (name === CONSTANTS.detailFieldNames.MATERIAL) {
						selectTexture(detail, value);
						resetEdges(detail, value);
				}
			
				detail[name] = value;

				updateDetail(detail, name, value);
    }, [updateDetail, selectTexture]);

    const handlerSetFocused = useCallback((meta) => {
        if (!isEqual(detailsTableConfig.focusedRow, meta)) {
            dispatch(projectOperations.setDetailsTableFocusedRow(meta));
        }
    }, [dispatch, detailsTableConfig.focusedRow]);

    const scrollToSelectedDetail = (detailNode) => {
        if (detailNode) {
            detailNode.scrollIntoView({block: "center", behavior: "smooth", inline: "center"})
        }
    }

    const showError = useCallback(() => {
        const message = Languages.getTranslation('can\'t-be-empty', true);
        toastWarning(message, {
            onClose: () => {
            }
        })
    }, []);

    const focusByClickDetail = (event) => {
				const clickedElement = event.target.hasAttribute("tabindex") ? event.target : event.target.querySelector('[tabindex]');

				if (clickedElement) {
					const detailElement = clickedElement.closest('.detailsTab__body-item');
					const currentDetailIndex = +detailElement.dataset.index;
					const focusableFields = getFocusableFieldsByParentEl(detailElement);
					const currentFieldIndex = focusableFields.indexOf(clickedElement);
					selectField(clickedElement);
					handlerSetFocused({detailIndex: currentDetailIndex, fieldIndex: currentFieldIndex})
					scrollToSelectedDetail(detailElement);
			}
		}
		
    const focusByKeyDownDetail = (event) => {
        if (!keysWhichNecessaryHandle.includes(event.keyCode)) {
            return;
        }
        const clickedElement = event.target;
        const detailElement = clickedElement.closest('.detailsTab__body-item');
        const currentDetailIndex = +detailElement.dataset.index;
        const nextDetailIndex = CONSTANTS.keyCodes.top === event.keyCode ? currentDetailIndex - 1 : currentDetailIndex + 1;
        const focusableFields = getFocusableFieldsByParentEl(detailElement);
        const currentFieldIndex = focusableFields.indexOf(clickedElement);
				const clickedElementId = clickedElement.getAttribute('id');

        const isNotSelect = !(!!clickedElementId && clickedElementId.includes("react-select"));
			
				const handleVerticalNavigation = (currentDetailIndex, currentFieldIndex, detailElement, direction) => {
					const nextDetailElement =
						direction === "up"
							? detailElement.previousSibling
							: detailElement.nextSibling;
					
					const focusableFields = getFocusableFieldsByParentEl(nextDetailElement);
					const nextNode = focusableFields[currentFieldIndex];
					
					if (nextNode) {
						handlerSetFocused({ detailIndex: nextDetailIndex, fieldIndex: currentFieldIndex });
						selectField(nextNode);
					}
				};
			
        if (CONSTANTS.keyCodes.top === event.keyCode && isNotSelect) {
					handleVerticalNavigation(currentDetailIndex, currentFieldIndex, detailElement, "up");
        }

        if (CONSTANTS.keyCodes.bottom === event.keyCode && isNotSelect) {
					handleVerticalNavigation(currentDetailIndex, currentFieldIndex, detailElement, "down");
        }

        if (clickedElement.tagName === "INPUT" && essentialInputFields.includes(clickedElement.name) && clickedElement.value === CONSTANTS.emptyString && isNotSelect) {
            showError();
            updateSizeWarning(currentDetailIndex, clickedElement.name, true);
        }

        const nextFieldIndex = CONSTANTS.keyCodes.left === event.keyCode ? currentFieldIndex - 1 : currentFieldIndex + 1;
        const nextField = focusableFields[nextFieldIndex];
			
        if ([CONSTANTS.keyCodes.enter, CONSTANTS.keyCodes.right].includes(event.keyCode)) {
            if (nextField) {
                handlerSetFocused({detailIndex: currentDetailIndex, fieldIndex: nextFieldIndex});
                selectField(nextField);
            } else if (currentDetailIndex < detailsCount - 1) {
                const nextDetailFocusableFields = getFocusableFieldsByParentEl(detailElement.nextSibling);
                handlerSetFocused({detailIndex: nextDetailIndex, fieldIndex: CONSTANTS.firstFieldDetailIndex});
                selectField(nextDetailFocusableFields[CONSTANTS.firstFieldDetailIndex]);
            }
        }

        if (event.keyCode === CONSTANTS.keyCodes.left && nextField) {
            handlerSetFocused({detailIndex: currentDetailIndex, fieldIndex: nextFieldIndex});
            selectField(nextField);
        }

        scrollToSelectedDetail(detailElement);
    }

    const rowEditRenderer = ({index, style}) => {
				if (details.length === 0) {
					return (
						<div key="empty-project" style={style} className="details__empty-project">{Languages.getTranslation("empty-project", true)}</div>
					);
				}
				
        const detail = details[index];

        return (
            <Detail
                style={style}
                editLimits={detailsTableConfig.limits}
                detail={detail}
                index={index}
                key={detail.id}
                updateSizeWarning={updateSizeWarning}
                warningState={warningState[index]}
                selectDetailHandler={selectDetail}
                construction={construction}
                isSelected={selectedDetails.includes(detail)}
                isFocused={detailsTableConfig.focusedRow.detailIndex === index}
                changeInputType={changeInputTypeDB}
                changeSelectType={changeSelectType}
                remove={remove}
            />)
    };

    useEffect(() => {
        setWarningState((prev) => ({...prev, [detailsCount - 1]: createDefaultStateOfWarnings}));
    }, [detailsCount]);

    useEffect(() => {
        if (localListRef.current && details.length > 0) {
            selectFirstAvaliableDetailField(localListRef.current, detailsTableConfig.focusedRow);
        }
    }, [detailsTableConfig.limits.onlyEdge, detailsTableConfig.limits.onlyFirstGroup]);

    useEffect(() => {
        return () => {
            changeInputTypeDB.cancel();

            dispatch(projectOperations.setDefaultDetailsTableFocusedRow());
        }
    }, []);

    return (
        <div ref={(node) => {
            localListRef.current = node;
            if (typeof ref === 'function') {
                ref(node);
            } else if (ref) {
                ref.current = node;
            }
        }} className="table-wrapper" onClick={focusByClickDetail} onKeyUp={focusByKeyDownDetail}>
            <List
                className="edit-details-table"
                ref={tableRef}
                details={details}
                autoHeight
                height={height}
                isScrolling={isScrolling}
                onScroll={onChildScroll}
                overscanRowCount={3}
                rowCount={details.length === 0 ? 1 : details.length}
                rowHeight={70}
                rowRenderer={rowEditRenderer}
                scrollTop={scrollTop}
                width={width}
            />
        </div>
    )
});

export default Table;