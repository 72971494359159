import Processing from "./3D/Processing";
import Languages from "../translation/Languages";
import Helpers from "./3D/Helpers";
import Sampling from "./CutOuts/Sampling";
import BasisCutOut from "./CutOuts/BasisCutOut";
import {empty} from "../helpers/helper";
import CompactLock from "./CutOuts/CompactLock";

/*
 * @class CutOut
 * @extends Processing
 * @param {Object} params
 * @param {Object} params.detail
 * @param {Array} params.elements
 * @param {Number} params.x
 */


export default class CutOut extends Processing {
	_elements = []
	_height = 0;
	_width = 0;
	_subType = 'cutOut';
	_x_axis = 'left';
	_y_axis = 'bottom';
	_type = '';
	_id;
	_edgeSide = 'left';
	_isActive = false;
	_mainElement
	compactLockType
	fullDepth = false
	constructor({
								detail,
								elements = [],
								x = 0,
								y = 0,
								depth = 6,
								height,
								width,
								id = null,
								side = 'front',
								edge = null,
								isErrorText = '',
								additionalInfo = '',
								dataForConstructor = {},
								type = "",
								edgeSide = 'left',
								compactLockType,
								comment = '',
		            isTemplate = null,
		            fullDepth = null
							}) {
		super({detail, x, y, side, edge, depth, comment, isErrorText, additionalInfo, id, isTemplate});
		this._elements = elements;
		this._edgeSide = edgeSide
		this._type = type
		this._dataForConstructor = dataForConstructor;
		this._height = height;
		this._width = width;
		this.compactLockType = compactLockType
		this._isErrorText = isErrorText;
		this.fullDepth = empty(fullDepth) ? depth === detail.w : fullDepth

		this.chooseMainElement()
		this.addParams();
	}

	chooseMainElement() {
		switch (this.type) {
			case "Sampling":
				this.mainElement = new Sampling(this)
				break
			case "compactLock":
				this.mainElement = new CompactLock(this)
				break
			default:
				this.mainElement = new BasisCutOut(this)
				break
		}
	}

	initRequiredFields () {
		this.mainElement.initRequiredFields()
	}

	checkRequiredFields() {
		for (const[field, value] of Object.entries(this.requiredFields)) {
			if(!this.checkIfFieldShown(field)) {
				delete this.errors[field];
				continue
			}
			if(empty(this.mainElement[field])) {
				this.errors[field] = 'empty';
			} else {
				if(!empty(value.min) && this.mainElement[field] < value.min) {
					this.errors[field] = 'value_min';
					continue;
				}
				if(!empty(value.max) && this.mainElement[field] > value.max) {
					this.errors[field] = 'value_max';
					continue;
				}
				delete this.errors[field];
			}
		}
	}

	addParams() {
		return this.mainElement.addParams()
	}

	get paramsSorting() {
		return this.mainElement.paramsSorting
	}

	calcSize() {
		let minX = 0, maxX = 0, minY = 0, maxY = 0;
		this.elements.forEach(element => {
			switch (element.type) {
				case 'line':
					minX = Math.min(minX, element.x1, element.x2)
					maxX = Math.max(maxX, element.x1, element.x2)
					minY = Math.min(minY, element.y1, element.y2)
					maxY = Math.max(maxY, element.y1, element.y2)
					break;
				case 'arc':
					minX = Math.min(minX, element.x1, element.x2, (element.xc - element.r))
					maxX = Math.max(maxX, element.x1, element.x2, (element.xc + element.r))
					minY = Math.min(minY, element.y1, element.y2, (element.yc - element.r))
					maxY = Math.max(maxY, element.y1, element.y2, (element.yc - element.r))
					break
			}
		})
		this.height = maxY - minY;
		this.width = maxX - minX;
	}

	get realData() {
		return {...this.mainElement.realData, isTemplate: this.isTemplate}
		// this.calcSize()
		// return {
		// 	height: this.height,
		// 	width: this.width,
		// 	elements: this.elements,
		// 	type: this.type,
		// 	x: this.x,
		// 	y: this.y,
		// 	edge: this.edge,
		// 	depth: this.depth,
		// 	comment: this.comment,
		// 	id: this.id,
		// 	side: this.side
		// }
	}

	get cutOut() {
		return this.mainElement.cutOut
		// return {
		// 	color: this.getMeshColor(),
		// 	height: this.height,
		// 	width: this.width,
		// 	elements: this.elements,
		// 	x: this.x,
		// 	y: this.y,
		// 	edge: this.edge,
		// 	depth: this.depth,
		// 	comment: this.comment,
		// 	id: this.id,
		// 	side: this.side
		// }
	}

	addSizesArrows() {
		return this.mainElement.addSizesArrows()
	}

	get subType() {
		return this._subType
	}

	get elements() {
		return this._elements;
	}

	set elements(value) {
		this._elements = value;
	}

	get height() {
		return this._height
	}

	set height(value) {
		this.mainElement.height = value;
	}

	get type() {
		return this._type;
	}

	set type(value) {
		this.mainElement.type = value;
	}

	set isActive(active) {
		this._isActive = active
		this.renderDetail()
	}

	get isActive() {
		return this._isActive;
	}

	get width() {
		return this._width;
	}

	set width(value) {
		this.mainElement.width = value;
	}

	set x(value) {
		this.mainElement.x = value;
	}

	set r1(value) {
		this.mainElement.r1 = value;
	}

	set r2(value) {
		this.mainElement.r2 = value;
	}

	set r3(value) {
		this.mainElement.r3 = value;
	}

	set r4(value) {
		this.mainElement.r4 = value;
	}

	set y(value) {
		this.mainElement.y = value;
	}

	set side(value) {
		this.mainElement.side = value;
	}


	get depth() {
		return this._depth
	}

	set depth(value) {
		this.mainElement.depth = value;
	}

	get x_axis() {
		return this.mainElement.x_axis
	}

	set x_axis(x_axis) {
		this._x_axis = x_axis;
		this.mainElement.x_axis = x_axis;
	}

	get y_axis() {
		return this.mainElement.y_axis
	}
	get x(){
		return this.mainElement.x
	}
	get y(){
		return this.mainElement.y
	}

	set y_axis(y_axis) {
		this._y_axis = y_axis;
		this.mainElement.y_axis = y_axis;
	}

	get mainElement() {
		return this._mainElement;
	}

	set mainElement(value) {
		this._mainElement = value;
	}

	calcX(x) {
		if (this.x_axis === "right") {
			return (this.detail.l - x - this.mainElement.width).toFixed(2)
		} else {
			return x.toFixed(2);
		}
	}

	calcY(y) {
		if (this.y_axis === "top") {
			return (this.detail.h - y - this.mainElement.height).toFixed(2);
		} else {
			return y.toFixed(2);
		}
	}

	rotateDetail(direction, l, h) {
		return this.mainElement.rotateDetail(direction, l, h)
	}

}