import imgYoutube from "../../media/youtube.svg";
import Languages from "../../translation/Languages";
import imgQuestion from "../../media/question.svg";
import {Link} from "react-router-dom";

import "./styles.scss";

const ResourceLinks = () => {
	return (
		<div className='resource-links'>
			<a className='resource-links__link-block' target='_blank' rel='noopener noreferrer'
				 href='https://www.youtube.com/watch?v=o8V28bwO4n0'>
				<img src={imgYoutube} alt="youtube" className='resource-links__icon'/>
				<span className='resource-links__text'>
        {Languages.getTranslation('learn-video', true)}
      </span>
			</a>
			<Link to="/support" className='resource-links__link-block'>
				<img src={imgQuestion} alt="question" className='resource-links__icon resource-links__icon--question'/>
				<span className='resource-links__text'>
        {Languages.getTranslation('rare-questions', true)}
      </span>
			</Link>
		</div>)
}

export default ResourceLinks;