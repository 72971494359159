import Languages from "../../../../../translation/Languages";
import { useEffect, useState} from "react";
import {scrollToTop} from "../helper";

const ScrollTopBtn = () => {
	const [showTopButton, setShowTopButton] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 100) {
				setShowTopButton(true);
			} else {
				setShowTopButton(false);
			}
		};

		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	if (!showTopButton) {
		return null;
	}

	return (
		<div className="button-top">
			<div className='button-top_block' onClick={scrollToTop}>
				<div className='button-top_block-angle'></div>
				<span>{Languages.getTranslation("to-the-top", true)}</span>
			</div>
		</div>
	)
};

export default ScrollTopBtn;