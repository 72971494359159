import {useEffect, useState} from "react";
import PrivateTemplate from "views/PrivateTemplate";
import ProcessType from "components/ProcessType";
import type1 from "media/img1-main-page.svg";
import type2 from "media/img2-main-page.svg";
import type3 from "media/warehouse.png";
import type4 from "media/transportCat.png";
import {useLocation, useNavigate} from "react-router-dom";
import Languages from "translation/Languages";
import "./style.scss";
import {panelOperations} from "redux/panel";
import {useDispatch, useSelector} from "react-redux";
import {empty} from "../../helpers/helper";
import Requests from "../../api/API";
import Errors from "../../models/Errors";
import { errorMessageOperations } from '../../redux/errors'
import {projectOperations, projectSelectors} from "redux/project";
const _errors = new Errors([])
import Loader from "../../components/UI/Loader";
import {modalsOperations} from "redux/modals";
import {userSelectors} from "redux/user";
import {useUpdateTranslations} from "../../hooks/useUpdateTranslations";
import ResourceLinks from "../../components/ResourceLinks";
import SaleInfo from "../../components/SaleInfo";
import CONSTANTS from "../../config/constants";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
const classImg = {width: '180px', height: '180px'}
const MainPage = (load) => {

  const construction = useSelector(projectSelectors.getConstruction);
  const query = useQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [isLoaded, setIsLoaded] = useState(null)
  const permissions = useSelector(userSelectors.permissions);
  useUpdateTranslations();

  useEffect(() => {

    if (load && load.loaded) {
      navigate("/details");
    }
    dispatch(
      panelOperations.switchStateForPanel({
        show: 'none'
      })
    )


  }, []);

  useEffect(() => {
    const currentMaterialId = query.get('materialId');
    const currentEdgeId = query.get('edgeId');
    const fetchMaterial = async() => {
      if(!empty(currentMaterialId)){
        setIsLoaded(true)

        const request = await Requests.Materials.getSearchMaterials(currentMaterialId).catch(error => {
          _errors.setError(error.message, 'error', true)
        });
        if (empty(request)) {
          dispatch(errorMessageOperations.switchStateError({
            message: Languages.getTranslation('material-search-none', true), type: 'warning', show: true
          }))
        }
        if (!empty(request) && !empty(construction)) {
          const filteredMaterials = request.filter(({ thickness, w, l, responsetype }) => {
            return !empty(thickness) && !empty(w) && !empty(l) && (responsetype === 'sheet' || responsetype === 'Sheet');
          });
          // if (request[0]?.responsetype === 'Sheet' || request[0].responsetype === 'sheet') {
            let equalMaterial = construction?.materials.filter(obj1 =>
                filteredMaterials.find(obj2 => obj1?.article === obj2?.article)
            );
            if(empty(equalMaterial)){
              dispatch(
                  modalsOperations.switchStateForModal({
                    show: true,
                    type: "ADD_MATERIAL",
                    isEdit: filteredMaterials,
                  })
              );
              dispatch(
                  projectOperations.setIsArrowMaterial({
                    IsArrowMaterial: true,
                  })
              );

            }else {
              dispatch(errorMessageOperations.switchStateError({
                message: Languages.getTranslation('material-already-have', true), type: 'warning', show: true
              }))
            }


          // }
        }
      }else if(!empty(currentEdgeId)){
        setIsLoaded(true)

        const request = await Requests.Materials.getEdgesCurrent(currentEdgeId).catch(error => {
          _errors.setError(error.message, 'error', true)
        });
        if (empty(request)) {
          dispatch(errorMessageOperations.switchStateError({
            message: Languages.getTranslation('edge-search-none', true), type: 'warning', show: true
          }))
        }
        const filteredEdges = request.data.filter(({ thickness, responsetype }) => {
          return !empty(thickness)  && (responsetype === 'band' || responsetype === 'Band');
        });
        if (!empty(filteredEdges) && !empty(construction)) {

          const addedEdges = filteredEdges .filter(
              (el) => !construction.edges.find((el1) => el1.article === el.article)
          );
          if(!empty(addedEdges)){
            await construction
                .setProjectEdges(addedEdges)
                .then(() => dispatch(errorMessageOperations.switchStateError({ message: Languages.getTranslation("edge-success", true), type: 'success', show: true }))
                )
                .then(() => setIsLoaded(false))
                .then(() => navigate("/details"))
            dispatch(
                projectOperations.setIsArrowEdge({
                  IsArrowEdge: true,
                })
            );
          }else {
            dispatch(errorMessageOperations.switchStateError({
              message: Languages.getTranslation('edge-already-have', true), type: 'warning', show: true
            }))
          }
        }
      }

    }
    fetchMaterial()

  }, [construction]);

  return (
    <PrivateTemplate>
      {!isLoaded ? (
      <div className="mainPage">
				<div className="mainPage__container">
        <h2 className="mainPage__title">
          {Languages.getTranslation("mainPage_title", true)}
        </h2>
        <div className="mainPage__block-type">
          <ProcessType
            type={"ADD_MATERIAL"}
            title={"processes-type_title"}
            img={type1}
          />
          <ProcessType
            type={"KRONAS_MATERIALS"}
            title={"processes-type_title-disabled"}
            img={type2}
          />
          <ProcessType
            type={"CUSTOMER_WAREHOUSE"}
            title={"customer-warehouse"}
            img={type3}
          />
          {permissions.includes("KM_TransportCut") && (
              <ProcessType
                  type={"TRANSPORT_CUT"}
                  title={"transport-cut"}
                  img={type4}
                  imgClass={classImg}
              />
          )}
        </div>
        <SaleInfo title={Languages.getTranslation("sale-november-2024-title")} conditions={CONSTANTS.saleNovember2024Conditions}/>
				<ResourceLinks/>
				</div>
      </div>
      ): <Loader />
      }

    </PrivateTemplate>
  );
};

export default MainPage;
