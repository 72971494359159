import './style.scss'
import DefaultBtn from "../../Buttons/DefaultBtn";
import Languages from "../../../../translation/Languages";
import CloseBtn from "../../Buttons/CloseBtn";
import {modalsOperations} from "redux/modals";
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import ProductsItem from "./ProductsItem";
import {projectOperations, projectSelectors} from "redux/project";
import {toastError, toastSuccess} from "../../../../helpers/toasts";
import {empty} from "../../../../helpers/helper";

const removeDuplicatesById = (arr) => arr.filter((obj1, i, arr) =>
	arr.findIndex(obj2 => (obj2.id === obj1.id)) === i
);

const Products = () => {
  const construction = useSelector(projectSelectors.getConstruction);
	const [products, setProducts] = useState(construction._products);
	const dispatch = useDispatch();
  const close = () => {
    dispatch(
      modalsOperations.switchStateForModal({
        show: false,
        type: null,
      })
    );
  };

  const deleteProductsHandler = (id) => {
    dispatch(
      modalsOperations.switchStateForModal({
        show: true,
        type: "DELETE_PRODUCT",
        isEdit: id,
      })
    );
  };

  const deleteProduct = (id, index) => {
    if (index === 0) {
			toastError(Languages.getTranslation('cant-delete-first-product', true));
   
			return;
    }
		
		const atLeastOneDetailHasProductForDeleting = construction.details.some(el => Number(el.productId) === id);
		
    if (atLeastOneDetailHasProductForDeleting) {
			deleteProductsHandler(id);
			
			return;
    }
		
    construction.deleteProduct(id)
      .then(() => {
        const filteredProducts = products.filter((product) => product.id !== id);
        setProducts(filteredProducts);
        dispatch(projectOperations.updateProducts(filteredProducts))
      })
      .then(() => toastSuccess(Languages.getTranslation('product-success-delete', true)))
      .catch(() => toastError(Languages.getTranslation('defaultError', true)))
  }

  const addProduct = () => {
    const product = {
      name: Languages.getTranslation('single-product', true),
    }
    construction.createProduct(product).then(() => {
			// newProducts must have unique products because during adding new product makes a copy for no one reason.
			// It connected to redux dispatch, but we cant remove it because of redux doesn't trigger useSelectors with products
			// Its because of changing redux state directly in construction.createProduct()
      const newProducts = removeDuplicatesById([...products, product]);
      setProducts(newProducts);
      dispatch(projectOperations.updateProducts(newProducts));
    })
  }

  const updateProduct = (id, field, value) => {
    construction.updateProduct(id, field, value)
  }

  return <div className='products'>
    <div className="products__header">
      <span>{Languages.getTranslation('all-products', true)}</span>
      <CloseBtn handler={close}/>
    </div>
    <div className="products__main">
      <div className="products__table">
        <div className="products__table-header">
          <div className="products__table-number products__table-headerStyle">№</div>
          <div
            className="products__table-name products__table-headerStyle">{Languages.getTranslation('product-name', true)}</div>
        </div>

        {empty(products) ? <div className="products__table-body products__addProduct">{Languages.getTranslation('need-add-product', true)}</div>
					: <div className="products__table-body">
						{products.map((product, index) =>
							<ProductsItem
								deleteProduct={deleteProduct}
								index={index}
								product={product}
								key={product.id}
								updateProduct={updateProduct}/>
						)}
        </div>
				}

      </div>
    </div>
    <div className="products__footer">
      <div className="products__footer-container">
        <DefaultBtn title={Languages.getTranslation('add-product', true)} handler={addProduct}/>
        <DefaultBtn title={Languages.getTranslation('save', true)} handler={close}/>
      </div>
    </div>
  </div>
};

export default Products;