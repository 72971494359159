import {CustomMenuPortal, CustomOption} from "./CustomSelectOption";
import CONSTANTS from "../../../../../config/constants";

const option = (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#f1e5ef" : null,
    color: '#451D54',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    opacity: state.isDisabled ? '0.7' : '1',
    cursor: state.isDisabled ? 'not-allowed' : 'default',
})

const menuList = (provided) => ({
    ...provided,
    padding: 0
});

const dropdownIndicator = (provided) => ({
    ...provided,
    borderTop: 'none',
    borderBottom: 'none',
    padding: 0,
});

const menuPortal = (provided) => ({
    ...provided,
    zIndex: 11
})

const container = (provided, state) => ({
    ...provided,
    pointerEvents: 'initial',
		outline: state.isFocused ? "2px solid #451d54" : null,
		borderRadius: state.isFocused ? "4px" : null,
});

const indicatorSeparator = (provided) => ({
    ...provided,
    display: 'none',
    width: 0
});

const input = (provided) => ({
    ...provided,
    width: 110,
    marginTop: 0,
    marginBottom: 0,
});

const control = (provided, state) => ({
    ...provided,
    border: 'none',
    backgroundColor: '#F0F0F0',
    outline: state.isFocused ? 'none' : provided.outline,
    boxShadow: state.isFocused ? 'none' : provided.boxShadow,
    cursor: state.isDisabled ? 'not-allowed' : 'default',
    height: 26,
    minHeight: 26,
});

const commonStyles = {
    container,
    menuPortal,
    indicatorSeparator,
    menuList,
    input,
}

export const customStylesMaterial = {
    ...commonStyles,
    option,
    control: (provided, state) => ({
        ...provided,
        ...control(provided, state),
        width: 126,
    }),
    menu: provided => ({
        ...provided,
        width: 450,
        marginTop: 0,
    }),
    dropdownIndicator
};

export const customStylesProduct = {
    ...commonStyles,
    option,
    control: (provided, state) => ({
        ...provided,
        ...control(provided, state),
        width: 273,
    }),
    menu: provided => ({
        ...provided,
        width: 273,
        marginTop: 0,
    }),
    dropdownIndicator
};


export const selectConfig = {
    menuPlacement: "auto",
    components: { Option: CustomOption, MenuPortal: CustomMenuPortal },
    openMenuOnFocus: true,
		className: CONSTANTS.reactSelectContainerClass,
    menuPortalTarget: document.querySelector("body")
};