import materialImg from "../../media/selectAction/material-to-detail.svg";
import productImg from "../../media/selectAction/change-product.svg";
import edgeImg from "../../media/selectAction/edge-to-detail.svg";
import deleteDetailsImg from "../../media/selectAction/delete-details.svg";
import textureImg from "../../media/selectAction/texture.svg";
import noTextureImg from "../../media/selectAction/noTexture.svg";
import cloneDetailImg from "../../media/selectAction/clone-detail.svg";
import countDetailsImg from "../../media/selectAction/count-details.svg";
import reverseHolesImg from "../../media/selectAction/reverse-holes.svg";
import groupChangeHoles from "../../media/selectAction/groupChangeHoles.svg";
import addSlidingSystem from "../../media/selectAction/addSlidingSystem.svg";
import removeSlidingSystem from "../../media/selectAction/removeSlidingSystem.svg";
import Languages from "translation/Languages";

export const generateDetailActions = (selectedDetails, permissions) => {
  return [
    {
      value: "selectMaterial",
      label: Languages.getTranslation("material-to-detail", true),
      img: materialImg,
      visible: true,
    },
    {
      value: "changeProductOnDetail",
      label: Languages.getTranslation("change-product-on-detail", true),
      img: productImg,
      visible: true,
    },
    {
      value: "selectEdge",
      label: Languages.getTranslation("edge-to-detail", true),
      img: edgeImg,
      visible: true,
    },
    {
      value: "removeDetails",
      label: Languages.getTranslation("delete-selected-details", true),
      img: deleteDetailsImg,
      visible: true,
    },
    {
      value: "selectTexture",
      label: Languages.getTranslation("select-texture-on-detail", true),
      img: textureImg,
      visible: true,
    },
    {
      value: "removeTexture",
      label: Languages.getTranslation("remove-texture-on-detail", true),
      img: noTextureImg,
      visible: true,
    },
    {
      value: "cloneDetail",
      label: Languages.getTranslation(
        `clone-detail${selectedDetails.length > 1 ? "s" : ""}`,
        true
      ),
      img: cloneDetailImg,
      visible: true,
    },
    {
      value: "updateCountDetails",
      label: Languages.getTranslation(`update-count-details`, true),
      img: countDetailsImg,
      visible: true,
    },
    {
      value: "transferToReverseSide",
      label: Languages.getTranslation(`reverse-holes-to-side`, true),
      img: reverseHolesImg,
      visible: true,
    },
    {
      value: "groupChangeDiamHoles",
      label: Languages.getTranslation(`group-change-diamHoles`, true),
      img: groupChangeHoles,
      visible: true,
    },
    {
      value: "addSlidingSystem",
      label: Languages.getTranslation(`add-sliding-system`, true),
      img: addSlidingSystem,
      visible: permissions.includes("KM_SlidingSystemAction"),
    },
    {
      value: "removeSlidingSystem",
      label: Languages.getTranslation(`remove-sliding-system`, true),
      img: removeSlidingSystem,
      visible: permissions.includes("KM_SlidingSystemAction"),
    },
  ];
};

export const chooseFormatByType = (type) => {
  const formats = {
      import_project_pro100: '.txt',
      import_project_voody: '.xml',
      import_project_3dConstructor: '.zip',
  };

  return formats[type] || '.kronas, .project, .json, .xml';
};

export const getModeClass = (condition) =>
	condition
		? "details__options-add_editMode__edges-mode details__options-add_editMode__edges-mode_active"
		: "quickInputTable__edges-mode";

export const getModeItemClass = (condition) =>
	condition
		? "details__options-add_editMode__edges-mode-item details__options-add_editMode__edges-mode-item_active"
		: "details__options-add_editMode__edges-mode-item";

export const parentStyles = {
	control: (provided, {isDisabled}) => ({
		...provided,
		cursor: isDisabled ? 'not-allowed' : 'default',
		backgroundColor: "#ffffff",
		opacity: isDisabled ? 0.7 : 1,
		border: 'none',
	}),
	placeholder: (provided, {isDisabled}) => ({
	...provided,
	fontSize: '16px',
	cursor: isDisabled ? 'not-allowed' : 'default',
	}),
	container: (provided) => ({
		...provided,
		pointerEvents: 'initial'
	})
};