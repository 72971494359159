import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import "./style.scss";
import Requests from "api/API";
import DefaultBtn from "../../Buttons/DefaultBtn";
import Languages from "../../../../translation/Languages";
import {errorMessageOperations} from '../../../../redux/errors'
import CustomSelect from "../../Select";
import Loader2 from "../../Loader2";
import warningImg from "../../../../media/ahtuung.png";

const JoinToSlidingSystemOrder = ({close, onContinue}) => {
	const dispatch = useDispatch();
	const [orders, setOrders] = useState([]);
	const [selectedOrder, setSelectedOrder] = useState(null);
	const [isOrdersLoading, setIsOrdersLoading] = useState(true);
	const [isError, setIsError] = useState(false);
	
	const chooseOrder = (option) => {
		setSelectedOrder(option.value);
	}
	
	useEffect(() => {
		const getOrders = async () => {
			const promises = [Requests.Orders.getSSActualOrders(), Requests.Orders.getSSFinalOrders()];
			
			Promise.all(promises).then((data) => {
				const joinedData = data.flat();
				
				if (joinedData && joinedData.length) {
					setOrders(joinedData.filter((order) => order.type === "SLIDING_SYSTEMS"));
				}
			}).catch((error) => {
				dispatch(errorMessageOperations.switchStateError(
					{message: error?.message, type: 'warn', show: true}));
				setIsError(true);
			}).finally(() => {
				setIsOrdersLoading(false);
			});
		};
		
		getOrders();
	}, []);
	
	const submit = () => {
		onContinue(selectedOrder);
		close();
	};
	
	return (
		<div className='modal'>
			<div className="join-to-ss-order-modal">
				{isOrdersLoading ? <Loader2/> : (
					<>
						{isError ?
							<div className="join-to-ss-order-modal__error">
								<div
									className="join-to-ss-order-modal__error-text">{Languages.getTranslation("service-doesnt-work", true)}</div>
								<DefaultBtn title={Languages.getTranslation('go-back', true)} handler={close}/>
							</div> :
							<>
								<div className="join-to-ss-order-modal__img">
									<img src={warningImg} width="88" height="70" alt="Warning image"/>
								</div>
								<h2
									className="join-to-ss-order-modal__title">{Languages.getTranslation('select-ss-order-title', true)}</h2>
								<p className="join-to-ss-order-modal__text">{Languages.getTranslation("join-ss-order-warning", true)}</p>
								<div className="join-to-ss-order-modal__body">
									<div className="join-to-ss-order-modal__form-control">
										<label
											className="join-to-ss-order-modal__label"
										>
											{Languages.getTranslation('select-ss-order', true)}
										</label>
										<CustomSelect
											options={orders.map((order) => ({value: order.number, label: order.number}))}
											handleChange={chooseOrder}
											placeholder=""
											noOptionsMessage={() => Languages.getTranslation("join-ss-order-no-orders", true)}
										/>
									</div>
								</div>
								<div className="join-to-ss-order-modal__btns">
									<DefaultBtn title={Languages.getTranslation('cancel', true)} handler={close}/>
									<DefaultBtn title={Languages.getTranslation('join-details-to-ss', true)} handler={submit}
															disabled={!selectedOrder}/>
								</div>
							</>}
					</>)}
			</div>
		</div>
	);
};

export default JoinToSlidingSystemOrder;
