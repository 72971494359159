import React, { useEffect, useRef, useState } from "react";
import Requests from "api/API";
import { useDispatch } from "react-redux";
import { modalsOperations, modalsSelectors } from "redux/modals";
import { useSelector } from "react-redux";
import { projectSelectors } from "redux/project";
import { useNavigate } from "react-router-dom";
import { empty, imgLink } from "../../../../helpers/helper";

import CloseBtn from "components/UI/Buttons/CloseBtn";
import Languages from "translation/Languages";
import DefaultBtn from "components/UI/Buttons/DefaultBtn";
import Checkbox from "components/UI/Inputs/Input/Checkbox";

import "./style.scss";
import img2 from "../../../../media/addEdge-test.png";
import Loader from "../../Loader";
import { errorMessageOperations } from '../../../../redux/errors'
import Errors from "../../../../models/Errors";
import img from "../../../../media/material-add-img-test.svg";
import MaterialEdgeImg from "../../../CreateProject/Materials/MaterialEdgeImg/MaterialEdgeImg";
const _errors = new Errors([])

const AddEdges = () => {
	const construction = useSelector(projectSelectors.getConstruction);
	const navigate = useNavigate();
	const [selectedMaterials, setSelectedMaterial] = useState([]);
	const [isLoaded, setIsLoaded] = useState(false);
	const dispatch = useDispatch();
	const [code, setCode] = useState("");
	const [debouncedValue, setDebouncedValue] = useState('');

	const [edges, setEdges] = useState([]);
	const [selectedEdges, setSelectedEdges] = useState([]);
	const input = useRef(null);



	const search = async () => {
		setEdges([]);
		setIsLoaded(true)
		if (code.length > 1) {
			const request = await Requests.Materials.getEdgesCurrent(code).catch(error => {
				_errors.setError(error.message, 'error', true)
			});
			if (empty(request.data)) {
				setIsLoaded(false)
				dispatch(errorMessageOperations.switchStateError({ message: Languages.getTranslation('edge-search-none', true), type: 'warning', show: true }))
			}
			if (!empty(request.data)) {
				setIsLoaded(false)
				const filteredEdges = request.data.filter(({ thickness, responsetype }) => {
					return !empty(thickness)  && (responsetype === 'band' || responsetype === 'Band');
				});
				setEdges([]);
					if (!empty(filteredEdges)) {
						setEdges(filteredEdges);
				}
			}
		}
	};

	useEffect(() => {
		const delay = 500;
		const debounceTimer = setTimeout(() => {
			setDebouncedValue(code);
		}, delay);

		return () => {
			clearTimeout(debounceTimer);
		};
	}, [code]);

	useEffect(() => {
		if (debouncedValue !== '') {
			search()
		}
	}, [debouncedValue]);
	const submit = () => {
		const sortedMaterials = [...selectedMaterials].map((material) => {
			if (material.edges) {
				delete material.edges
			}
			return material
		})
		if (empty(sortedMaterials) && empty(selectedEdges)) {
			_errors.setError(`${Languages.getTranslation("material-not-chos", true)}`, 'error', true)
			return;
		}

		if (!empty(sortedMaterials) &&
			construction._materials.find(
				(mat) => mat.article === sortedMaterials[0].article
			)
		) {
			_errors.setError(`${Languages.getTranslation("material-already-have", true)}`, 'error', true)
			return;
		}

		construction
			.setProjectMaterials([...sortedMaterials])
			.then(() => {
				const addedEdges = selectedEdges.filter(
					(el) => !construction.edges.find((el1) => el1.article === el.article)
				);
				return construction.setProjectEdges(addedEdges);
			})
			.then(() => {
				if (empty(sortedMaterials) && selectedEdges.length === 1) {
					dispatch(errorMessageOperations.switchStateError({ message: Languages.getTranslation("edge-success", true), type: 'success', show: true }))
				} else if (selectedEdges.length > 1) {
					dispatch(errorMessageOperations.switchStateError({ message: Languages.getTranslation("edges-success", true), type: 'success', show: true }))
				} else {
					dispatch(errorMessageOperations.switchStateError({ message: Languages.getTranslation("material-success", true), type: 'success', show: true }))
				}

				setSelectedEdges([]);
				navigate("/details");
				close();
			});
	};


	const setEdge = (edge) => {
		if (selectedEdges.find((edgeV) => edgeV === edge)) {
			setSelectedEdges(selectedEdges.filter((edgeV) => edgeV !== edge));
		} else {
			setSelectedEdges([...selectedEdges, edge]);
		}
	};


	const close = () => {
		dispatch(
			modalsOperations.switchStateForModal({
				show: false,
				type: null,
			})
		);
	};

	const disabled = empty(selectedEdges);

	return (
		<div className="addMaterial">
			<div className="addMaterial__header">
				<h3 className="addMaterial__header-title">
					{Languages.getTranslation("edges", true)}
				</h3>
				<div className="addMaterial__header-searchField">
					<i className="addMaterial__input-icon"></i>
					<input
						className="addMaterial__input"
						onChange={(e) => setCode(e.target.value)}
						ref={input}
						placeholder={Languages.getTranslation("add-name-or-article-edge", true)}
						autoComplete='off'
					/>
				</div>
				<CloseBtn handler={close} />
			</div>
			<div className="addMaterial__body">
				{isLoaded && <Loader />}

				{edges.length ? (
					<div className="edges-header">
						<div className="edges-header__checkbox">{/* <Checkbox /> */}</div>
						<div className="edges-header__title-container">
							<h3 className="edges-header__title">{Languages.getTranslation('type-edge', true)}</h3>
						</div>

						<div className="edges__table">
							<div className="edges__data-container">
								<div className="edges__data">
									{Languages.getTranslation("article", true)}
								</div>
								<div className="edges__data">
									{Languages.getTranslation("width", true)}
								</div>
								<div className="edges__data">
									{Languages.getTranslation("thickness", true)}
								</div>
							</div>
							<div className="edges__table-name">
								{Languages.getTranslation("name", true)}
							</div>
							<div className="edges__data-container-second">
								<div className="edges__data">
									{Languages.getTranslation("article", true)}
								</div>
								<div className="edges__data">
									{Languages.getTranslation("width", true)}
								</div>
								<div className="edges__data">
									{Languages.getTranslation("thickness", true)}
								</div>
							</div>
						</div>
					</div>
				) : (
					<></>
				)}

				<div className="edges">
					{edges.length
						? edges.map((edge, key) => (
							<div className="edges__item" key={edge.guid}>
								<div className="edges__checkbox">
									<Checkbox type="edge" value={edge} setEdge={setEdge} />
								</div>
								<div className="edges__item-number">{`${key + 1}`}</div>
								<div className="edges__title-container">
									<MaterialEdgeImg item={edge} isMaterial={false} />
									<h3 className="edges__name">
										{Languages.getAvaliableTranslation(edge.languages)}
									</h3>
								</div>
								<div className="edges__data-container">
									<div className="edges__data-item"> {edge.article}</div>
									<div className="edges__data-item"> {edge.w}</div>
									<div className="edges__data-item"> {edge.thickness}</div>
								</div>

							</div>
						))
						: <div className="edges__none"></div>}
				</div>
			</div>

			<div className="addMaterial__btns">
				<div className="addMaterial__actions">
					<DefaultBtn
						title={Languages.getTranslation("cancel2", true)}
						handler={close}
					/>
				</div>
					<DefaultBtn
						title={Languages.getTranslation("save", true)}
						handler={submit}
						disabled={disabled}
					/>
			</div>
		</div>
	);
};

export default AddEdges;
