import React, {useEffect, useState} from "react";
import Buttons from "../../../model/components/Buttons";
import {empty} from "../../../../helpers/helper";
import {div} from "three/examples/jsm/nodes/math/OperatorNode";
import './style.scss'

const ButtonsClass = new Buttons();

export default function ControlButton({button}) {
	const [opened, setOpened] = useState(false);
	const [activeItems, setActiveItems] = useState({});

	useEffect(() => {
		if(!empty(button.items)) {
			const items = {};
			button.items.forEach(el => {
				items[el.name] = false
			})
			setActiveItems({...items});
		}
	}, []);

	const handleItemClick = (handler, name) => {
		if(ButtonsClass[handler]) {
			ButtonsClass[handler]();
		}
		setActiveItems({...activeItems, [name]: !activeItems[name]})
	}

	const handleOpened = () => {
		setOpened(!opened)
	}

	const getItems = () => {
		if(!empty(button.items) && opened) {
			return (
				<div className={'control-button-items'}>
					{button.items.map((el, i) => {
						return (
	            <button key={i} onClick={e => handleItemClick(el.call, el.name)} className={`icon-button-${el.name} control-button-item ${activeItems[el.name] ? 'active' : ''}`}>
	              {el.name}
	            </button>
	          )
					})}
				</div>
			)
		}
		return null;
	}

	const getArrow = () => {
		if(empty(button.items)) {
			return null;
		}
		return (
			<span className={`icon-button-arrow-ver ${!opened ? 'hide' : 'show'}`}></span>
		)
	}

	return (
		<div className={'control-button'}>
			<button className={`icon-button-${button.name} control-button-main ${empty(button.items) ? 'single' : ''}`} onClick={e => {
				if(!empty(button.items)) {
					handleOpened()
				} else {
					handleItemClick(button.call)
				}
			}}>{button.name}{getArrow()}</button>

			{getItems()}
		</div>
	)
}