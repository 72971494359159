import {Checkbox} from "../../Tables/Details/common";
import Languages from "../../../../translation/Languages";

const Item = ({ item, index, onSelect, nameKey = "name" }) => {
	const translations =  Languages.getAvaliableTranslation(item.languages, nameKey) || item[nameKey] || Languages.getTranslation(nameKey, true);
	
	return (
		<div className="filter-sidebar_content-materials-item">
			<Checkbox isSelected={item.isChecked} onChange={onSelect} />
			{!isNaN(index + 1) && <div className="filter-sidebar_content-materials-item-index">{index + 1}</div>}
			<div className="filter-sidebar_content-materials-item-text">
				{translations}
			</div>
		</div>
	);
}

export default Item;