import "./style.scss";

import Select, { components } from "react-select";

const { Option } = components;

const CustomOption = (props) => {
    return (
        <Option {...props}>
            <div style={{display: 'flex', gap: "5px"}}>
                {props.data.img && <span style={{display: "flex"}}><img  src={props.data.img} width="20" height="21"  alt={props.data.label} /></span>}
                {props.data.label}
            </div>
        </Option>
    );
};

const defaultStyles = {
	option: (provided, state) => ({
		...provided,
		color: state.isSelected ? '#451d54' : 'black',
		backgroundColor: state.isFocused ? '#EFE3EC' : null,
		padding: 10,
		fontSize: '16px',
		cursor: "pointer"
	}),
	control: () => ({
		width: '100%',
		display: 'flex',
		background: "#F0F0F0",
		cursor: "pointer"
	}),
	singleValue: (provided, state) => ({
		...provided,
		fontSize: '16px',
		cursor: 'pointer'
	}),
	menu: (provided, state) => ({
		...provided,
		zIndex: 15,
	}),
	placeholder: (provided) => ({
		...provided,
		fontSize: '16px',
		cursor: "pointer"
		
	})
}

const CustomSelect = ({ options,defaultValue, handleChange, placeholder, customStyles, disabled, withImages, value, noOptionsMessage }) => {
    const combinedStyles = { ...defaultStyles, ...customStyles }

    return (
        <Select
						value={value}
            styles={combinedStyles}
            options={options}
            onChange={handleChange}
            isSearchable={false}
						noOptionsMessage={noOptionsMessage}
            defaultValue={defaultValue}
            placeholder={placeholder}
            isDisabled={disabled}
            components={{
							IndicatorSeparator: () => null,
							...(withImages ? {Option: CustomOption} : {})
						}}
        />
    );
}

export default CustomSelect;
